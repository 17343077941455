import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { slQaPlugin } from '@internal/plugin-sl-qa';
import { slReleasePlugin } from '@internal/plugin-sl-release';
import { slToolsPlugin } from '@internal/plugin-sl-tools';
import { slReviewsPlugin } from '@internal/plugin-sl-reviews';
import { SLPokedexApi, SLPokedexApiRef } from '@internal/plugin-sl-bots';
import { slShortcutsPlugin } from '@internal/plugin-sl-shortcuts';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: SLPokedexApiRef,
    deps: {
      identityApi: identityApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new SLPokedexApi(discoveryApi, identityApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  ...slQaPlugin.getApis(),
  ...slReleasePlugin.getApis(),
  ...slToolsPlugin.getApis(),
  ...slReviewsPlugin.getApis(),
  ...slShortcutsPlugin.getApis(),
];
