import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const slCliPlugin = createPlugin({
  id: 'sl-cli',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [],
});

export const SlCliLoginPage = slCliPlugin.provide(
  createRoutableExtension({
    name: 'SlCliLoginPage',
    component: () => import('./components').then(m => m.CliLoginPage),
    mountPoint: rootRouteRef,
  }),
);

export const SlCliAppleShortcutsLoginPage = slCliPlugin.provide(
  createRoutableExtension({
    name: 'SlCliAppleShortcutsLoginPage',
    component: () => import('./components').then(m => m.AppleShortcutsLogin),
    mountPoint: rootRouteRef,
  }),
);
