import { createPermission } from '@backstage/plugin-permission-common';
import { ComponentDefinitions } from '../definitions';
import { sanitizeComponentDefinition } from '../sanitization';
import {
  AllComponentPermissionNames,
  ComponentPermissionName,
  SanitizedComponentDefinition,
  isComponentPermissionName,
} from '../definitions/types';
import {
  ComponentPermission,
  ComponentPermissionCollection,
  IncompleteComponentPermissionCollection,
  UrlPermissionCollection,
} from './types';
import { SLPermissionsBuilderError } from './errors';

const buildComponentPermission = (
  urlPermissionsCollection: UrlPermissionCollection,
  sanitiziedDefinitions: SanitizedComponentDefinition[],
  definition: SanitizedComponentDefinition,
  collection: IncompleteComponentPermissionCollection,
): IncompleteComponentPermissionCollection => {
  if (collection[definition.name]) {
    throw new SLPermissionsBuilderError(
      `Infinite cycle detected while building component permissions. ${definition.name} is one of them.`,
    );
  }

  const currentComponentPermission: ComponentPermission = {
    name: definition.name,
    matchType: definition.matchType,
    type: 'component',
    basicPermission: createPermission({
      name: definition.name,
      attributes: {},
    }),
    members: [],
  };

  const futureCollection = {
    ...collection,
    [definition.name]: currentComponentPermission,
  };

  const reduceMatchAll = (
    accumulator: IncompleteComponentPermissionCollection,
    memberName: string,
  ): IncompleteComponentPermissionCollection => {
    const memberToBeParsed = sanitiziedDefinitions.find(
      componentDefinition => componentDefinition.name === memberName,
    );

    return {
      ...accumulator,
      ...(memberToBeParsed
        ? {
            [memberName]: buildComponentPermission(
              urlPermissionsCollection,
              sanitiziedDefinitions,
              memberToBeParsed,
              futureCollection,
            ),
          }
        : {}),
    };
  };

  const reduceMatchOne = (
    accumulator: IncompleteComponentPermissionCollection,
    memberName: string,
  ): IncompleteComponentPermissionCollection => {
    const memberToBeParsed = sanitiziedDefinitions.find(
      componentDefinition => componentDefinition.name === memberName,
    );

    if (!memberToBeParsed) {
      throw new SLPermissionsBuilderError(
        `A match one sanitized definition (${memberName}) was not found after sanitization for ${definition.name}`,
      );
    }

    return {
      ...accumulator,
      ...buildComponentPermission(
        urlPermissionsCollection,
        sanitiziedDefinitions,
        memberToBeParsed,
        futureCollection,
      ),
    };
  };

  const componentPermissionDependencies =
    definition.matchType === 'all'
      ? definition.members.reduce<IncompleteComponentPermissionCollection>(
          reduceMatchAll,
          {},
        )
      : definition.members.reduce<IncompleteComponentPermissionCollection>(
          reduceMatchOne,
          {},
        );

  const members = definition.members.map(memberName => {
    return isComponentPermissionName(memberName)
      ? componentPermissionDependencies[memberName]
      : urlPermissionsCollection[memberName];
  });

  members.forEach((currentMember, currentIndex) => {
    if (!currentMember) {
      throw new SLPermissionsBuilderError(
        `Parsed member of ${definition.name} on index ${currentIndex} resulted in an invalid value: ${currentMember}`,
      );
    }
  });

  return {
    ...componentPermissionDependencies,
    [definition.name]: {
      ...currentComponentPermission,
      members,
    },
  };
};

export const buildComponentPermissions = (
  urlPermissionCollection: UrlPermissionCollection,
): ComponentPermissionCollection => {
  const componentPermissions = ComponentDefinitions.map(
    sanitizeComponentDefinition,
  ).reduce<IncompleteComponentPermissionCollection>(
    (
      collection: IncompleteComponentPermissionCollection,
      componentDefinition: SanitizedComponentDefinition,
      _: number,
      sanitizedComponentDefinition: SanitizedComponentDefinition[],
    ) => ({
      ...collection,
      ...(collection[componentDefinition.name]
        ? {}
        : buildComponentPermission(
            urlPermissionCollection,
            sanitizedComponentDefinition,
            componentDefinition,
            { ...collection },
          )),
    }),
    {},
  );

  AllComponentPermissionNames.forEach(
    (componentPermissionName: ComponentPermissionName) => {
      if (!componentPermissions[componentPermissionName]) {
        throw new SLPermissionsBuilderError(
          `Missing component permission after building: ${componentPermissionName}`,
        );
      }
    },
  );

  return componentPermissions as ComponentPermissionCollection;
};
