import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
  },
});

export const CircleCIIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.641 0C12.46 0 3.077 7.676.436 18.053c-.018.076-.03.152-.03.233 0 .63.51 1.143 1.14 1.143h9.655c.46 0 .853-.275 1.034-.668h.002c1.984-4.326 6.34-7.333 11.404-7.333 6.928 0 12.546 5.63 12.546 12.572 0 6.942-5.619 12.572-12.546 12.572-5.063 0-9.42-3.008-11.404-7.336l-.002.003a1.14 1.14 0 0 0-1.034-.668H1.547c-.63 0-1.141.51-1.141 1.143 0 .078.012.153.028.228 2.64 10.378 12.023 18.057 23.207 18.057 13.227 0 23.952-10.747 23.952-24 0-13.255-10.725-24-23.952-24zm-5.704 24a5.71 5.71 0 0 1 5.704-5.714c3.15 0 5.702 2.559 5.702 5.714 0 3.155-2.553 5.715-5.702 5.715-3.15 0-5.704-2.56-5.704-5.715z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
