import { Icons } from '@internal/plugin-sl-assets';

export const PRStatuses = Object.freeze({
  DRAFT: 'draft',
  READY: 'ready',
  CLOSED: 'closed',
  MERGED: 'merged',
});

export type PRStatusValue = (typeof PRStatuses)[keyof typeof PRStatuses];

export const PRStatusIcon = Object.freeze({
  [PRStatuses.DRAFT]: Icons.PR_DRAFT,
  [PRStatuses.READY]: Icons.PR_READY,
  [PRStatuses.CLOSED]: Icons.PR_CLOSED,
  [PRStatuses.MERGED]: Icons.PR_MERGED,
});

export const ReviewStatuses = Object.freeze({
  SEEN: 'seen',
  LATENT: 'latent',
  AWAITING_CHANGES: 'awaiting_changes',
  REVIEW_REQUESTED: 'review_requested',
  REVIEWED: 'reviewed',
  REQUESTED_CHANGES: 'requested_changes',
  APPROVED: 'approved',
});

export type ReviewStatusValue =
  (typeof ReviewStatuses)[keyof typeof ReviewStatuses];

export const ReviewStatusesDisplay = Object.freeze({
  [ReviewStatuses.SEEN]: 'Seen',
  [ReviewStatuses.LATENT]: 'Latent',
  [ReviewStatuses.AWAITING_CHANGES]: 'Awaiting changes',
  [ReviewStatuses.REVIEW_REQUESTED]: 'Review requested',
  [ReviewStatuses.REVIEWED]: 'Reviewed',
  [ReviewStatuses.REQUESTED_CHANGES]: 'Requested changes',
  [ReviewStatuses.APPROVED]: 'Approved',
});

export const ReviewStatusesAction = Object.freeze({
  [ReviewStatuses.SEEN]: 'has seen',
  [ReviewStatuses.LATENT]: 'is waiting for you on',
  [ReviewStatuses.AWAITING_CHANGES]: 'is awaiting changes on',
  [ReviewStatuses.REVIEW_REQUESTED]: 'received a request to review',
  [ReviewStatuses.REVIEWED]: 'reviewed',
  [ReviewStatuses.REQUESTED_CHANGES]: 'requested changes on',
  [ReviewStatuses.APPROVED]: 'approved',
});

export const StatusesSortedByPriority = [
  ReviewStatuses.APPROVED,
  ReviewStatuses.REQUESTED_CHANGES,
  ReviewStatuses.REVIEWED,
  ReviewStatuses.REVIEW_REQUESTED,
  ReviewStatuses.AWAITING_CHANGES,
  ReviewStatuses.LATENT,
  ReviewStatuses.SEEN,
];

export const StatusIcon = Object.freeze({
  [ReviewStatuses.SEEN]: Icons.VISIBILITY,
  [ReviewStatuses.LATENT]: Icons.NOTIFICATION_PAUSED,
  [ReviewStatuses.AWAITING_CHANGES]: Icons.HOURGLASS_TOP,
  [ReviewStatuses.REVIEW_REQUESTED]: Icons.PENDING,
  [ReviewStatuses.REVIEWED]: Icons.MESSAGE,
  [ReviewStatuses.REQUESTED_CHANGES]: Icons.THUMBS_DOWN,
  [ReviewStatuses.APPROVED]: Icons.THUMBS_UP,
});
