import React from 'react';

export const useUrlUpdater = (
  params: Record<string, string | number | null>,
) => {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(
      Object.fromEntries(
        Object.entries({
          ...Object.fromEntries(
            new URLSearchParams(window.location.search).entries(),
          ),
          ...params,
        })
          .filter(([_, value]) => value !== null && value !== undefined)
          .map(([key, value]) => [key, (value as string | number).toString()]),
      ),
    ).toString();

    window.history.replaceState(
      null,
      '',
      `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }${urlParams.length ? `?${urlParams}` : ''}`,
    );
  }, [params]);
};
