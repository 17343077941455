import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { SLReleaseApi, SLReleaseApiRef } from './api';
import { ArgoApi, ArgoApiRef } from './argoApi';

export const slReleasePlugin = createPlugin({
  id: 'sl-release',
  apis: [
    createApiFactory({
      api: SLReleaseApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new SLReleaseApi(discoveryApi, identityApi),
    }),
    createApiFactory({
      api: ArgoApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new ArgoApi(discoveryApi, identityApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});
