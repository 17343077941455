import { useApi } from '@backstage/core-plugin-api';
import { SLReleaseApi, SLReleaseApiRef } from '../../api';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import useAsync from 'react-use/lib/useAsync';
import { Header } from '@backstage/core-components';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Page, handleResponse, PageData } from '@internal/plugin-sl-assets';
import { ReleaseNotesContent } from './content';

export const ReleaseNotesPage = () => {
  const api = useApi<SLReleaseApi>(SLReleaseApiRef);
  const today = React.useMemo(() => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  }, []);
  const [repositoryFullName, _] = React.useState('SectorLabs/maple');
  const [startDate, setStartDate] = React.useState(dayjs(new Date()));
  const [unixTimestamp, setUnixTimestamp] = React.useState(
    Math.floor(today.getTime() / 1000),
  );

  const releaseNotes = useAsync(
    async () =>
      api
        .getReleaseNotes(unixTimestamp, repositoryFullName)
        .then(handleResponse),
    [unixTimestamp, repositoryFullName],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => (
      <ReleaseNotesContent value={pageData.value} startDate={startDate} />
    ),
    [startDate],
  );

  return (
    <Page
      pageData={releaseNotes}
      header={() => (
        <>
          <Header title="Release notes" />
          <Paper sx={{ marginTop: '10px', padding: 1 }}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Start date"
                  value={startDate}
                  onChange={(newValue: Dayjs | null) => {
                    const newDate =
                      newValue?.hour(0).minute(0).second(0) || newValue;
                    setStartDate(dayjs(newDate));
                    if (newValue) {
                      setUnixTimestamp(
                        Math.floor(
                          (newDate as Dayjs).toDate().getTime() / 1000,
                        ),
                      );
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Paper>
        </>
      )}
      content={renderPage}
    />
  );
};
