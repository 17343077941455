import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  message: string;
  color: string;
};

export const Text = ({ message, color }: Props) => {
  const textColorProps = ['primary', 'secondary', 'error'].includes(color)
    ? { color }
    : { sx: { color } };

  return <Typography {...textColorProps}>{message}</Typography>;
};
