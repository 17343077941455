import { UrlDefinition } from './types';

export const UrlPermissionDefinitions: UrlDefinition[] = [
  {
    baseName: 'scaffolder.general', // Placeholder
    urlMatch: '^/',
    actions: ['create', 'read', 'update', 'delete'],
  },
  {
    baseName: 'search.general', // Placeholder
    urlMatch: '^/',
    actions: ['create', 'read', 'update', 'delete'],
  },
  {
    baseName: 'proxy.sentry.general',
    urlMatch: '^/sentry',
    actions: ['read'],
  },
  {
    baseName: 'announcement.entity',
    urlMatch: '^/',
    actions: ['create', 'read', 'update', 'delete'],
  },
  {
    baseName: 'qeta',
    urlMatch: '^/',
    actions: ['create', 'read', 'update', 'delete'],
  },
  // SL-Bots ping api
  {
    baseName: 'proxy.sl-bots.ping',
    urlMatch: '^/sl-bots/[^/]+/ping',
    actions: ['read'],
  },
  // SL-Bots users api
  {
    baseName: 'proxy.sl-bots.users',
    urlMatch: '^/sl-bots/[^/]+/api/users',
    actions: ['read'],
  },

  // SL-CI
  {
    baseName: 'sl-ci.workflows',
    urlMatch: '^/job',
    actions: ['read'],
  },
  // SL-CLI backend
  {
    baseName: 'sl-cli.catalog',
    urlMatch: '^/catalog',
    actions: ['read'],
  },

  // SL-Bots proxies
  {
    baseName: 'proxy.oncall.availability',
    urlMatch: '^/sl-bots/oncall/api/availability',
    actions: ['read', 'update'],
  },
  {
    baseName: 'proxy.oncall.availability.stats',
    urlMatch: '^/sl-bots/oncall/api/availability/stats',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.summary',
    urlMatch: '^/sl-bots/oncall/api/summary',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.expectations',
    urlMatch: '^/sl-bots/oncall/api/expectations',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.schedule',
    urlMatch: '^/sl-bots/oncall/api/schedule',
    actions: ['read', 'update'],
  },
  {
    baseName: 'proxy.oncall.admin',
    urlMatch: '^/sl-bots/oncall/api/shortcuts/admin',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.admin.cable',
    urlMatch: '^/sl-bots/oncall/api/shortcuts/cableAdmin',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.today',
    urlMatch: '^/sl-bots/oncall/api/shortcuts/(shifts|releaseWizard)/today',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.shifts',
    urlMatch: '^/sl-bots/oncall/api/shortcuts/user/(shifts|compensation)',
    actions: ['read'],
  },
  {
    baseName: 'proxy.oncall.opsgenie.notifications',
    urlMatch: '^/sl-bots/oncall/api/shortcuts/defaultOpsgenieNotifications/',
    actions: ['create'],
  },
  {
    baseName: 'proxy.pokedex.employee.list',
    urlMatch: '^/sl-bots/pokedex/api/employee[s]?',
    actions: ['read'],
  },

  // SL Tools proxies
  {
    baseName: 'proxy.sl-tools.github.commit-tracker',
    urlMatch: '^/sl-bots/tools/api/shortcuts/commitTracker',
    actions: ['read'],
  },

  // SL Release proxies
  {
    baseName: 'proxy.argocd.application.resource-tree',
    urlMatch: '^(/sl-cli)?/argocd/api/applications/[^/]+/resource-tree$',
    actions: ['read'],
  },
  {
    baseName: 'proxy.release.qa.assignments',
    urlMatch: '^/sl-bots/release/api/qa/assignments',
    actions: ['read', 'update'],
  },
  {
    baseName: 'proxy.release.version',
    urlMatch: '^/sl-bots/release/api/releaseVersion',
    actions: ['create'],
  },
  {
    baseName: 'proxy.release.deploy.next',
    urlMatch: '^/sl-bots/release/api/deploy/next',
    actions: ['create', 'read'],
  },
  {
    baseName: 'proxy.release.deploy.hotfix',
    urlMatch: '^/sl-bots/release/api/deploy/prepareHotfix',
    actions: ['create'],
  },
  {
    baseName: 'proxy.release.deploy.backport',
    urlMatch: '^/sl-bots/release/api/deploy/backport',
    actions: ['create'],
  },
  {
    baseName: 'proxy.release.deployed.slack',
    urlMatch: '^/sl-bots/release/api/deployed',
    actions: ['create'],
  },
  {
    baseName: 'proxy.release.deploying.slack',
    urlMatch: '^/sl-bots/release/api/deploying',
    actions: ['create'],
  },
  {
    baseName: 'proxy.release.notes.data',
    urlMatch: '^/sl-bots/release/api/releaseNotes/data',
    actions: ['read'],
  },
  {
    baseName: 'proxy.release.releases',
    urlMatch: '^/sl-bots/release/api/release/',
    actions: ['read'],
  },
  // SL Reviews proxies

  // This update query is only used by Backstage and should not be accessible by any regular user
  {
    baseName: 'proxy.reviews.codeowners',
    urlMatch: '^/sl-bots/reviews/api/codeowners',
    actions: ['update'],
  },
  {
    baseName: 'proxy.reviews.reviews',
    urlMatch: '^/sl-bots/reviews/api/reviews',
    actions: ['read'],
  },
  {
    baseName: 'proxy.reviews.repositories',
    urlMatch: '^/sl-bots/reviews/api/repositories',
    actions: ['read'],
  },
  {
    baseName: 'proxy.reviews.state-machines',
    urlMatch: '^/sl-bots/reviews/api/stateMachines',
    actions: ['read'],
  },
];
