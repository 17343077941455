import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    padding: '3px',
  },
  st0: {
    fill: '#e01e5a',
  },
  st1: {
    fill: '#36c5f0',
  },
  st2: {
    fill: '#2eb67d',
  },
  st3: {
    fill: '#ecb22e',
  },
});

export const SlackIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      viewBox="0 0 48 48"
    >
      <g id="g327" transform="translate(-22.683 -22.663) scale(.38186)">
        <path
          id="path14"
          d="M85.9 138.8c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h13.2z"
          className={classes.st0}
        />
        <path
          id="path16"
          d="M92.5 138.8c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2z"
          className={classes.st0}
        />
        <path
          id="path18"
          d="M105.7 85.8c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v13.2z"
          className={classes.st1}
        />
        <path
          id="path20"
          d="M105.7 92.5c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H72.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2z"
          className={classes.st1}
        />
        <path
          id="path22"
          d="M158.6 105.7c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2h-13.2z"
          className={classes.st2}
        />
        <path
          id="path24"
          d="M152 105.7c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V72.6c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2z"
          className={classes.st2}
        />
        <g id="g30">
          <path
            id="path26"
            d="M138.8 158.6c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2v-13.2z"
            className={classes.st3}
          />
          <path
            id="path28"
            d="M138.8 152c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2z"
            className={classes.st3}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
