const handleResponseError = (status: number, errorMessage: string = '') => {
  switch (status) {
    case 403:
      throw new Error(`403 Unauthorized. ${errorMessage}`);
    case 404:
      throw new Error(`404 Not Found. ${errorMessage}`);
    case 409:
      throw new Error(
        `409 Conflict. Some permissions are not configured: ${errorMessage}`,
      );
    case 422:
      throw new Error(`422 Unprocessable Entity. Reason: ${errorMessage}`);
    case 500:
      throw new Error('500 Internal Server Error');
    case 503:
      throw new Error('503 Temporary Unavailable');
    case 504:
      // One of the most common occurences of this error is when a bot is offline or cannot be found
      throw new Error('504 Gateway Timeout. Bot looks to be offline.');
    default:
      throw new Error(`Status code: ${status}. ${errorMessage}`);
  }
};

export const handleJSONResponse = async (response: Response) => {
  if (response.status === 204) {
    return {};
  }
  if (!response.ok) {
    let data = null;
    if (response.status >= 400 && response.status < 500) {
      data = await response.json();
      if (!data.error && response.status === 403) {
        handleResponseError(
          response.status,
          'Permission misconfiguration. Please contact a developer',
        );
      }
      if (!data.error) {
        handleResponseError(response.status, JSON.stringify(data));
      }
      handleResponseError(response.status, data.error);
    }
    handleResponseError(response.status);
  }
  return response.json();
};

export const handleTextResponse = async (response: Response) => {
  if (response.status === 204) {
    return '';
  }
  if (!response.ok) {
    let data = null;
    if (response.status >= 400 && response.status < 500) {
      data = await response.text();
      if (!data && response.status === 403) {
        handleResponseError(
          response.status,
          'Permission misconfiguration. Please contact a developer',
        );
      }
      handleResponseError(response.status, data);
    }
    handleResponseError(response.status);
  }
  return response.text();
};

export const handleResponse = async (response: Response) =>
  ((response.headers.get('Content-Type') || '').includes('application/json')
    ? handleJSONResponse
    : handleTextResponse)(response);
