import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export abstract class BaseApi {
  protected constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
    readonly backendPlugin: string,
    readonly baseUrl: string = '',
  ) {}

  protected async request(
    url: string,
    headers: Record<string, string>,
    method: string,
    body: BodyInit | null = null,
  ): Promise<Response> {
    const apiUrl = `${await this.discoveryApi.getBaseUrl(this.backendPlugin)}${
      this.baseUrl
    }${url}`;
    const options: RequestInit = {
      headers: {
        ...headers,
        ...(await this.authHeaders()),
      },
      method,
      ...(body ? { body: body } : {}),
    };

    return await fetch(apiUrl, options);
  }

  protected async get(
    url: string,
    headers: Record<string, string> = {},
  ): Promise<Response> {
    return this.request(url, headers, 'GET');
  }

  protected async post(
    url: string,
    body: BodyInit,
    headers: Record<string, string> = {},
  ): Promise<Response> {
    return this.request(url, headers, 'POST', body);
  }

  protected async put(
    url: string,
    body: BodyInit,
    headers: Record<string, string> = {},
  ): Promise<Response> {
    return this.request(url, headers, 'PUT', body);
  }

  protected async patch(
    url: string,
    body: BodyInit,
    headers: Record<string, string> = {},
  ): Promise<Response> {
    return this.request(url, headers, 'PATCH', body);
  }

  protected async delete(
    url: string,
    body: BodyInit,
    headers: Record<string, string> = {},
  ): Promise<Response> {
    return this.request(url, headers, 'DELETE', body);
  }

  private async authHeaders() {
    if (!this.identityApi) {
      return { authorization: '' };
    }
    const { token } = await this.identityApi.getCredentials();
    return {
      authorization: `Bearer ${token}`,
    };
  }
}
