export const IssueTypes = Object.freeze({
  BUG: 'bug',
  CHORE: 'chore',
  FEATURE: 'feature',
});

export const IssueStates = Object.freeze({
  UNSCHEDULED: 'unscheduled',
  UNSTARTED: 'unstarted',
  PLANNED: 'planned',
  STARTED: 'started',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  DELIVERED: 'delivered',
  FINISHED: 'finished',
});

export const IssueType = {
  [IssueTypes.BUG]: '🐞',
  [IssueTypes.CHORE]: '🔧',
  [IssueTypes.FEATURE]: '⭐',
};

export const IssueState = {
  [IssueStates.UNSCHEDULED]: '⏳',
  [IssueStates.UNSTARTED]: '⏳',
  [IssueStates.PLANNED]: '⏳',
  [IssueStates.STARTED]: '⚪',
  [IssueStates.FINISHED]: '🔵',
  [IssueStates.DELIVERED]: '✴️',
  [IssueStates.REJECTED]: '❌',
  [IssueStates.ACCEPTED]: '✅',
};

export const IssueTypeOrder: (typeof IssueTypes)[keyof typeof IssueTypes][] = [
  IssueTypes.CHORE,
  IssueTypes.BUG,
  IssueTypes.FEATURE,
];

export const IssueStateOrder: (typeof IssueStates)[keyof typeof IssueStates][] =
  [
    IssueStates.UNSCHEDULED,
    IssueStates.UNSTARTED,
    IssueStates.PLANNED,
    IssueStates.STARTED,
    IssueStates.FINISHED,
    IssueStates.DELIVERED,
    IssueStates.REJECTED,
    IssueStates.ACCEPTED,
  ];

export type ReleaseData = {
  merged_at: string;
  url: string;
};

export type ReleaseRecords = Record<string, ReleaseData>;

export type Issue = {
  pivotalId: number;
  projectId: number;
  projectName: string;
  title: string;
  type: (typeof IssueTypes)[keyof typeof IssueTypes];
  state: (typeof IssueStates)[keyof typeof IssueStates];
  userFacingReleaseNotes: string | null;
  labels: string[];
  prs: Record<number, string>;
  releases: ReleaseRecords;
  old_releases: ReleaseRecords;
};

export type ReleaseNotesData = {
  releases: Record<string, string>;
  issues: Array<Issue>;
  applications: string[];
  applicationGroups: Record<string, string[]>;
};

export type IssuesExtraInformation = {
  portals: string[];
  checked: boolean;
  userFacingReleaseNotes: string;
};

export type IssuesExtraInformationChanges = {
  portals?: string[];
  userFacingReleaseNotes?: string;
  checked?: boolean;
};
