import React from 'react';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {
  ReviewStatusesDisplay,
  ReviewStatusValue,
  StatusIcon,
} from '../status';
import { AvatarContact } from '@internal/plugin-sl-assets';
import { CodeownerReview } from '../types';
import { sortReviews } from './utils';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  codeownerReview: CodeownerReview;
  showIndividualCodeownerReviews: boolean;
};

export const CodeownerReviewChip = ({
  codeownerReview,
  showIndividualCodeownerReviews,
}: Props) => {
  const app = useApp();

  return (
    <Chip
      size="medium"
      sx={{
        marginBottom: 0,
        marginRight: 0,
        '.MuiChip-label': showIndividualCodeownerReviews
          ? { paddingRight: 0 }
          : {},
      }}
      avatar={
        <Tooltip title={codeownerReview.name}>
          <Avatar
            sx={{
              height: `28px`,
              width: `28px`,
              border: 'solid 1px #333',
            }}
            alt={codeownerReview.slug}
            src={codeownerReview.picture}
          >
            {codeownerReview.name}
          </Avatar>
        </Tooltip>
      }
      label={
        <>
          {codeownerReview.reviews.length && showIndividualCodeownerReviews ? (
            sortReviews(codeownerReview.reviews).map(
              ([status, reviewers], index) => (
                <Chip
                  size="medium"
                  sx={{
                    marginRight: 0,
                    marginTop: 1,
                    '.MuiChip-label': showIndividualCodeownerReviews
                      ? { paddingRight: '12px' }
                      : {},
                  }}
                  key={index}
                  avatar={
                    <>
                      {reviewers.map((reviewer, reviewerIndex) => (
                        <AvatarContact
                          key={reviewerIndex}
                          avatarIconSize={28}
                          employee={reviewer}
                        />
                      ))}
                    </>
                  }
                  label={
                    <IconButton size="small" disabled>
                      {React.createElement(
                        app.getSystemIcon(
                          StatusIcon[status as ReviewStatusValue],
                        ) as IconComponent,
                      )}
                    </IconButton>
                  }
                />
              ),
            )
          ) : (
            <Tooltip
              title={
                ReviewStatusesDisplay[
                  codeownerReview.status as ReviewStatusValue
                ]
              }
            >
              <Avatar sx={{ height: '28px', background: 'transparent' }}>
                {React.createElement(
                  app.getSystemIcon(
                    StatusIcon[codeownerReview.status as ReviewStatusValue],
                  ) as IconComponent,
                )}
              </Avatar>
            </Tooltip>
          )}
        </>
      }
    />
  );
};
