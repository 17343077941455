import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { BaseApi } from '@internal/plugin-sl-assets';

export enum Bots {
  ONCALL = 'oncall',
  POKEDEX = 'pokedex',
  RELEASE = 'release',
  TOOLS = 'tools',
  REVIEWS = 'reviews',
}

type EmployeeFilters = {
  email?: string;
  emails?: string;
  githubUsername?: string;
  role?: string;
  active?: 1 | 0;
};

export class SLBotsApi extends BaseApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
    readonly bot: Bots,
  ) {
    super(discoveryApi, identityApi, 'proxy', `/sl-bots/${bot}`);
  }

  public users(filters: EmployeeFilters) {
    return this.get(
      `/api/users/?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(filters)
            .filter(([_key, value]) => !!value)
            .map(([key, value]) => [key, value.toString()]),
        ),
      ).toString()}`,
    );
  }
  ping() {
    // Pings the bot to check the connection, authorization etc
    return this.get('/ping/');
  }
}
