import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { SLOnCallApi, SLOnCallApiRef } from './api';

export const slOnCallPlugin = createPlugin({
  id: 'sl-oncall',
  apis: [
    createApiFactory({
      api: SLOnCallApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new SLOnCallApi(discoveryApi, identityApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SLOnCallAvailabilityPage = slOnCallPlugin.provide(
  createRoutableExtension({
    name: 'SLOnCallAvailabilityPage',
    component: () => import('./availability').then(m => m.AvailabilityPage),
    mountPoint: rootRouteRef,
  }),
);

export const SLOnCallSchedulePage = slOnCallPlugin.provide(
  createRoutableExtension({
    name: 'SLOnCallSchedulePage',
    component: () => import('./schedule').then(m => m.Schedule),
    mountPoint: rootRouteRef,
  }),
);

export const SLOnCallSummaryPage = slOnCallPlugin.provide(
  createRoutableExtension({
    name: 'SLOnCallSummaryPage',
    component: () => import('./summary').then(m => m.SummaryPage),
    mountPoint: rootRouteRef,
  }),
);
