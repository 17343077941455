import React from 'react';
import {
  Issue,
  IssuesExtraInformation,
  IssuesExtraInformationChanges,
} from './types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';

type Props = {
  issue: Issue;
  issueData: IssuesExtraInformation;
  portals: string[];
  onHandleIssueData: (
    pivotalId: number,
    changes: IssuesExtraInformationChanges,
  ) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(portal: string, portals: readonly string[], theme: Theme) {
  return {
    fontWeight:
      portals.indexOf(portal) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MultiPortalSelect = ({
  issue,
  issueData,
  onHandleIssueData,
  portals,
}: Props) => {
  const theme = useTheme();

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      let newValue = typeof value === 'string' ? value.split(',') : value;

      if (newValue.includes('all') && !issueData.portals.includes('all')) {
        newValue = ['all'];
      } else if (issueData.portals.includes('all') && newValue.length > 1) {
        newValue = newValue.filter(valueToFilter => valueToFilter !== 'all');
      }

      onHandleIssueData(
        issue.pivotalId,
        // On autofill we get a stringified value.
        { portals: newValue },
      );
    },
    [onHandleIssueData, issue.pivotalId, issueData.portals],
  );

  return (
    <div>
      <FormControl sx={{ m: 1, width: 275 }}>
        <InputLabel>Select...</InputLabel>
        <Select
          sx={{ '& .v5-MuiSelect-select': { height: 'unset' } }}
          labelId="multi-country-select"
          multiple
          value={issueData.portals}
          onChange={handleChange}
          input={<OutlinedInput label="Select..." />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {['all'].concat(portals).map(portal => (
            <MenuItem
              key={portal}
              value={portal}
              style={getStyles(portal, issueData.portals, theme)}
            >
              {portal}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
