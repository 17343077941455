import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';

export class SLReviewsApi extends SLBotsApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, Bots.REVIEWS);
  }

  myPrs() {
    return this.get('/api/reviews?author=1');
  }

  myReviews() {
    return this.get('/api/reviews?reviewer=1');
  }

  codeownerReviews() {
    return this.get('/api/reviews?codeowner=1');
  }

  supportedRepositories() {
    return this.get('/api/repositories');
  }

  repositoryStateMachine(
    prNumber: number = 0,
    repositoryName: string = 'SectorLabs/maple',
  ) {
    return this.get(
      `/api/stateMachines?repo=${encodeURIComponent(
        repositoryName,
      )}&pr=${prNumber}`,
    );
  }
}

export const SLReviewsApiRef = createApiRef<SLReviewsApi>({
  id: 'plugin.sl-reviews.api',
});

export const useReviewsApi = () => {
  return useApi<SLReviewsApi>(SLReviewsApiRef);
};
