import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
  },
});

export const OpsgenieIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      viewBox="0 0 256 305"
    >
      <defs>
        <linearGradient
          id="logosOpsgenie0"
          x1="50%"
          x2="50%"
          y1="16.62%"
          y2="119.283%"
        >
          <stop offset="0%" stopColor="#2684FF" />
          <stop offset="82%" stopColor="#0052CC" />
        </linearGradient>
        <linearGradient
          id="logosOpsgenie1"
          x1="41.18%"
          x2="67.714%"
          y1="31.16%"
          y2="78.678%"
        >
          <stop offset="0%" stopColor="#2684FF" />
          <stop offset="62%" stopColor="#0052CC" />
        </linearGradient>
      </defs>
      <circle cx="127.996" cy="76.058" r="76.058" fill="url(#logosOpsgenie0)" />
      <path
        fill="url(#logosOpsgenie1)"
        d="M121.516 302.953A366.903 366.903 0 0 1 1.076 177.056a8.527 8.527 0 0 1 3.71-11.81l57.597-28.265a8.527 8.527 0 0 1 11.128 3.41a284.748 284.748 0 0 0 123.636 111.913a368.779 368.779 0 0 1-62.67 50.649a12.236 12.236 0 0 1-12.961 0Z"
      />
      <path
        fill="#2684FF"
        d="M134.476 302.953a366.647 366.647 0 0 0 120.44-125.897a8.527 8.527 0 0 0-3.667-11.81l-57.64-28.265a8.527 8.527 0 0 0-11.127 3.41A284.62 284.62 0 0 1 58.845 252.305a366.647 366.647 0 0 0 62.67 50.649a12.236 12.236 0 0 0 12.961 0Z"
      />
    </SvgIcon>
  );
};
