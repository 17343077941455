import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ResourceView } from './resourceView';
import { BUCKET_BASE_URL } from './constants';
import { Icons, Keys } from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  jobId: string;
  highlightedResource: string | null;
  setHighlightedResource: Function;
  resourcePaths: string[];
};

export const ResourceViewDialog = ({
  jobId,
  highlightedResource,
  setHighlightedResource,
  resourcePaths,
}: Props) => {
  const app = useApp();
  const move = React.useCallback(
    (positions: number) => {
      if (!highlightedResource) return;
      const currentIndex = resourcePaths.findIndex(
        resource => resource === highlightedResource,
      );
      if (currentIndex === -1) return;
      setHighlightedResource(
        resourcePaths[(currentIndex + positions) % resourcePaths.length],
      );
    },
    [highlightedResource, setHighlightedResource, resourcePaths],
  );

  const moveRight = React.useCallback(() => move(1), [move]);

  const moveLeft = React.useCallback(
    () => move(resourcePaths.length - 1),
    [move, resourcePaths],
  );

  const resourceViewKeyPressHandler = React.useCallback(
    (event: {
      key: any;
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => {
      switch (event.key) {
        case Keys.ARROW_LEFT:
          moveLeft();
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ARROW_RIGHT:
          moveRight();
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ENTER:
          event.preventDefault();
          event.stopPropagation();
          if (highlightedResource) {
            window.open(
              `${BUCKET_BASE_URL}/${jobId}/${encodeURIComponent(
                highlightedResource,
              )}`,
            );
          }
          break;
        default:
          break;
      }
    },
    [moveLeft, moveRight, highlightedResource, jobId],
  );

  const renderDialogContent = React.useCallback(
    (resource: string | null) => {
      if (resource === null) return null;
      if (resource?.endsWith('.png') || resource?.endsWith('.jpg')) {
        return (
          <ResourceView
            resourceUrl={`${BUCKET_BASE_URL}/${jobId}/${encodeURIComponent(
              resource,
            )}`}
          />
        );
      }
      return (
        <DialogContentText>
          Unknown data.
          <br />
          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                `${BUCKET_BASE_URL}/${jobId}/${encodeURIComponent(resource)}`,
              )
            }
          >
            Open in new tab (or press Enter)
          </Button>
        </DialogContentText>
      );
    },
    [jobId],
  );

  return (
    <Dialog
      open={!!highlightedResource}
      onClose={() => setHighlightedResource(null)}
      fullWidth
      maxWidth="xl"
      onKeyDown={resourceViewKeyPressHandler}
      disableEscapeKeyDown
    >
      <DialogTitle>
        {highlightedResource}
        <Tooltip
          style={{ position: 'absolute', right: 58, top: 8, color: 'white' }}
          title={
            <Typography variant="body1">
              View Resources. To navigate only using the keyboard: <br />-{' '}
              <i>Press</i> <i>ESC</i> to close the view
              <br />- Use the <i>LEFT</i> and <i>RIGHT</i> arrows to{' '}
              <b>navigate</b> through the diffs
              <br />
              - Press Enter to open in a new tab
              <br />
            </Typography>
          }
        >
          <IconButton>
            {React.createElement(
              app.getSystemIcon(Icons.QUESTION) as IconComponent,
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
          onClick={() => setHighlightedResource(null)}
        >
          {React.createElement(app.getSystemIcon(Icons.CLOSE) as IconComponent)}
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {renderDialogContent(highlightedResource)}
      </DialogContent>
    </Dialog>
  );
};
