import React from 'react';
import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { Header } from '@backstage/core-components';
import { SLReleaseApi, SLReleaseApiRef } from '../api';
import {
  AvatarContact,
  Employee,
  handleResponse,
  Icons,
  Page,
  PageData,
} from '@internal/plugin-sl-assets';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { usePermission } from '@backstage/plugin-permission-react';
import { SLPermissions } from '@internal/plugin-sl-permissions';
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'react-device-detect';

type ApplicationData = {
  employees: Employee[];
  order: number;
};

const ASSIGNATION_TYPES = ['qa', 'review_apps', 'mobile_apps'];

type Props = {
  value: [
    Record<(typeof ASSIGNATION_TYPES)[number], Record<string, ApplicationData>>,
    Employee[],
  ];
  api: SLReleaseApi;
};

const QaAssignmentsContent = ({ value, api: api }: Props) => {
  const app = useApp();
  const [existingAssignations, options] = value;
  const { loading: canChangeLoading, allowed: canChange } = usePermission({
    permission: SLPermissions.component['sl.release.qa.assignments.change'],
  });
  const [applicationInEditMode, setApplicationInEditMode] = React.useState<{
    id: string | null;
    type: string | null;
    employees: Employee[];
  }>({ id: null, type: null, employees: [] });
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarError, setSnackbarError] = React.useState<string>('');
  const [addChipAnchors, setAddChipAnchors] = React.useState<
    Record<string, (EventTarget & HTMLDivElement) | null>
  >(
    Object.fromEntries(
      Object.keys(existingAssignations).map(appId => [appId, null]),
    ),
  );

  const [assignations, setAssignations] =
    React.useState<Record<string, Record<string, ApplicationData>>>(
      existingAssignations,
    );

  const getDisplayName = (rawName: string): string => {
    const capitalizedApplicationName =
      rawName.charAt(0).toUpperCase() + rawName.slice(1);
    const [brand, extraInfo] = capitalizedApplicationName.split('_', 2);
    return `${brand}${
      extraInfo
        ? ` ${
            extraInfo.length === 2
              ? extraInfo.split('_').join(' ').toUpperCase()
              : extraInfo
          }`
        : ''
    }`;
  };

  const renderList = React.useCallback(
    (
      currentTypeAssignations: [string, ApplicationData][],
      assignationType: string,
    ) =>
      currentTypeAssignations.map(([applicationId, applicationData]) => (
        <>
          <ListItem
            key={applicationId}
            divider
            sx={{ paddingTop: 0.5, paddingBottom: 0 }}
          >
            <Grid container direction={isMobile ? 'column' : 'row'} spacing={0}>
              <Grid item key="title">
                <Typography
                  variant="h6"
                  style={!isMobile ? { width: '150px' } : {}}
                >
                  {getDisplayName(applicationId)}
                </Typography>
              </Grid>
              <Grid item key="details">
                {applicationData.employees.map((employee: Employee) => (
                  <Chip
                    key={employee.email}
                    avatar={
                      <AvatarContact avatarIconSize={25} employee={employee} />
                    }
                    label={employee.name}
                    {...(!isMobile &&
                    applicationInEditMode.id === applicationId &&
                    applicationInEditMode.type === assignationType
                      ? {
                          onDelete: () =>
                            setAssignations(prevValue => ({
                              ...prevValue,
                              [assignationType]: {
                                ...prevValue[assignationType],
                                [applicationId]: {
                                  ...prevValue[assignationType][applicationId],
                                  employees: prevValue[assignationType][
                                    applicationId
                                  ].employees.filter(
                                    e => e.email !== employee.email,
                                  ),
                                },
                              },
                            })),
                        }
                      : {})}
                  />
                ))}
                {!isMobile &&
                applicationInEditMode.id === applicationId &&
                applicationInEditMode.type === assignationType ? (
                  <>
                    <Chip
                      variant="outlined"
                      avatar={React.createElement(
                        app.getSystemIcon(Icons.ADD) as IconComponent,
                      )}
                      onClick={event =>
                        setAddChipAnchors(prevValue => ({
                          ...prevValue,
                          [applicationId]: event.currentTarget,
                        }))
                      }
                    />
                    <Popover
                      open={Boolean(addChipAnchors[applicationId])}
                      anchorEl={addChipAnchors[applicationId]}
                      onClose={() =>
                        setAddChipAnchors(preValue => ({
                          ...preValue,
                          [applicationId]: null,
                        }))
                      }
                    >
                      <List>
                        {options
                          .filter(
                            employeeOption =>
                              !assignations[assignationType][
                                applicationId
                              ].employees.some(
                                (e: Employee) =>
                                  e.email === employeeOption.email,
                              ),
                          )
                          .map((employeeOption: Employee) => (
                            <ListItem
                              style={{ cursor: 'pointer' }}
                              key={employeeOption.email}
                              onClick={() => {
                                setAddChipAnchors(preValue => ({
                                  ...preValue,
                                  [applicationId]: null,
                                }));
                                setAssignations(prevValue => ({
                                  ...prevValue,
                                  [assignationType]: {
                                    ...prevValue[assignationType],
                                    [applicationId]: {
                                      ...prevValue[assignationType][
                                        applicationId
                                      ],
                                      employees: [
                                        ...prevValue[assignationType][
                                          applicationId
                                        ].employees,
                                        employeeOption,
                                      ],
                                    },
                                  },
                                }));
                              }}
                            >
                              <ListItemAvatar>
                                <AvatarContact
                                  avatarIconSize={30}
                                  employee={employeeOption}
                                />
                              </ListItemAvatar>
                              <ListItemText>{employeeOption.name}</ListItemText>
                            </ListItem>
                          ))}
                      </List>
                    </Popover>
                  </>
                ) : null}
              </Grid>
            </Grid>
            {!isMobile && !canChangeLoading && canChange && (
              <ListItemSecondaryAction>
                {applicationInEditMode.id !== applicationId ||
                applicationInEditMode.type !== assignationType ? (
                  <IconButton
                    size="small"
                    onClick={() =>
                      setApplicationInEditMode({
                        id: applicationId,
                        type: assignationType,
                        employees:
                          assignations[assignationType][applicationId]
                            .employees,
                      })
                    }
                  >
                    {React.createElement(
                      app.getSystemIcon(Icons.EDIT) as IconComponent,
                    )}
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      size="small"
                      onClick={() =>
                        api
                          .patchQaAssignations(
                            applicationId,
                            assignations[assignationType][
                              applicationId
                            ].employees.map(employee => employee.email),
                            assignationType,
                          )
                          .then(handleResponse)
                          .then(() =>
                            setApplicationInEditMode({
                              id: null,
                              type: assignationType,
                              employees: [],
                            }),
                          )
                          .catch(e => setSnackbarError(e.message))
                          .finally(() => setSnackbarOpen(true))
                      }
                    >
                      {React.createElement(
                        app.getSystemIcon(Icons.SAVE) as IconComponent,
                      )}
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setApplicationInEditMode({
                          id: null,
                          type: assignationType,
                          employees: [],
                        });
                        setAssignations(prevValue => ({
                          ...prevValue,
                          [assignationType]: {
                            ...prevValue[assignationType],
                            [applicationId]: {
                              ...prevValue[assignationType][applicationId],
                              employees: applicationInEditMode.employees,
                            },
                          },
                        }));
                      }}
                    >
                      {React.createElement(
                        app.getSystemIcon(Icons.CLOSE) as IconComponent,
                      )}
                    </IconButton>
                  </>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </>
      )),
    [
      applicationInEditMode.id,
      applicationInEditMode.type,
      applicationInEditMode.employees,
      app,
      addChipAnchors,
      options,
      canChangeLoading,
      canChange,
      assignations,
      api,
    ],
  );

  const sortedAssignations = Object.keys(assignations).reduce<
    Record<string, [string, ApplicationData][]>
  >((alreadySorted, assignationType) => {
    const assignationsByType = Object.entries(assignations[assignationType]);
    assignationsByType.sort((assignation1, assignation2) => {
      if (assignation1[1].order > assignation2[1].order) {
        return 1;
      }
      return -1;
    });
    return { ...alreadySorted, [assignationType]: assignationsByType };
  }, {});

  return (
    <Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setSnackbarOpen(false);
          setSnackbarError('');
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
            setSnackbarError('');
          }}
          severity={!snackbarError ? 'success' : 'error'}
        >
          {snackbarError || 'Successfully saved!'}
        </Alert>
      </Snackbar>
      <Paper>
        {ASSIGNATION_TYPES.map(assignationType =>
          sortedAssignations[assignationType] ? (
            <Container key={assignationType}>
              <Typography variant="h3" key="header">
                {getDisplayName(assignationType)}
              </Typography>
              <List key="list">
                {renderList(
                  sortedAssignations[assignationType],
                  assignationType,
                )}
              </List>
            </Container>
          ) : null,
        )}
      </Paper>
    </Container>
  );
};

export const QaAssignmentsPage = () => {
  const api = useApi<SLReleaseApi>(SLReleaseApiRef);

  const assignationsOptions = useAsync(
    async () =>
      Promise.all([
        api.getQaAssignations().then(handleResponse),
        api.getQaOptions().then(handleResponse),
      ]),
    [],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => (
      <QaAssignmentsContent value={pageData.value} api={api} />
    ),
    [api],
  );

  return (
    <Page
      pageData={assignationsOptions}
      header={() => <Header title="QA Assignments" />}
      content={renderPage}
    />
  );
};
