import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { ArgoApi, ArgoApiRef } from '../../argoApi';
import useAsync from 'react-use/lib/useAsync';
import { ArgoProgressBar, getProgressBars, processPods } from './argo';
import {
  BotPatchedCatalogEntity,
  handleResponse,
  PageData,
  PartialPageData,
} from '@internal/plugin-sl-assets';

export const useArgoProgress = (
  pageData: PageData,
): PartialPageData<Record<string, Array<ArgoProgressBar>>> => {
  const entities = pageData.value as BotPatchedCatalogEntity[];
  const argoApi = useApi<ArgoApi>(ArgoApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const showArgoStatuses = featureFlagsApi.isActive('show-argo-status');

  return useAsync(
    async () =>
      Promise.all(
        showArgoStatuses
          ? entities.reduce<Array<Promise<Array<ArgoProgressBar>>>>(
              (processedProgressBars, entity) => [
                ...processedProgressBars,
                argoApi
                  .getApplicationTree(
                    entity.metadata.annotations['argocd/app-name'],
                  )
                  .then(handleResponse)
                  .then(processPods)
                  .then(pods => getProgressBars(pods, entity))
                  .catch(_ => [100, 'orange']) as Promise<
                  Array<ArgoProgressBar>
                >,
              ],
              [],
            )
          : [],
      ).then(promises =>
        promises.reduce(
          (reducedPromises, progressBar, index) => ({
            ...reducedPromises,
            [entities[index].metadata.annotations['argocd/app-name']]:
              progressBar,
          }),
          {},
        ),
      ),
    [entities],
  ) as PartialPageData<Record<string, Array<ArgoProgressBar>>>;
};
