import { useApi } from '@backstage/core-plugin-api';
import { SLOnCallApi, SLOnCallApiRef } from '../api';
import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { handleResponse, Result } from '@internal/plugin-sl-assets';

type ShiftWithDate = Result & {
  date: Date;
};

export const useMyOnCallShifts = (): ShiftWithDate[] => {
  const api = useApi<SLOnCallApi>(SLOnCallApiRef);
  const { value }: AsyncState<ShiftWithDate[]> = useAsync(
    async () =>
      api
        .myShifts()
        .then(handleResponse)
        .then(data =>
          data.results.map(
            (shift: Result): ShiftWithDate => ({
              ...shift,
              date: new Date(
                parseInt(shift.title.split(' ')[1].split('/')[2], 10),
                parseInt(shift.title.split(' ')[1].split('/')[1], 10) - 1,
                parseInt(shift.title.split(' ')[1].split('/')[0], 10),
              ),
            }),
          ),
        ),
    [],
  );

  return value || [];
};
