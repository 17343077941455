export const Keys = Object.freeze({
  ENTER: 'Enter',
  BACKSPACE: 'Backspace',
  ESCAPE: 'Escape',
  TAB: 'Tab',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  d: 'd',
  D: 'D',
});
