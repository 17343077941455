import { useApi } from '@backstage/core-plugin-api';
import { SLOnCallApi, SLOnCallApiRef } from '../api';
import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';
import { handleResponse } from '@internal/plugin-sl-assets';

export type AdminRedirectProps = {
  portal: string;
  suffix: string;
};

type Props = ShortcutComponentProps & AdminRedirectProps;

export const AdminRedirect = ({ portal, suffix, ...props }: Props) => {
  const api = useApi<SLOnCallApi>(SLOnCallApiRef);

  return (
    <BaseCommandShortcut
      {...props}
      request={() =>
        api
          .adminUrl(portal)
          .then(handleResponse)
          .then(data => ({
            ...data,
            url: `${data.url}${suffix}`,
          }))
      }
    />
  );
};

export const adminRedirectFactory =
  (portal: string, suffix: string = '') =>
  (props: ShortcutComponentProps) =>
    <AdminRedirect portal={portal} suffix={suffix} {...props} />;
