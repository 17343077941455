import { createCardExtension } from '@backstage/plugin-home-react';
import { homePlugin } from '@backstage/plugin-home';
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Header } from '@backstage/core-components';
import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@k-phoen/backstage-plugin-announcements';
import { QuestionTableCard } from '@drodil/backstage-plugin-qeta';
import { TodayDate } from './todayDate';
import { BotPing } from './botPing';
import { Bots } from '@internal/plugin-sl-bots';
import { isMobile } from 'react-device-detect';
import { UpcomingEvents } from './upcomingEvents';
import { usePermission } from '@backstage/plugin-permission-react';
import { SLPermissions } from '@internal/plugin-sl-permissions';
import { useMyOnCallShifts } from '@internal/plugin-sl-oncall';
import { IconNames } from '@internal/backstage-plugin-sl-common';
import { EventProps, EventTypes } from './event';

const OnCallTodayComponent = homePlugin.provide(
  createCardExtension({
    title: 'On-call Today',
    components: () => import('./OnCallToday'),
  }),
);

const ReleaseWizardComponent = homePlugin.provide(
  createCardExtension({
    title: 'Release Wizard Today',
    components: () => import('./ReleaseWizardToday'),
  }),
);

export const HomePage = () => {
  const permissions = {
    qeta: usePermission({
      permission: SLPermissions.component['sl.qeta'],
    }),
    announcements: usePermission({
      permission: SLPermissions.component['sl.announcements.read-only'],
    }),
    oncall: usePermission({
      permission: SLPermissions.component['sl.oncall.public'],
    }),
    botsPing: usePermission({
      permission: SLPermissions.component['sl.bots.ping'],
    }),
  };
  const myOnCallShifts = useMyOnCallShifts();

  return (
    <Box {...(!isMobile ? { minWidth: 900 } : {})}>
      <Header title="SectorLabs Backstage" />
      {permissions.announcements.allowed && (
        <NewAnnouncementBanner variant="floating" />
      )}
      <Container sx={{ marginTop: '50px' }}>
        <Grid container direction="row" columnGap={4} spacing={0}>
          <Grid
            container
            item
            direction="column"
            spacing={0}
            xs={12}
            md={7}
            zeroMinWidth
          >
            {permissions.announcements.allowed &&
              (isMobile ? (
                <Grid item xs={12}>
                  <AnnouncementsCard max={3} />
                </Grid>
              ) : (
                <Grid item>
                  <Box minWidth={300}>
                    <AnnouncementsCard max={3} />
                  </Box>
                </Grid>
              ))}
            {!isMobile && permissions.qeta.allowed && (
              <Grid item xs>
                <QuestionTableCard rowsPerPage={3} />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            direction="column"
            spacing={0}
            xs={12}
            md={4}
            rowGap={1}
          >
            <Grid item>
              <TodayDate />
            </Grid>
            {permissions.oncall.allowed && [
              <Grid item key="release-wizard-today">
                <ReleaseWizardComponent />
              </Grid>,
              <Grid item key="on-call-today">
                <OnCallTodayComponent />
              </Grid>,
            ]}
            <Grid item>
              <UpcomingEvents
                events={[
                  {
                    startDate: new Date(2024, 5, 28, 16, 0, 0),
                    endDate: new Date(2024, 5, 30, 12, 0, 0),
                    name: 'SectorLabs Teambuilding',
                    url: 'https://maps.app.goo.gl/UzGeqGXFpqxKhQvh9',
                    type: EventTypes.UNIQUE,
                    details: 'Nord Hotel, Borșa, Bistrița-Năsăud',
                    secondTicks: 60,
                    icon: IconNames.SL,
                  },
                  ...(myOnCallShifts.map?.(
                    (shift): EventProps => ({
                      startDate: shift.date,
                      name: `${shift.subtitle} on-call shift`,
                      type: EventTypes.IMPORTANT,
                    }),
                  ) || []),
                ]}
              />
            </Grid>
            {permissions.botsPing.allowed && (
              <Grid
                item
                container
                direction="row"
                spacing={0}
                rowGap={1}
                alignItems="center"
                justifyContent="center"
              >
                {Object.values(Bots).map((botName, index) => (
                  <Grid key={botName} item xs={6}>
                    <BotPing bot={botName} index={index} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
