import React from 'react';
import { SidebarItem } from '@backstage/core-components';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import {
  AnyGroupShortcut,
  AnyNonGroupShortcut,
  AnyRootShortcut,
  AnyShortcut,
  CommandShortcut,
  GroupShortcut,
  isShortcutAnyGroup,
  isShortcutAnyPage,
  RootShortcut,
  RootUrlShortcut,
  ShortcutTypes,
  UrlShortcut,
  Keys,
  IconNames,
  PageValue,
  CommandValue,
} from '@internal/backstage-plugin-sl-common';
import {
  useUrlUpdater,
  SearchField,
  useScreenSize,
  handleResponse,
} from '@internal/plugin-sl-assets';
import { useCtrlKInterceptor, usePermissionedShortcuts } from '../hooks';
import { isMobile } from 'react-device-detect';
import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import { SLShortcutsApi, SLShortcutsApiRef } from '../api';
import useAsync from 'react-use/lib/useAsync';

type CommonProps = {
  pages: Record<PageValue, any>;
  commands: Record<CommandValue, any>;
};

type Props = CommonProps & {
  shortcuts: Record<string, AnyRootShortcut>;
  loadingComplete?: boolean;
  refreshShortcuts: () => void;
};

type ShortcutsHistoryItem = {
  term: string;
  group: AnyGroupShortcut;
  results: Record<string, AnyShortcut>;
};

type UnrankedResult = {
  ranking: [number, number, number, number];
  result: AnyShortcut;
};

type State = {
  searchTerm: string;
  history: ShortcutsHistoryItem[];
  currentResults: Record<string, AnyShortcut>;
  selectedResultIndex: number | null;
  filteredResults: [string, AnyShortcut][];
  selectedCommandEntry: (CommandShortcut & { id: string }) | null;
  initialJumpPerformed: boolean;
  initialQueryPerformed: boolean;
};

const RESULTS_LIMIT = 16;

const lowestCommonAncestor = (word1: string, word2: string): string => {
  let lowestCommonAncestorTerm = `${word1}`;
  // Good ol' binary search for finding the longest prefix of all the result terms
  for (
    let step = Math.pow(
      2,
      Math.ceil(Math.log2(Math.max(word1.length, word2.length))),
    );
    step > 0;
    step = Math.floor(step / 2)
  ) {
    const sliceSize = lowestCommonAncestorTerm.length - step + 1;
    if (
      sliceSize > 0 &&
      word2.slice(0, sliceSize) !== lowestCommonAncestorTerm.slice(0, sliceSize)
    )
      lowestCommonAncestorTerm =
        sliceSize > 1 ? lowestCommonAncestorTerm.slice(0, sliceSize - 1) : '';
  }
  return lowestCommonAncestorTerm;
};

const SidebarShortcutsContent = ({
  shortcuts,
  commands,
  pages,
  loadingComplete,
  refreshShortcuts,
}: Props) => {
  const initialState = React.useMemo(
    (): State => ({
      searchTerm: '',
      history: [],
      currentResults: shortcuts,
      selectedResultIndex: null,
      filteredResults: Object.entries(shortcuts),
      selectedCommandEntry: null,
      initialJumpPerformed: true,
      initialQueryPerformed: true,
    }),
    [shortcuts],
  );

  const app = useApp();
  const { width, height } = useScreenSize();
  const queryParams = new URLSearchParams(window.location.search);
  const searchBar: React.Ref<HTMLInputElement> = React.createRef();
  const [resultsRef, _setResultsRef] = React.useState<
    React.Ref<HTMLInputElement>[]
  >(Array.from({ length: 200 }, () => React.createRef()));
  const [showShortcutDialog, setShowShortcutDialog] =
    React.useState<boolean>(false);
  const [previousDialogShow, setPreviousDialogShow] =
    React.useState<boolean>(false);

  const [state, setState] = React.useState<State>({
    ...initialState,
    initialJumpPerformed: !queryParams.get('shortcut'),
    initialQueryPerformed: !queryParams.get('q'),
  });

  useCtrlKInterceptor({ action: () => setShowShortcutDialog(prev => !prev) });

  React.useEffect(() => {
    if (state.selectedResultIndex !== null) {
      // @ts-ignore TS2339: Property 'current' does not exist on type '((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement>'
      resultsRef[state.selectedResultIndex]?.current?.scrollIntoView?.({
        block: 'nearest',
      });
    }
  }, [resultsRef, state]);

  React.useEffect(() => {
    // Open the dialog if the shortcut or query params are present, but force the focus on search input
    if (
      !!new URLSearchParams(window.location.search).get('shortcut') ||
      !!new URLSearchParams(window.location.search).get('q')
    ) {
      setShowShortcutDialog(true);
    }
  }, [setShowShortcutDialog]);

  useUrlUpdater({
    ...(!state.initialJumpPerformed
      ? {}
      : {
          shortcut:
            showShortcutDialog &&
            (state.history.length || state.selectedCommandEntry)
              ? state.selectedCommandEntry?.id ||
                state.history.map(entry => entry.term).join('/')
              : null,
        }),
    ...(!state.initialQueryPerformed
      ? {}
      : {
          q: showShortcutDialog && state.searchTerm ? state.searchTerm : null,
        }),
  });

  const wordCountAndPrefixFilterRanking = React.useCallback(
    (
      key: string,
      entry: AnyShortcut,
      searchTerm: string,
      tags: string,
      level: number,
    ): [string, UnrankedResult | null] => {
      if (!searchTerm) return [key, { result: entry, ranking: [0, 0, 0, 0] }];

      const searchTermWords = new Set(
        searchTerm.split(/[\s-\/]/).filter(i => i.length),
      );
      const keyWords = new Set(tags.split(/[\s-\/]/));
      const wordCount = new Set(
        [...searchTermWords].filter(i => keyWords.has(i)),
      ).size;
      const prefixMatchCount = Array.from(searchTermWords).reduce<number>(
        (accumulator, currentTermWord) =>
          accumulator +
          Array.from(keyWords).reduce<number>(
            (accumulator2, currentKeyWord) =>
              accumulator2 +
              (currentKeyWord.startsWith(currentTermWord) ? 1 : 0),
            0,
          ),
        0,
      );

      if (!prefixMatchCount) return [key, null];

      if (!wordCount && !prefixMatchCount) {
        return [key, null];
      }

      return [
        key,
        {
          result: entry,
          ranking: [
            entry.type !== ShortcutTypes.GROUP ? 1 : 0,
            wordCount,
            prefixMatchCount,
            level,
          ],
        },
      ];
    },
    [],
  );

  const rankResults = React.useCallback(
    (
      results: [string, UnrankedResult | null][],
      withSlice: boolean,
    ): [string, AnyShortcut][] => {
      const filteredResults: [string, UnrankedResult][] = (
        results.filter(([_key, result]) => !!result) as [
          string,
          UnrankedResult,
        ][]
      ).sort((item1, item2) => {
        for (let i = 0; i < 4; ++i) {
          if (item1[1].ranking[i] > item2[1].ranking[i]) {
            return -1;
          } else if (item1[1].ranking[i] < item2[1].ranking[i]) {
            return 1;
          }
        }
        return 0;
      });
      return (
        withSlice ? filteredResults.slice(0, RESULTS_LIMIT) : filteredResults
      ).map(([key, result]) => [key, result.result]);
    },
    [],
  );

  const recursiveFetch = React.useCallback(
    (
      results: Record<string, AnyShortcut>,
      prefix: string,
      prefixTerms: string,
      level: number = 0,
    ): [string, string, AnyShortcut, number][] => {
      const allRecords: [string, string, AnyShortcut, number][] = Object.keys(
        results,
      ).reduce<[string, string, AnyShortcut, number][]>(
        (
          accumulator: [string, string, AnyShortcut, number][],
          currentResultTerm,
        ) => {
          const nextPrefix = prefix.length
            ? `${prefix}/${currentResultTerm}`
            : currentResultTerm;
          const nextPrefixTerms = prefixTerms.length
            ? `${prefixTerms} ${results[currentResultTerm].tags}`
            : results[currentResultTerm].tags;

          if (!isShortcutAnyGroup(results[currentResultTerm])) {
            return [
              ...accumulator,
              [nextPrefix, nextPrefixTerms, results[currentResultTerm], level],
            ];
          }
          if (results[currentResultTerm].type === ShortcutTypes.ROOT) {
            const result: RootShortcut = results[
              currentResultTerm
            ] as RootShortcut;
            if (result.disallowRecursiveSearch) {
              return [
                ...accumulator,
                [
                  nextPrefix,
                  nextPrefixTerms,
                  results[currentResultTerm],
                  level,
                ],
              ];
            }
          }
          const depthResults: AnyGroupShortcut = results[
            currentResultTerm
          ] as AnyGroupShortcut;
          return [
            ...accumulator,
            [nextPrefix, nextPrefixTerms, results[currentResultTerm], level],
            ...recursiveFetch(
              depthResults.results,
              nextPrefix,
              nextPrefixTerms,
              level + 1,
            ),
          ];
        },
        [],
      );
      allRecords.sort(
        ([key1, _terms1, _shortcut1], [key2, _term2, _shortcut2]) => {
          const key1Level = key1.split('/').length;
          const key2Level = key2.split('/').length;
          if (key1Level < key2Level) {
            return -1;
          } else if (key1Level > key2Level) {
            return 1;
          }
          return 0;
        },
      );
      return allRecords;
    },
    [],
  );

  const catalogSearchEntry = (searchTerm: string): [string, UrlShortcut] => [
    '',
    {
      type: ShortcutTypes.URL,
      title: 'Catalog search',
      description: `Search "${searchTerm}" in the software catalog`,
      tags: '',
      icon: IconNames.SEARCH,
      url: `/search?query=${searchTerm}&types[]=software-catalog`,
    },
  ];

  const filterResults = React.useCallback(
    (
      results: Record<string, AnyShortcut>,
      searchTerm: string = '',
    ): [string, AnyShortcut][] => [
      ...rankResults(
        (
          (!searchTerm
            ? Object.entries(results).map(([key, entry]) => [
                key,
                entry.tags,
                entry,
                0,
              ])
            : recursiveFetch(results, '', '')) as [
            string,
            string,
            AnyShortcut,
            number,
          ][]
        ).map(([key, terms, entry, level]) =>
          wordCountAndPrefixFilterRanking(key, entry, searchTerm, terms, level),
        ),
        searchTerm.length > 0,
      ),
      ...(searchTerm.length ? [catalogSearchEntry(searchTerm)] : []),
    ],
    [recursiveFetch, rankResults, wordCountAndPrefixFilterRanking],
  );

  const updateTerm = React.useCallback(
    (newTerm: string) =>
      !state.selectedCommandEntry &&
      setState(prevState => ({
        ...prevState,
        searchTerm: newTerm,
        filteredResults: filterResults(prevState.currentResults, newTerm),
        selectedResultIndex: 0,
        initialQueryPerformed: true,
      })),
    [filterResults, state.selectedCommandEntry],
  );

  const getEntryDescription = React.useCallback((entry: AnyShortcut) => {
    if (isShortcutAnyPage(entry)) {
      return <>Page</>;
    }
    if (entry.type === 'url') {
      return <i>{entry.url.replace('sl:getin1@', '').split('?', 1)[0]}</i>;
    }
    if (isShortcutAnyGroup(entry)) {
      return `Shortcut group`;
    }
    if (entry.type === 'command') {
      return <>Command</>;
    }
    return entry.title;
  }, []);

  const getBreadcrumbItem = (entry: AnyShortcut, entryIndex: number | null) => (
    <Button
      key={entry.title}
      style={{ color: 'white' }}
      variant="text"
      startIcon={
        <Avatar variant="rounded" style={{ height: '25px', width: '25px' }}>
          {React.createElement(
            app.getSystemIcon(entry.icon) ||
              (app.getSystemIcon(IconNames.QUESTION) as IconComponent),
            { fontSize: 'inherit' },
          )}
        </Avatar>
      }
      size="small"
      onClick={() =>
        entryIndex !== null
          ? setState(prevState => ({
              ...prevState,
              currentResults: prevState.history[entryIndex].results,
              filteredResults: filterResults(
                prevState.history[entryIndex].results,
              ),
              history: prevState.history.slice(0, entryIndex + 1),
            }))
          : {}
      }
    >
      {entry.title}
    </Button>
  );

  const jumpToNonGroupShortcut = React.useCallback(
    (resultTerm: string, entry: AnyNonGroupShortcut, inPlace: boolean) => {
      if (isShortcutAnyPage(entry)) {
        window.location.href = pages[entry.pageName].url;
        setState(initialState);
      } else if (entry.type === ShortcutTypes.URL) {
        if (inPlace) {
          window.location.href = (entry as UrlShortcut).url;
          return;
        }
        window.open((entry as UrlShortcut).url);
        setState(initialState);
      } else if (entry.type === ShortcutTypes.ROOT_URL) {
        if (inPlace) {
          window.location.href = (entry as RootUrlShortcut).url;
          return;
        }
        window.location.href = (entry as RootUrlShortcut).url;
        setState(initialState);
      } else if (entry.type === ShortcutTypes.COMMAND) {
        setState(prevState => {
          return {
            ...prevState,
            selectedCommandEntry: {
              ...(entry as CommandShortcut),
              id: resultTerm,
            },
            searchTerm: '',
            initialJumpPerformed: true,
            initialQueryPerformed: true,
          };
        });
      }
    },
    [pages, initialState],
  );

  const complexJumpTo = React.useCallback(
    (resultTerm: string, inPlace: boolean) => {
      const newHistory: ShortcutsHistoryItem[] = [];
      const termSplit = resultTerm.includes('/')
        ? resultTerm.split('/')
        : [resultTerm];
      let currentResults: Record<string, AnyShortcut> = state.currentResults;
      let currentEntry: AnyShortcut = currentResults[termSplit[0]];
      termSplit.forEach((currentTerm, currentTermIndex) => {
        currentEntry = currentResults[currentTerm];
        if (currentEntry === undefined) {
          return;
        }
        if (isShortcutAnyGroup(currentEntry)) {
          currentResults = currentEntry.results;
          newHistory.push({
            term: currentTerm,
            group: currentEntry,
            results: currentResults,
          });
        } else if (currentTermIndex !== termSplit.length - 1) {
          jumpToNonGroupShortcut(
            termSplit.slice(0, currentTermIndex + 1).join('/'),
            currentEntry,
            inPlace,
          );
          return;
        }
      });
      if (currentEntry === undefined) {
        return;
      }
      if (!isShortcutAnyGroup(currentEntry)) {
        jumpToNonGroupShortcut(resultTerm, currentEntry, inPlace);
      } else {
        // Typescript is dumb and complains about this not being a group shortcut
        const currentGroupEntry: GroupShortcut = currentEntry as GroupShortcut;
        setState(prevState => ({
          ...prevState,
          history: [...prevState.history, ...newHistory],
          currentResults: currentGroupEntry.results,
          filteredResults: filterResults(currentGroupEntry.results),
          searchTerm: '',
          selectedResultIndex: 0,
          initialJumpPerformed: true,
          initialQueryPerformed: true,
        }));
      }
    },
    [filterResults, jumpToNonGroupShortcut, state],
  );

  const jumpTo = React.useCallback(
    ([resultTerm, entry]: [string, AnyShortcut], inPlace: boolean = false) => {
      if (
        !resultTerm.length &&
        (entry.type === ShortcutTypes.URL ||
          entry.type === ShortcutTypes.ROOT_URL)
      ) {
        jumpToNonGroupShortcut(resultTerm, entry, inPlace);
        return;
      }
      if (resultTerm.includes('/')) {
        complexJumpTo(resultTerm, inPlace);
        return;
      }
      const selectedEntry: AnyShortcut = state.currentResults[resultTerm];
      if (selectedEntry === undefined) {
        return;
      }
      if (isShortcutAnyGroup(selectedEntry)) {
        complexJumpTo(resultTerm, inPlace);
      } else jumpToNonGroupShortcut(resultTerm, selectedEntry, inPlace);
    },
    [complexJumpTo, state.currentResults, jumpToNonGroupShortcut],
  );

  React.useEffect(() => {
    const initialShortcut = new URLSearchParams(window.location.search).get(
      'shortcut',
    );
    if (!state.initialJumpPerformed && initialShortcut && loadingComplete) {
      complexJumpTo(initialShortcut, false);
    }
  }, [loadingComplete, state.initialJumpPerformed, complexJumpTo, shortcuts]);

  React.useEffect(() => {
    // Make sure that query from URL params gets applied only once if it exists
    const query = new URLSearchParams(window.location.search).get('q');
    const lucky = new URLSearchParams(window.location.search).get('lucky');
    if (!state.initialQueryPerformed && query) {
      if (lucky && loadingComplete) {
        const luckyResult = filterResults(shortcuts, query)[0];
        if (luckyResult) {
          jumpTo(luckyResult, true);
        }
      }
      updateTerm(query);
    }
  }, [
    loadingComplete,
    filterResults,
    shortcuts,
    state.initialQueryPerformed,
    updateTerm,
    jumpTo,
  ]);

  const keyPressHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case Keys.ENTER:
          // If a component takes over the search, use this opportunity to refresh it
          if (state.selectedCommandEntry) break;
          if (state.selectedResultIndex !== null) {
            jumpTo(state.filteredResults[state.selectedResultIndex]);
          }
          break;
        case Keys.BACKSPACE:
          if (state.selectedCommandEntry) {
            setState(initialState);
            event.preventDefault();
            event.stopPropagation();
            break;
          }
          if (state.searchTerm === '' && state.history.length) {
            setState(prevState => {
              const newResults =
                state.history.length === 1
                  ? shortcuts
                  : prevState.history[prevState.history.length - 2].results;

              return {
                ...prevState,
                currentResults: newResults,
                filteredResults: filterResults(newResults),
                history:
                  prevState.history.slice(0, prevState.history.length - 1) ||
                  [],
              };
            });
          }
          break;
        case Keys.ARROW_DOWN:
          event.preventDefault();
          event.stopPropagation();
          if (state.selectedCommandEntry) break;
          if (state.filteredResults.length) {
            setState(prevState => ({
              ...prevState,
              selectedResultIndex:
                prevState.selectedResultIndex === null
                  ? 0
                  : Math.min(
                      prevState.selectedResultIndex + 1,
                      state.filteredResults.length - 1,
                    ),
            }));
          }
          break;
        case Keys.ARROW_UP:
          if (state.selectedCommandEntry) break;
          if (state.filteredResults.length) {
            setState(prevState => ({
              ...prevState,
              selectedResultIndex:
                prevState.selectedResultIndex === null
                  ? 0
                  : Math.max(prevState.selectedResultIndex - 1, 0),
            }));
            event.preventDefault();
            event.stopPropagation();
          }
          break;
        case Keys.TAB:
          event.preventDefault();
          event.stopPropagation();
          if (state.selectedCommandEntry) {
            break;
          }
          if (state.filteredResults.length === 1) {
            jumpTo(state.filteredResults[0]);
          } else if (state.filteredResults.length > 1) {
            const termSplit = state.searchTerm.split(/\s/);
            const lastWordInTerm = termSplit[termSplit.length - 1];
            if (!lastWordInTerm.length) {
              break;
            }
            const allWordTerms =
              state.filteredResults
                .map(([key, _]) => key)
                .filter(key => key.length)
                .map(key => key.split(/[\s\/]/))
                .filter(
                  keySplit =>
                    termSplit.length === 1 ||
                    keySplit.some(key =>
                      (termSplit.slice(0, termSplit.length - 1) || []).some(
                        word => key === word,
                      ),
                    ),
                ) || [];

            const bestKeyWords = allWordTerms
              .map(keyList =>
                keyList.reduce<[number, string]>(
                  (accumulator, key) => {
                    const lowestCommonAncestorTerm = lowestCommonAncestor(
                      lastWordInTerm,
                      key,
                    );
                    return lowestCommonAncestorTerm.length > accumulator[0]
                      ? [lowestCommonAncestorTerm.length, key]
                      : accumulator;
                  },
                  [0, ''],
                ),
              )
              .filter(([lcaSize, _bestKey]) => lcaSize >= lastWordInTerm.length)
              .map(([_, bestKey]) => bestKey) || [''];

            const lowestCommonAncestorTerm =
              bestKeyWords
                .slice(1)
                .reduce(lowestCommonAncestor, bestKeyWords[0]) || '';
            const longestWordSize = bestKeyWords.reduce<number>(
              (acc, bestKeyWord) => Math.max(acc, bestKeyWord.length),
              0,
            );

            if (lowestCommonAncestorTerm.length > lastWordInTerm.length) {
              const stateSearchTermSplit = state.searchTerm.split(' ');
              const newTerm = `${stateSearchTermSplit
                .slice(0, stateSearchTermSplit.length - 1)
                .join(' ')}${
                stateSearchTermSplit.length > 1 ? ' ' : ''
              }${lowestCommonAncestorTerm}${
                longestWordSize === lowestCommonAncestorTerm.length ? ' ' : ''
              }`;
              setState(prevState => ({
                ...prevState,
                searchTerm: newTerm,
                filteredResults: filterResults(
                  prevState.currentResults,
                  newTerm,
                ),
              }));
            }
          }
          break;
        case Keys.ESCAPE:
          setShowShortcutDialog(false);
          setState(initialState);
          event.preventDefault();
          event.stopPropagation();
          break;
        default:
          setState(prevState => ({
            ...prevState,
            selectedResultIndex: 0,
          }));
      }
    },
    [jumpTo, shortcuts, state, initialState, filterResults],
  );

  React.useEffect(() => {
    // Bring the search bar into focus when opening the dialog
    if (!previousDialogShow && showShortcutDialog) {
      searchBar.current?.focus();
    }
    setPreviousDialogShow(showShortcutDialog);
  }, [
    searchBar,
    showShortcutDialog,
    previousDialogShow,
    setPreviousDialogShow,
  ]);

  const renderSearchField = () => (
    <SearchField
      inputRef={searchBar}
      onChange={e => updateTerm(e.target.value.toLowerCase())}
      value={state.searchTerm}
      placeholder={
        !state.selectedCommandEntry
          ? 'Go to'
          : `Press BACKSPACE here to go back`
      }
      keyPressHandler={keyPressHandler}
    />
  );

  const runningCommand = React.useMemo(
    () =>
      // without checking if selected Command entry exists a second time, typescript will complain
      state.selectedCommandEntry &&
      commands[state.selectedCommandEntry.component[0]] ? (
        React.createElement(
          state.selectedCommandEntry.component[1].length
            ? commands[state.selectedCommandEntry.component[0]](
                ...state.selectedCommandEntry.component[1],
              )
            : commands[state.selectedCommandEntry.component[0]],
          {
            onFinish: () => {
              setState(initialState);
              searchBar.current?.focus();
            },
            inputs: state.selectedCommandEntry?.inputs || [],
            parentRef: searchBar,
            key: 'running-command',
          },
        )
      ) : (
        <Snackbar open>
          <Alert severity="error">The command was not found</Alert>
        </Snackbar>
      ),
    [commands, initialState, searchBar, state.selectedCommandEntry],
  );

  const renderContent = () => (
    <div>
      {state.selectedCommandEntry ? (
        runningCommand
      ) : (
        <List dense>
          {state.filteredResults.map(([matchingKey, entry], index) => (
            <ListItemButton
              key={matchingKey}
              onClick={() => jumpTo([matchingKey, entry])}
              selected={state.selectedResultIndex === index}
              ref={resultsRef[index]}
            >
              <ListItemAvatar>
                <Avatar>
                  {React.createElement(
                    app.getSystemIcon(entry.icon) ||
                      (app.getSystemIcon(IconNames.QUESTION) as IconComponent),
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<b>{entry.description || entry.title}</b>}
                secondary={getEntryDescription(entry)}
              />
            </ListItemButton>
          ))}
          {isMobile && state.history.length && !state.selectedCommandEntry ? (
            <ListItemButton
              ref={resultsRef[state.filteredResults.length]}
              key="back"
              onClick={() =>
                setState(prevState => ({
                  ...prevState,
                  currentResults:
                    prevState.history.length > 1
                      ? prevState.history[prevState.history.length - 2].results
                      : initialState.currentResults,
                  filteredResults: filterResults(
                    prevState.history.length > 1
                      ? prevState.history[prevState.history.length - 2].results
                      : initialState.currentResults,
                  ),
                  history: prevState.history.slice(
                    0,
                    prevState.history.length - 1,
                  ),
                }))
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {React.createElement(
                    app.getSystemIcon(IconNames.ARROW_BACK) as IconComponent,
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                Back to{' '}
                {state.history.length > 1
                  ? `${
                      state.history[state.history.length - 2].group.title
                    } shortcuts`
                  : 'shortcuts'}
              </ListItemText>
            </ListItemButton>
          ) : null}
        </List>
      )}
    </div>
  );

  return (
    <>
      <SidebarItem
        key="shortcuts"
        icon={app.getSystemIcon(IconNames.KEYBOARD) as IconComponent}
        text="Go to (CTRL+K)"
        onClick={() => setShowShortcutDialog(!showShortcutDialog)}
      />
      {!isMobile ? (
        <Dialog
          maxWidth="xl"
          sx={{
            '& .v5-MuiDialog-container .v5-MuiPaper-root': {
              backgroundImage: 'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0))',
            },
          }}
          open={showShortcutDialog}
          onClose={() => setShowShortcutDialog(false)}
          keepMounted
          aria-describedby="alert-dialog-shortcut-search"
        >
          <DialogTitle style={{ opacity: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Breadcrumbs aria-label="shortcuts-breadcrumb">
                <IconButton size="small" onClick={() => setState(initialState)}>
                  <Avatar
                    variant="rounded"
                    style={{ height: '25px', width: '25px' }}
                  >
                    {React.createElement(
                      app.getSystemIcon(IconNames.HOME) as IconComponent,
                      { fontSize: 'small' },
                    )}
                  </Avatar>
                </IconButton>
                {state.history
                  .map(historyItem => historyItem.group)
                  .map(getBreadcrumbItem)}
                {state.selectedCommandEntry &&
                  getBreadcrumbItem(state.selectedCommandEntry, null)}
              </Breadcrumbs>
              {/* eslint-disable-next-line no-constant-condition */}
              {false ? (
                <IconButton size="small" onClick={refreshShortcuts}>
                  <Avatar
                    variant="rounded"
                    style={{ height: '25px', width: '25px' }}
                  >
                    {React.createElement(
                      app.getSystemIcon(IconNames.SYNC) as IconComponent,
                      { fontSize: 'small' },
                    )}
                  </Avatar>
                </IconButton>
              ) : null}
            </div>
            <div style={{ height: '10px' }} />
            <Grid container style={{ opacity: '100%' }} spacing={0}>
              <Grid item xs={12}>
                {renderSearchField()}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                width: `${Math.min(width - 200, 800)}px`,
                height: `${height - 200}px`,
              }}
            >
              {renderContent()}
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={showShortcutDialog}
          onClose={() => setShowShortcutDialog(false)}
        >
          <div style={{ height: height * 0.9 }}>
            {renderSearchField()}
            {renderContent()}
          </div>
        </Drawer>
      )}
    </>
  );
};

export const SidebarShortcuts = ({ commands, pages }: CommonProps) => {
  const app = useApp();
  const api = useApi<SLShortcutsApi>(SLShortcutsApiRef);
  const [refreshTrigger, setRefreshTrigger] = React.useState(new Date());
  const [cachedShortcuts, setCachedShortcuts] =
    React.useState<AnyRootShortcut | null>(null);

  const refreshShortcuts = React.useCallback(
    () => setRefreshTrigger(new Date()),
    [setRefreshTrigger],
  );

  const shortcuts = useAsync(
    async () =>
      api
        .getShortcuts()
        .then(handleResponse)
        .then(result => {
          setCachedShortcuts(result);
          return result;
        }),
    [api, refreshTrigger],
  );

  const { shortcuts: filteredShortcuts, checkComplete } =
    usePermissionedShortcuts(shortcuts.loading ? {} : shortcuts.value, true);

  if ((shortcuts.loading || !shortcuts.value) && !cachedShortcuts) {
    return (
      <SidebarItem
        key="shortcuts"
        icon={app.getSystemIcon(IconNames.HOURGLASS_TOP) as IconComponent}
        text="Loading"
        onClick={() => {}}
      />
    );
  }

  if (!checkComplete) {
    return (
      <SidebarItem
        key="shortcuts"
        icon={app.getSystemIcon(IconNames.HOURGLASS_TOP) as IconComponent}
        text="Checking permissions"
        onClick={() => {}}
      />
    );
  }

  if (shortcuts.error) {
    <SidebarItem
      key="shortcuts"
      icon={app.getSystemIcon(IconNames.ERROR) as IconComponent}
      text="Unavailable"
      onClick={() => {}}
    />;
  }

  return (
    <SidebarShortcutsContent
      refreshShortcuts={refreshShortcuts}
      shortcuts={filteredShortcuts || cachedShortcuts}
      loadingComplete
      commands={commands}
      pages={pages}
    />
  );
};
