import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CommandShortcut } from '@internal/backstage-plugin-sl-common';
import { ShortcutIcon } from './shortcutIcon';
import { useCtrlKInterceptor } from '../hooks';

type Props = {
  shortcut: CommandShortcut;
  command: any;
};

export const CommandButton = ({ shortcut, command }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [props, _] = React.useState({
    onFinish: () => {
      setOpen(false);
    },
    inputs: shortcut.inputs || [],
    parentRef: null,
  });
  useCtrlKInterceptor({
    action: () => {
      if (open) setOpen(false);
    },
    withPropagation: true,
  });

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => setOpen(true)}
        startIcon={<ShortcutIcon shortcut={shortcut} size="small" />}
      >
        {shortcut.title}
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{shortcut.description}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: '550px', height: '600px' }}>
            {React.createElement(
              shortcut.component[1].length
                ? command(...shortcut.component[1])
                : command,
              props,
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
