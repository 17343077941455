import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    padding: '3px',
  },
  cls1: {
    fill: '#28b16d',
  },
});

export const LamudiIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 104 28"
    >
      <defs>
        <path id="A" d="M0 0h103.6v27.1H0" />
        <path id="B" d="M0 0h103.6v27.1H0z" />
      </defs>
      <g fillRule="evenodd">
        <mask id="C" fill="#fff">
          <use xlinkHref="#A" />
        </mask>
        <g fill="#ff8700">
          <path
            d="M12.3 0a12.3 12.3 0 0 0-4.7 23.8V16l-1.8 1.4-2-2.3 4-4V6.6h3v2.5l4-3.9s6.5 5.2 9.7 7.2C24.5 5.6 19.1.1 12.3.1"
            mask="url(#C)"
          />
          <path d="M100.9 13.2c-1 0-1.7-.3-2.4-.8v14.4h4.8V12.4a3.8 3.8 0 0 1-2.4.8" />
        </g>
        <path
          fill="#36c"
          d="M103.6 9.4a2.7 2.7 0 0 1-2.7 2.7 2.7 2.7 0 0 1-2.7-2.7 2.7 2.7 0 0 1 2.7-2.7 2.7 2.7 0 0 1 2.7 2.7M21.8 26.8H11.3v-16H16v12h5.8zm11.8-6.5c0-.8-.3-1.5-.8-2a2.7 2.7 0 0 0-2-.8c-.9 0-1.6.3-2.2 1a2.8 2.8 0 0 0-.7 1.8 2.8 2.8 0 0 0 .7 1.9 2.7 2.7 0 0 0 2.2 1c.8 0 1.4-.3 2-.9s.8-1.2.8-2zm4.3 6.5h-4.4v-1.6c-.4.5-.9 1-1.3 1.2a6 6 0 0 1-6.7-1 6.5 6.5 0 0 1-2.2-5.1c0-2.1.8-3.9 2.2-5.1a6 6 0 0 1 4-1.6 5.8 5.8 0 0 1 2.6.6c.5.2 1 .6 1.4 1V14h4.4v12.8z"
        />
        <path
          fill="#ff8700"
          d="M62.2 26.8h-4.5v-6.4a5 5 0 0 0-.3-2.2c-.3-.6-.8-1-1.5-1s-1.3.4-1.7 1c-.3.5-.4 1.2-.4 2.2v6.4h-4.5v-6.4c0-1 0-1.8-.2-2.1a1.8 1.8 0 0 0-1.7-1c-.7 0-1.2.3-1.6.8-.2.2-.3.5-.3.9l-.1 1.4v6.4h-4.5V14h4.5v1.6c.5-.5 1-1 1.4-1.1.8-.5 1.7-.7 2.6-.7 1.3 0 2.3.3 3.1 1 .4.3.8.8 1.2 1.4.3-.6.7-1 1.2-1.4a4.9 4.9 0 0 1 3-1c1 0 1.8.2 2.5.7.8.5 1.3 1.2 1.5 2 .2.5.3 1.2.3 2v8.3z"
        />
        <mask id="D" fill="#fff">
          <use xlinkHref="#B" />
        </mask>
        <g fill="#ff8700">
          <path
            d="M78.5 22c0 2.3-1.1 3.9-3.3 4.7-1 .3-2 .4-3.4.4-2.6 0-4.4-.6-5.6-1.9-.7-.8-1-1.9-1-3.2v-8h4.4v7.1c0 .8.2 1.4.6 1.8.4.4 1 .6 1.6.6.7 0 1.3-.2 1.7-.6.4-.4.6-1 .6-1.8v-7h4.4V22z"
            mask="url(#D)"
          />
          <path d="M91.3 20.3c0-.8-.3-1.5-.8-2a2.7 2.7 0 0 0-2-.9c-1 0-1.6.4-2.2 1a2.8 2.8 0 0 0-.7 2c0 .6.2 1.2.7 1.8.6.6 1.3 1 2.2 1 .8 0 1.4-.3 2-.9.5-.5.8-1.2.8-2zm4.3 6.5h-4.4v-1.6c-.4.5-.9 1-1.3 1.2a6 6 0 0 1-6.7-1 6.5 6.5 0 0 1-2.2-5.1c0-2.1.7-3.9 2.2-5.1a6 6 0 0 1 6.6-1l1.4 1V6.8h4.4v20.1z" />
        </g>
      </g>
    </SvgIcon>
  );
};
