import React from 'react';
import {
  handleResponse,
  NewTabLink,
  Page,
  PageData,
  useUrlUpdater,
} from '@internal/plugin-sl-assets';
import { StateMachineVisualizer } from '@internal/plugin-sl-bots';
import { useApi } from '@backstage/core-plugin-api';
import { SLReviewsApi, SLReviewsApiRef } from '../api';
import useAsync from 'react-use/lib/useAsync';
import { Header } from '@backstage/core-components';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import debounce from 'just-debounce';

type Props = {
  supportedRepositories: string[];
};

const RepositoryStateMachineContent = ({ supportedRepositories }: Props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const api = useApi<SLReviewsApi>(SLReviewsApiRef);
  const [repository, setRepository] = React.useState(
    queryParams.get('repository') || supportedRepositories[0],
  );
  const [prNumber, setPrNumber] = React.useState(
    queryParams.get('pr') ? parseInt(queryParams.get('pr') as string, 10) : 0,
  );
  const [prText, setPrText] = React.useState(queryParams.get('pr') || '');

  const isPrNumberInvalid = React.useMemo(
    () => !prText.match(/^[1-9][0-9]*$/) || !prText.length,
    [prText],
  );

  const stateMachineData = useAsync(
    async () =>
      prNumber
        ? api.repositoryStateMachine(prNumber, repository).then(handleResponse)
        : Promise.resolve(null),
    [repository, prNumber],
  ) as PageData;

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      setPrNumber(0);
      setRepository(event.target.value);
    },
    [setRepository, setPrNumber],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetPr = React.useCallback(
    debounce(text => setPrNumber(parseInt(text, 10)), 1000),
    [setPrNumber],
  );

  useUrlUpdater({
    repository: repository,
    pr: prText,
  });

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPrText(event.target.value);
      if (!isPrNumberInvalid) debouncedSetPr(event.target.value);
    },
    [debouncedSetPr, isPrNumberInvalid],
  );

  return (
    <>
      <Paper>
        <Container sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant="h3">
            {repository}
            {prNumber ? ` PR #${prNumber}` : ''}
          </Typography>
          <FormControl>
            <InputLabel>Repository</InputLabel>
            <Select
              labelId="repository"
              id="repository"
              value={repository}
              label="Repositories"
              onChange={handleChange}
              disabled={supportedRepositories.length === 1}
              sx={{
                mr: 1,
                '& .MuiSelect-select': {
                  padding: 1,
                },
              }}
            >
              {supportedRepositories.map(repo => (
                /* WTF material UI can't render a simple list properly*/
                <MenuItem key={repo} value={repo} style={{ display: 'block' }}>
                  <ListItem>{repo}</ListItem>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="pr-number"
            error={isPrNumberInvalid && prText.length > 0}
            helperText={isPrNumberInvalid ? 'Must be a valid number' : ''}
            label="PR"
            variant="outlined"
            value={prText}
            onChange={onChange}
          />
          {prText.length ? (
            <>
              <br />
              <NewTabLink
                href={`https://bayut.coralogix.com/#/query-new/logs?query=coralogix.metadata.applicationName:%22sl-reviews%22%20AND%20task_name:%22process_pr_from_webhook%22%20AND%20id:${prText}%20AND%20repository:%22${repository}%22&time=from:now-7d,to:now&page=0&permalink=true`}
              >
                Transition History
              </NewTabLink>
              &nbsp;|&nbsp;
              <NewTabLink
                href={`https://www.github.com/${repository}/pull/${prText}`}
              >
                Pull request
              </NewTabLink>
            </>
          ) : null}
          {prText.length &&
          stateMachineData.value &&
          !stateMachineData.loading &&
          !stateMachineData.error ? (
            <>
              <hr />
              <StateMachineVisualizer stateMachine={stateMachineData.value} />
            </>
          ) : null}
          {stateMachineData.error ? (
            <Alert severity="error">{stateMachineData.error.message}</Alert>
          ) : null}
        </Container>
      </Paper>
    </>
  );
};

export const RepositoryStateMachine = () => {
  const api = useApi<SLReviewsApi>(SLReviewsApiRef);

  const supportedRepositories = useAsync(
    async () => api.supportedRepositories().then(handleResponse),
    [],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => (
      <RepositoryStateMachineContent supportedRepositories={pageData.value} />
    ),
    [],
  );

  return (
    <Page
      pageData={supportedRepositories}
      header={() => <Header title="Repository Review State Machines" />}
      content={renderPage}
    />
  );
};
