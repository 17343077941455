import React from 'react';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Employee, Icons } from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  employee: Employee;
  height?: number;
};

export const SlackSocial = ({ employee, height }: Props) => {
  const { slackId } = employee;
  const link = `slack://user?team=T2JU6EBHV&id=${slackId}`;

  const avatarSize = height ? height + 10 : height;

  const app = useApp();
  const slackIcon = app.getSystemIcon(Icons.SLACK) as IconComponent;

  if (!slackId) return null;

  return (
    <Tooltip
      title={
        employee.active ? 'Message on Slack' : 'View past conversation on Slack'
      }
    >
      <Avatar style={{ height: avatarSize, width: avatarSize }}>
        <IconButton onClick={() => window.open(link)} size="small">
          {React.createElement(slackIcon)}
        </IconButton>
      </Avatar>
    </Tooltip>
  );
};
