import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  AnyGroupShortcut,
  AnyRootShortcut,
  AnyShortcut,
  CommandShortcut,
  isShortcutAnyGroup,
  RootShortcut,
  ShortcutTypes,
  UrlShortcut,
} from '@internal/backstage-plugin-sl-common';
import { isMobile } from 'react-device-detect';
import { Header } from '@backstage/core-components';
import {
  handleResponse,
  Icons,
  Page,
  PageData,
} from '@internal/plugin-sl-assets';
import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  CommandButton,
  ShortcutIcon,
  UrlButton,
  SLShortcutsApi,
  SLShortcutsApiRef,
  usePermissionedShortcuts,
} from '@internal/plugin-sl-shortcuts';
import { Commands } from '../commands';

type Props = {
  shortcuts: Record<string, AnyRootShortcut>;
};

const CheatsheetContent = ({ shortcuts }: Props) => {
  const app = useApp();
  const { shortcuts: permissionedShortcuts } =
    usePermissionedShortcuts(shortcuts);
  const [visibleLevel1Children, setVisibleLevel1Children] = React.useState<
    Record<string, boolean>
  >({});
  const filteredShortcuts: [string, RootShortcut][] = Object.entries(
    permissionedShortcuts,
  ).filter(([_, shortcut]) => shortcut.type === ShortcutTypes.ROOT) as [
    string,
    RootShortcut,
  ][];

  const scrollToHash = (hash: string) => {
    const headerComponent = document.getElementById(hash.slice(1));
    if (headerComponent) {
      headerComponent.scrollIntoView();
    }
    if (hash !== '#appendix') window.location.hash = hash;
  };

  React.useEffect(() => {
    scrollToHash(window.location.hash);
  }, []);

  const renderGroupShortcuts = (
    results: Record<string, AnyShortcut>,
    baseId: string = '',
    level: number = 0,
  ) => {
    const groupResults = Object.entries(results).filter(([_, result]) =>
      isShortcutAnyGroup(result),
    ) as [string, AnyGroupShortcut][];

    return groupResults.map(([groupShortcutId, groupShortcut]) => {
      const subGroupCount = Object.values(groupShortcut.results).filter(
        result => isShortcutAnyGroup(result),
      ).length;

      if (level === 2 && !visibleLevel1Children[baseId]) return null;

      return (
        <Grid
          spacing={0}
          container
          item
          direction="column"
          id={baseId.length ? `${baseId}-${groupShortcutId}` : groupShortcutId}
          key={baseId.length ? `${baseId}-${groupShortcutId}` : groupShortcutId}
          xs={
            isMobile || level === 0 || (level === 1 && subGroupCount > 1)
              ? 'auto'
              : 3
          }
        >
          <Grid item container direction="row" spacing={0}>
            <Button
              onClick={() =>
                level === 1
                  ? setVisibleLevel1Children(prevValue => ({
                      ...prevValue,
                      [`${baseId}-${groupShortcutId}`]:
                        !prevValue[`${baseId}-${groupShortcutId}`],
                    }))
                  : null
              }
            >
              {level <= 1 ? (
                <>
                  <ShortcutIcon shortcut={groupShortcut} key="icon" />
                  &nbsp;
                </>
              ) : null}
              <Typography
                // @ts-ignore value is h4 | h5 | h6
                variant={`h${Math.min(6, 4 + level)}`}
              >
                {groupShortcut.title}
              </Typography>
              {level === 1 &&
                React.createElement(
                  app.getSystemIcon(Icons.ARROW_DROPDOWN) as IconComponent,
                )}
            </Button>
          </Grid>
          <Grid container item direction="row" spacing={0}>
            {renderGroupShortcuts(
              groupShortcut.results,
              baseId.length ? `${baseId}-${groupShortcutId}` : groupShortcutId,
              level + 1,
            )}
          </Grid>
          {(level !== 1 ||
            visibleLevel1Children[`${baseId}-${groupShortcutId}`]) && (
            <Grid
              container
              item
              direction={level ? 'column' : 'row'}
              spacing={0}
            >
              {Object.entries(groupShortcut.results)
                .filter(
                  ([_, result]) =>
                    result.type === ShortcutTypes.URL ||
                    result.type === ShortcutTypes.COMMAND,
                )
                .map(([shortcutId, nonGroupShortcut]) => (
                  <Grid item key={`${baseId}-${shortcutId}`}>
                    {nonGroupShortcut.type === ShortcutTypes.URL && (
                      <UrlButton
                        key={`${baseId}-${shortcutId}-url`}
                        shortcut={nonGroupShortcut as UrlShortcut}
                      />
                    )}
                    {nonGroupShortcut.type === ShortcutTypes.COMMAND && (
                      <CommandButton
                        key={`${baseId}-${shortcutId}-command`}
                        shortcut={nonGroupShortcut as CommandShortcut}
                        command={Commands[nonGroupShortcut.component[0]]}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      );
    });
  };

  return (
    <>
      <Container
        style={!isMobile ? { minWidth: '900px' } : {}}
        key="cheatsheet"
      >
        <Paper>
          <Container>
            <>
              <Typography variant="h3" id="appendix">
                Appendix
              </Typography>
              {filteredShortcuts.map(([rootShortcutId, shortcut]) => (
                <Button
                  sx={{ display: 'inline-block', marginRight: '15px' }}
                  key={rootShortcutId}
                  onClick={() => scrollToHash(`#${rootShortcutId}`)}
                  startIcon={<ShortcutIcon shortcut={shortcut} size="small" />}
                >
                  <Typography style={{ display: 'inline' }}>
                    {shortcut.title}
                  </Typography>
                </Button>
              ))}
            </>
            <br />
            <br />
            {renderGroupShortcuts(Object.fromEntries(filteredShortcuts))}
            <br />
            <br />
          </Container>
        </Paper>
      </Container>
    </>
  );
};

export const CheatsheetPage = () => {
  const api = useApi<SLShortcutsApi>(SLShortcutsApiRef);
  const shortcuts = useAsync(
    async () => api.getShortcuts().then(handleResponse),
    [api],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => <CheatsheetContent shortcuts={pageData.value} />,
    [],
  );

  return (
    <Page
      pageData={shortcuts}
      header={() => <Header title="Cheatsheet" />}
      content={renderPage}
    />
  );
};
