// To test the jobs page locally, add the following prefix: `http://localhost:7007`

export const BUCKET_BASE_URL = `/api/sl-ci/job`;
export const DIFF_NEIGHBOURS_LAZY_LOAD_THRESHOLD = 3;

export const DiffStates: Record<string, string> = Object.freeze({
  DIFF: 'diff',
  ACTUAL: 'actual',
  EXPECTED: 'expected',
});
export const DiffStatesOrder = [
  DiffStates.DIFF,
  DiffStates.EXPECTED,
  DiffStates.ACTUAL,
];
