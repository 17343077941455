import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    'enable-background': 'new 0 0 139.1 139.1',
  },
  path: {
    fill: '#7df3e1',
  },
  st0: { display: 'none' },
  st1: { display: 'inline' },
  st2: { fill: '#D9EBFF' },
  st3: { fill: '#314563' },
  st4: { fill: '#4284D3' },
  st5: { fill: '#2D70C1' },
  st6: { fill: '#5547D6' },
  st7: { fill: '#9C61DB' },
  st8: { fill: '#7A1EBC' },
  st9: { fill: '#6319B2' },
  st10: { fill: '#6935BC' },
  st11: { fill: '#533E7F' },
  st12: { fill: '#67A2E8' },
  st13: { fill: '#287EE5' },
  st14: { fill: '#849FBF' },
  st15: { fill: '#0B66D3' },
  st16: { fill: '#461FB2' },
  st17: { fill: '#055EC9' },
  st18: { fill: '#50B0FF' },
  st19: { fill: '#416EA3' },
  st20: { fill: '#3CC48F' },
  st21: { fill: '#AEFFDC' },
});

export const CoralogixIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 139.1 139.1"
      className={classes.svg}
    >
      <g id="Layer_1" className={classes.st0}>
        <g className={classes.st1}>
          <polygon
            className={classes.st2}
            points="1336.1,1205.6 1355.8,1173.4 1417.8,1156.9 1459.3,1198.5 1455.7,1230 1429.6,1243.6 1436.7,1259 
			1423.1,1259 1403.1,1232.5 1363.3,1231.4 		"
          />
          <g>
            <path
              className={classes.st3}
              d="M1461.6,1197.8c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.1-0.1-0.1-0.2-0.1-0.2l-41.7-41.5c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0
				c-0.2-0.1-0.4-0.2-0.6-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
				l-62.6,16.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2
				c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0l-20.4,34.1c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3
				c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4
				c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0l27.9,24.9c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0
				c0.2,0.1,0.5,0.2,0.7,0.2l38.5,0.6l21.2,26.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.1
				c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.3,0,0.4,0h0h12c0,0,0,0,0,0c1.1,0,1.9-0.9,1.9-1.9c0-0.5-0.2-0.9-0.4-1.2l-7.5-13.9l13.8-7.2
				l13.1-5.6c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,0.3-0.3,0.4-0.4c0,0,0-0.1,0-0.1
				c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0l3.5-31.9
				c0-0.1,0-0.1,0-0.2C1461.6,1197.9,1461.6,1197.9,1461.6,1197.8z M1423,1255.1l-14.2-17.8l16.4,7.4L1423,1255.1z M1339.6,1208.3
				l33.3-3l-10.7,23.2L1339.6,1208.3z M1432,1197l-25.5-9.3l24.6-3.7L1432,1197z M1402.6,1184.4l15.3-24.3l11.8,20.2L1402.6,1184.4z
				 M1376.4,1200.3l-16.2-23.9l34.7,11.2L1376.4,1200.3z M1398.5,1189.6l24,28.5l-42.8-15.6L1398.5,1189.6z M1423.5,1222.5
				l-20.5,7.5l-18.1-22.5L1423.5,1222.5z M1404.9,1191.2l26.9,9.9l-4,17.4L1404.9,1191.2z M1434.7,1181.7
				C1434.7,1181.7,1434.7,1181.6,1434.7,1181.7c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3
				c0,0,0,0,0-0.1l0,0c0,0,0,0,0,0l-7.4-12.6l28.3,28.2l-19.5,1.4L1434.7,1181.7z M1398,1184.6l-36.2-11.7l52-13.3L1398,1184.6z
				 M1355.5,1176.2l17.1,25.3l-34.1,3.1L1355.5,1176.2z M1376.5,1206.7l21.8,23.6l-32.4-0.5L1376.5,1206.7z M1407.4,1232.4l19.4-7.1
				l-1.1,15.3L1407.4,1232.4z M1435,1204.3l16.4,22.3l-20.3-5.4L1435,1204.3z M1454.9,1224.8l-17.2-23.3l19.9-1.4L1454.9,1224.8z
				 M1432.8,1257.5h-6.4l1.8-8.5L1432.8,1257.5z M1442.2,1233.8l-12.6,6.5l1.1-15.3l19.7,5.3L1442.2,1233.8z"
            />
          </g>
          <g>
            <path
              className={classes.st3}
              d="M1500.7,1225.4c-4.1-2.2-7.2-5.4-9.3-9.5c-2.2-4.1-3.3-8.8-3.3-14.2c0-5.4,1.1-10.1,3.3-14.2
				c2.2-4.1,5.3-7.2,9.3-9.5c4.1-2.2,8.8-3.4,14.3-3.4c3.4,0,6.8,0.5,10,1.6c3.2,1,5.8,2.4,7.9,4.2l-4.3,9.7
				c-4.3-3-8.8-4.6-13.3-4.6c-4.3,0-7.6,1.3-9.8,4s-3.4,6.7-3.4,12c0,5.3,1.1,9.4,3.4,12.1s5.5,4.1,9.8,4.1c4.5,0,9-1.5,13.3-4.6
				l4.3,9.7c-2,1.7-4.7,3.1-7.9,4.2c-3.2,1-6.5,1.6-10,1.6C1509.5,1228.8,1504.8,1227.7,1500.7,1225.4z"
            />
            <path
              className={classes.st3}
              d="M1545,1226.4c-3.1-1.6-5.4-3.8-7.1-6.7c-1.7-2.9-2.5-6.3-2.5-10.2c0-3.9,0.8-7.3,2.5-10.1
				c1.7-2.9,4-5.1,7.1-6.6c3-1.5,6.6-2.3,10.7-2.3c4.1,0,7.6,0.8,10.7,2.3c3,1.5,5.4,3.8,7.1,6.6c1.7,2.9,2.5,6.2,2.5,10.1
				c0,3.9-0.8,7.3-2.5,10.2c-1.7,2.9-4,5.1-7.1,6.7c-3,1.6-6.6,2.4-10.7,2.4C1551.6,1228.7,1548,1227.9,1545,1226.4z M1562.7,1209.5
				c0-6.2-2.4-9.3-7.1-9.3c-4.7,0-7.1,3.1-7.1,9.3c0,6.2,2.4,9.3,7.1,9.3C1560.3,1218.8,1562.7,1215.7,1562.7,1209.5z"
            />
            <path
              className={classes.st3}
              d="M1609.7,1201.3l-7.3,0.7c-2.9,0.3-4.9,1.1-6.1,2.3c-1.1,1.2-1.7,3-1.7,5.3v18.5h-13.3v-36.8h12.6v6.4
				c0.8-2.2,2.2-4,4.2-5.1c1.9-1.2,4.3-1.8,7.1-2l3.7-0.3L1609.7,1201.3z"
            />
            <path
              className={classes.st3}
              d="M1643,1194.4c2.8,2.7,4.1,6.8,4.1,12.4v21.2h-12.5v-5.2c-0.7,1.8-1.9,3.2-3.5,4.2c-1.6,1-3.6,1.5-5.9,1.5
				c-2.5,0-4.8-0.5-6.9-1.5c-2-1-3.7-2.4-4.8-4.3c-1.2-1.8-1.8-3.9-1.8-6.2c0-2.6,0.7-4.7,2.1-6.3c1.4-1.6,3.6-2.7,6.5-3.4
				c3-0.7,7-1,12.1-1h2v-0.9c0-1.8-0.4-3.1-1.3-3.8c-0.9-0.7-2.3-1.1-4.5-1.1c-1.7,0-3.7,0.3-5.9,1c-2.2,0.7-4.3,1.5-6.2,2.6
				l-3.4-8.7c2.1-1.3,4.8-2.4,8-3.3c3.2-0.9,6.2-1.3,8.9-1.3C1636,1190.4,1640.3,1191.8,1643,1194.4z M1632.8,1217.9
				c1.1-1.3,1.7-2.9,1.7-4.9v-0.9h-1.2c-3.2,0-5.5,0.3-6.8,0.9c-1.3,0.6-2,1.5-2,2.9c0,1.1,0.4,2.1,1.1,2.8c0.7,0.7,1.7,1.1,2.8,1.1
				C1630.2,1219.8,1631.7,1219.2,1632.8,1217.9z"
            />
            <path
              className={classes.st3}
              d="M1653.4,1228.1v-52.7h13.3v52.7H1653.4z"
            />
            <path
              className={classes.st3}
              d="M1681.8,1226.4c-3.1-1.6-5.4-3.8-7.1-6.7c-1.7-2.9-2.5-6.3-2.5-10.2c0-3.9,0.8-7.3,2.5-10.1
				c1.7-2.9,4-5.1,7.1-6.6c3-1.5,6.6-2.3,10.7-2.3c4.1,0,7.6,0.8,10.7,2.3c3,1.5,5.4,3.8,7.1,6.6c1.7,2.9,2.5,6.2,2.5,10.1
				c0,3.9-0.8,7.3-2.5,10.2c-1.7,2.9-4,5.1-7.1,6.7c-3,1.6-6.6,2.4-10.7,2.4C1688.4,1228.7,1684.8,1227.9,1681.8,1226.4z
				 M1699.5,1209.5c0-6.2-2.4-9.3-7.1-9.3c-4.7,0-7.1,3.1-7.1,9.3c0,6.2,2.4,9.3,7.1,9.3C1697.1,1218.8,1699.5,1215.7,1699.5,1209.5
				z"
            />
            <path
              className={classes.st3}
              d="M1758.4,1191.3v32.3c0,6.1-1.8,10.7-5.4,13.8c-3.6,3.1-9,4.7-16,4.7c-3.3,0-6.6-0.4-9.7-1.2
				c-3.1-0.8-5.9-1.9-8.1-3.3l3.4-9c2.3,1.2,4.7,2.1,7.1,2.8c2.5,0.6,4.8,1,7,1c2.9,0,5.1-0.6,6.5-1.9c1.4-1.3,2.1-3.3,2.1-6v-2.8
				c-1.1,1.6-2.7,3-4.7,3.9c-2,1-4.3,1.5-6.7,1.5c-3.2,0-6-0.7-8.5-2.2c-2.5-1.5-4.4-3.6-5.8-6.4c-1.4-2.8-2.1-6-2.1-9.6
				s0.7-6.9,2.1-9.6c1.4-2.8,3.3-4.9,5.8-6.5c2.5-1.5,5.3-2.3,8.5-2.3c2.4,0,4.7,0.5,6.7,1.5c2,1,3.6,2.4,4.7,4.1v-4.7H1758.4z
				 M1743.4,1214.9c1.3-1.5,1.9-3.5,1.9-6.1c0-2.6-0.6-4.7-1.9-6.2c-1.3-1.5-3.1-2.2-5.5-2.2c-2.3,0-4.1,0.7-5.4,2.2
				c-1.3,1.5-1.9,3.6-1.9,6.2c0,2.6,0.7,4.6,2,6.1c1.3,1.5,3.1,2.2,5.3,2.2C1740.3,1217.1,1742.1,1216.4,1743.4,1214.9z"
            />
            <path
              className={classes.st3}
              d="M1764.3,1173h13.9v12h-13.9V1173z M1764.6,1228.1v-36.8h13.3v36.8H1764.6z"
            />
            <path
              className={classes.st3}
              d="M1811.7,1209.1l15.6,19h-15.5l-7.7-9.7l-7.7,9.7h-15.5l15.6-19l-14.7-17.8h15.6l6.7,8.7l6.7-8.7h15.6
				L1811.7,1209.1z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_2" className={classes.st0}>
        <g className={classes.st1}>
          <path
            className={classes.st3}
            d="M1490.7,1225.4c-4.1-2.2-7.2-5.4-9.3-9.5c-2.2-4.1-3.3-8.8-3.3-14.2c0-5.4,1.1-10.1,3.3-14.2
			c2.2-4.1,5.3-7.2,9.3-9.5c4.1-2.2,8.8-3.4,14.3-3.4c3.4,0,6.8,0.5,10,1.6c3.2,1,5.8,2.4,7.9,4.2l-4.3,9.7c-4.3-3-8.8-4.6-13.3-4.6
			c-4.3,0-7.6,1.3-9.8,4s-3.4,6.7-3.4,12c0,5.3,1.1,9.4,3.4,12.1s5.5,4.1,9.8,4.1c4.5,0,9-1.5,13.3-4.6l4.3,9.7
			c-2,1.7-4.7,3.1-7.9,4.2c-3.2,1-6.5,1.6-10,1.6C1499.5,1228.8,1494.8,1227.7,1490.7,1225.4z"
          />
          <path
            className={classes.st3}
            d="M1535,1226.4c-3.1-1.6-5.4-3.8-7.1-6.7c-1.7-2.9-2.5-6.3-2.5-10.2c0-3.9,0.8-7.3,2.5-10.1
			c1.7-2.9,4-5.1,7.1-6.6c3-1.5,6.6-2.3,10.7-2.3c4.1,0,7.6,0.8,10.7,2.3c3,1.5,5.4,3.8,7.1,6.6c1.7,2.9,2.5,6.2,2.5,10.1
			c0,3.9-0.8,7.3-2.5,10.2c-1.7,2.9-4,5.1-7.1,6.7c-3,1.6-6.6,2.4-10.7,2.4C1541.6,1228.7,1538,1227.9,1535,1226.4z M1552.7,1209.5
			c0-6.2-2.4-9.3-7.1-9.3c-4.7,0-7.1,3.1-7.1,9.3c0,6.2,2.4,9.3,7.1,9.3C1550.3,1218.8,1552.7,1215.7,1552.7,1209.5z"
          />
          <path
            className={classes.st3}
            d="M1599.7,1201.3l-7.3,0.7c-2.9,0.3-4.9,1.1-6.1,2.3c-1.1,1.2-1.7,3-1.7,5.3v18.5h-13.3v-36.8h12.6v6.4
			c0.8-2.2,2.2-4,4.2-5.1c1.9-1.2,4.3-1.8,7.1-2l3.7-0.3L1599.7,1201.3z"
          />
          <path
            className={classes.st3}
            d="M1633,1194.4c2.8,2.7,4.1,6.8,4.1,12.4v21.2h-12.5v-5.2c-0.7,1.8-1.9,3.2-3.5,4.2c-1.6,1-3.6,1.5-5.9,1.5
			c-2.5,0-4.8-0.5-6.9-1.5c-2-1-3.7-2.4-4.8-4.3c-1.2-1.8-1.8-3.9-1.8-6.2c0-2.6,0.7-4.7,2.1-6.3c1.4-1.6,3.6-2.7,6.5-3.4
			c3-0.7,7-1,12.1-1h2v-0.9c0-1.8-0.4-3.1-1.3-3.8c-0.9-0.7-2.3-1.1-4.5-1.1c-1.7,0-3.7,0.3-5.9,1c-2.2,0.7-4.3,1.5-6.2,2.6
			l-3.4-8.7c2.1-1.3,4.8-2.4,8-3.3c3.2-0.9,6.2-1.3,8.9-1.3C1626,1190.4,1630.3,1191.8,1633,1194.4z M1622.8,1217.9
			c1.1-1.3,1.7-2.9,1.7-4.9v-0.9h-1.2c-3.2,0-5.5,0.3-6.8,0.9c-1.3,0.6-2,1.5-2,2.9c0,1.1,0.4,2.1,1.1,2.8c0.7,0.7,1.7,1.1,2.8,1.1
			C1620.2,1219.8,1621.7,1219.2,1622.8,1217.9z"
          />
          <path
            className={classes.st3}
            d="M1643.4,1228.1v-52.7h13.3v52.7H1643.4z"
          />
          <path
            className={classes.st3}
            d="M1671.8,1226.4c-3.1-1.6-5.4-3.8-7.1-6.7c-1.7-2.9-2.5-6.3-2.5-10.2c0-3.9,0.8-7.3,2.5-10.1
			c1.7-2.9,4-5.1,7.1-6.6c3-1.5,6.6-2.3,10.7-2.3c4.1,0,7.6,0.8,10.7,2.3c3,1.5,5.4,3.8,7.1,6.6c1.7,2.9,2.5,6.2,2.5,10.1
			c0,3.9-0.8,7.3-2.5,10.2c-1.7,2.9-4,5.1-7.1,6.7c-3,1.6-6.6,2.4-10.7,2.4C1678.4,1228.7,1674.8,1227.9,1671.8,1226.4z
			 M1689.5,1209.5c0-6.2-2.4-9.3-7.1-9.3c-4.7,0-7.1,3.1-7.1,9.3c0,6.2,2.4,9.3,7.1,9.3C1687.1,1218.8,1689.5,1215.7,1689.5,1209.5z
			"
          />
          <path
            className={classes.st3}
            d="M1748.4,1191.3v32.3c0,6.1-1.8,10.7-5.4,13.8c-3.6,3.1-9,4.7-16,4.7c-3.3,0-6.6-0.4-9.7-1.2
			c-3.1-0.8-5.9-1.9-8.1-3.3l3.4-9c2.3,1.2,4.7,2.1,7.1,2.8c2.5,0.6,4.8,1,7,1c2.9,0,5.1-0.6,6.5-1.9c1.4-1.3,2.1-3.3,2.1-6v-2.8
			c-1.1,1.6-2.7,3-4.7,3.9c-2,1-4.3,1.5-6.7,1.5c-3.2,0-6-0.7-8.5-2.2c-2.5-1.5-4.4-3.6-5.8-6.4c-1.4-2.8-2.1-6-2.1-9.6
			s0.7-6.9,2.1-9.6c1.4-2.8,3.3-4.9,5.8-6.5c2.5-1.5,5.3-2.3,8.5-2.3c2.4,0,4.7,0.5,6.7,1.5c2,1,3.6,2.4,4.7,4.1v-4.7H1748.4z
			 M1733.4,1214.9c1.3-1.5,1.9-3.5,1.9-6.1c0-2.6-0.6-4.7-1.9-6.2c-1.3-1.5-3.1-2.2-5.5-2.2c-2.3,0-4.1,0.7-5.4,2.2
			c-1.3,1.5-1.9,3.6-1.9,6.2c0,2.6,0.7,4.6,2,6.1c1.3,1.5,3.1,2.2,5.3,2.2C1730.3,1217.1,1732.1,1216.4,1733.4,1214.9z"
          />
          <path
            className={classes.st3}
            d="M1754.3,1173h13.9v12h-13.9V1173z M1754.6,1228.1v-36.8h13.3v36.8H1754.6z"
          />
          <path
            className={classes.st3}
            d="M1801.7,1209.1l15.6,19h-15.5l-7.7-9.7l-7.7,9.7h-15.5l15.6-19l-14.7-17.8h15.6l6.7,8.7l6.7-8.7h15.6
			L1801.7,1209.1z"
          />
        </g>
        <g className={classes.st1}>
          <polygon
            className={classes.st4}
            points="1435.8,1197.7 1434.6,1181 1426.9,1168 1455.2,1196.2 		"
          />
          <polygon
            className={classes.st5}
            points="1437.6,1201.5 1454.8,1224.8 1457.7,1200.1 		"
          />
          <polygon
            className={classes.st6}
            points="1431.2,1221.1 1434.9,1204.2 1451.4,1226.6 		"
          />
          <polygon
            className={classes.st7}
            points="1430.6,1225 1450.2,1230.3 1429.5,1240.3 		"
          />
          <polygon
            className={classes.st8}
            points="1425.7,1240.7 1426.7,1225.4 1407.4,1232.4 		"
          />
          <polygon
            className={classes.st9}
            points="1408.9,1237.3 1423,1255.1 1425.2,1244.7 		"
          />
          <polygon
            className={classes.st10}
            points="1426.4,1257.5 1428.2,1249 1432.8,1257.6 		"
          />
          <polygon
            className={classes.st11}
            points="1402.8,1229.9 1384.7,1207.4 1423.4,1222.4 		"
          />
          <polygon
            className={classes.st12}
            points="1427.8,1218.3 1404.7,1191.1 1431.7,1200.9 		"
          />
          <polygon
            className={classes.st13}
            points="1406.7,1187.7 1431.1,1183.9 1431.9,1197.2 		"
          />
          <polygon
            className={classes.st14}
            points="1380,1202.4 1398.4,1189.6 1422.6,1218.1 		"
          />
          <polygon
            className={classes.st15}
            points="1365.9,1229.6 1376.4,1206.7 1398.3,1230.3 		"
          />
          <polygon
            className={classes.st16}
            points="1362.3,1228.4 1339.4,1208.2 1372.9,1205.1 		"
          />
          <polygon
            className={classes.st17}
            points="1338.5,1204.4 1355.7,1175.9 1372.8,1201.5 		"
          />
          <polygon
            className={classes.st18}
            points="1360,1176.2 1376.4,1200.4 1395,1187.6 		"
          />
          <polygon
            className={classes.st6}
            points="1361.8,1172.9 1414,1159.2 1397.9,1184.7 		"
          />
          <polygon
            className={classes.st19}
            points="1402.7,1184.4 1417.9,1160.1 1429.7,1180.2 		"
          />
        </g>
      </g>
      <g id="Layer_2_copy">
        <g>
          <g>
            <circle className={classes.st20} cx="69.6" cy="69.6" r="62.6" />
            <path
              className={classes.st21}
              d="M69.6,139.1C31.2,139.1,0,107.9,0,69.6C0,31.2,31.2,0,69.6,0s69.6,31.2,69.6,69.6
				C139.1,107.9,107.9,139.1,69.6,139.1z M69.6,13.8c-30.7,0-55.7,25-55.7,55.7c0,30.7,25,55.7,55.7,55.7s55.7-25,55.7-55.7
				C125.3,38.8,100.3,13.8,69.6,13.8z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
