import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import { SLReleaseApi, SLReleaseApiRef } from '../../api';
import React from 'react';
import { Content, ContentHeader, Header } from '@backstage/core-components';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  handleResponse,
  Icons,
  Page,
  PageData,
  Text,
} from '@internal/plugin-sl-assets';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import useAsync from 'react-use/lib/useAsync';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { IconNames, IconValue } from '@internal/backstage-plugin-sl-common';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import { isMobile } from 'react-device-detect';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

type Props = {
  productionEntities: Entity[];
};

const MAPLE_REPOSITORY = 'maple';

const BackportProgressIcon = Object.freeze({
  STALE: IconNames.HOURGLASS_TOP,
  LOADING: IconNames.SYNC,
  ERROR: IconNames.ERROR,
  SUCCESS: IconNames.CHECK_CIRCLE,
});

export const ReleaseControlPanelContent = ({ productionEntities }: Props) => {
  const app = useApp();
  const api = useApi<SLReleaseApi>(SLReleaseApiRef);
  const [error, setError] = React.useState<Error | null>(null);
  const cancelTrigger = React.useRef(false);
  const handleErrorToToast = (errorToHandle: Error | null) => {
    setError(errorToHandle);
    setTimeout(() => setError(null), 5000);
  };
  const [
    showDestructiveConfirmationDialog,
    setShowDestructiveConfirmationDialog,
  ] = React.useState(false);
  const defaultBackportProgressValue: [
    IconValue,
    { message: string; color: string },
  ] = React.useMemo(
    () => [BackportProgressIcon.STALE, { message: 'Waiting...', color: '' }],
    [],
  );
  const filterEntitiesWithHfBranch = (entity: Entity) =>
    (entity.metadata.annotations?.['sectorlabs/branches']?.split(' ')?.length ||
      0) > 1;
  const [operationError, setOperationError] = React.useState<string | null>(
    null,
  );
  const [backportInProgress, setBackportInProgress] = React.useState(false);
  const [backportDialogOpen, setBackportDialogOpen] = React.useState(false);
  const [backportOrder, _] = React.useState(
    productionEntities
      .filter(filterEntitiesWithHfBranch)
      .map(
        entity =>
          entity.metadata.annotations?.['sectorlabs/branches']?.split(
            ' ',
          )[1] as string,
      )
      .concat(['production', 'staging']),
  );
  const defaultBackportProgressValues = React.useMemo(
    () =>
      Object.fromEntries(
        backportOrder.map(hotfixBranch => [
          hotfixBranch,
          defaultBackportProgressValue,
        ]),
      ),
    [backportOrder, defaultBackportProgressValue],
  );
  const [backportProgress, setBackportProgress] = React.useState<
    Record<string, [IconValue, { message: string; color: string }]>
  >(defaultBackportProgressValues);

  const doBackport = React.useCallback(
    async (dryRun: boolean = false) => {
      cancelTrigger.current = false;
      setBackportDialogOpen(true);
      setBackportInProgress(true);
      for (const branchName of backportOrder) {
        if (cancelTrigger.current) {
          break;
        }
        setBackportProgress(prevValue => ({
          ...prevValue,
          [branchName]: [
            BackportProgressIcon.LOADING,
            { message: 'Loading...', color: '' },
          ],
        }));

        try {
          const data = await api
            .backport(MAPLE_REPOSITORY, branchName, dryRun)
            .then(handleResponse);
          setBackportProgress(prevValue => ({
            ...prevValue,
            [branchName]: [BackportProgressIcon.SUCCESS, data],
          }));
        } catch (backportError) {
          setBackportProgress(prevValue => ({
            ...prevValue,
            [branchName]: [
              BackportProgressIcon.ERROR,
              {
                message: (backportError as Error).message,
                color: 'error',
              },
            ],
          }));
        }
      }
      setBackportInProgress(false);
      if (cancelTrigger.current) {
        cancelTrigger.current = false;
        setBackportDialogOpen(false);
        setBackportProgress(defaultBackportProgressValues);
      }
    },
    [
      cancelTrigger,
      api,
      backportOrder,
      setBackportInProgress,
      setBackportProgress,
      defaultBackportProgressValues,
    ],
  );

  const renderList = () => (
    <List dense>
      {backportOrder.map(branchName => {
        // eslint-disable-next-line prefer-const
        let { message, color: textColor } = backportProgress[branchName][1];

        if (
          cancelTrigger.current &&
          backportProgress[branchName][0] === BackportProgressIcon.STALE
        ) {
          message = 'Cancelled!';
        }

        const textColorProps = ['primary', 'secondary', 'error'].includes(
          textColor,
        )
          ? { color: textColor }
          : { sx: { color: textColor } };

        return (
          <ListItem key={branchName}>
            <ListItemAvatar>
              {React.createElement(
                app.getSystemIcon(
                  backportProgress[branchName][0],
                ) as IconComponent,
                { fontSize: 'small', ...textColorProps },
              )}
            </ListItemAvatar>
            <ListItemText
              sx={{ overflow: 'initial' }}
              primary={
                <Text
                  message={`${branchName}${!isMobile ? `: ${message}` : ''}`}
                  color={textColor}
                />
              }
              {...(isMobile
                ? { secondary: <Text message={message} color={textColor} /> }
                : {})}
            />
          </ListItem>
        );
      })}
    </List>
  );

  const onBackportDialogClose = () => {
    if (backportInProgress) {
      cancelTrigger.current = true;
    } else {
      setBackportDialogOpen(false);
      setBackportInProgress(false);
      setBackportProgress(defaultBackportProgressValues);
    }
  };

  const renderBackportProgresMobile = () => (
    <Drawer
      anchor="bottom"
      open={backportDialogOpen}
      onClose={onBackportDialogClose}
    >
      <List>{renderList()}</List>
    </Drawer>
  );

  const renderBackportProgressDesktop = () => (
    <Dialog
      open={backportDialogOpen}
      fullScreen
      maxWidth="xl"
      onClose={onBackportDialogClose}
    >
      <DialogTitle>
        <Typography>Backport in progress</Typography>
        <IconButton
          style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
          onClick={onBackportDialogClose}
        >
          {React.createElement(app.getSystemIcon(Icons.CLOSE) as IconComponent)}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{renderList()}</DialogContentText>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <Snackbar open={error !== null}>
        {error ? <Alert severity="error">{error.message}</Alert> : <div />}
      </Snackbar>
      <Content>
        <ContentHeader title="Maple" />
        <Paper sx={{ marginTop: '10px', padding: 1 }}>
          <Grid container>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  api
                    .createDeployPR(MAPLE_REPOSITORY, 'staging')
                    .then(handleResponse)
                    .then(data => {
                      if (data.type === 'redirect') {
                        window.open(data.url);
                        window.open(`${data.url}/files`);
                      }
                    })
                    .catch(handleErrorToToast)
                }
              >
                Deploy to staging
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setShowDestructiveConfirmationDialog(true);
                }}
              >
                Deploy to staging (destructive)
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  api
                    .createDeployPR(MAPLE_REPOSITORY, 'production')
                    .then(handleResponse)
                    .then(data => {
                      if (data.type === 'redirect') {
                        window.open(data.url);
                        window.open(`${data.url}/files`);
                      }
                    })
                    .catch(handleErrorToToast)
                }
              >
                Deploy to production
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item>
              <Button variant="outlined" onClick={() => doBackport(false)}>
                Backport
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ color: 'gray' }}
                onClick={() => doBackport(true)}
              >
                Backport (dry run)
              </Button>
            </Grid>
          </Grid>
          <br />
          {productionEntities.filter(filterEntitiesWithHfBranch).map(entity => (
            <>
              <Grid container key={entity.metadata.name}>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      api
                        .prepareHf(MAPLE_REPOSITORY, entity.metadata.name)
                        .catch(handleErrorToToast)
                    }
                  >
                    Prepare {entity.metadata.annotations?.['sectorlabs/brand']}{' '}
                    HF
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() =>
                      api
                        .prepareHf(MAPLE_REPOSITORY, entity.metadata.name, true)
                        .catch(handleErrorToToast)
                    }
                  >
                    Prepare {entity.metadata.annotations?.['sectorlabs/brand']}{' '}
                    HF (forced)
                  </Button>
                </Grid>
              </Grid>
              <br />
            </>
          ))}
        </Paper>
      </Content>
      {isMobile
        ? renderBackportProgresMobile()
        : renderBackportProgressDesktop()}
      <Dialog open={!!operationError} onClose={() => setOperationError(null)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>{operationError}</Typography>
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setOperationError(null)}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showDestructiveConfirmationDialog}
        onClose={() => setShowDestructiveConfirmationDialog(false)}
      >
        <DialogTitle>Confirm PR deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              The deploy-to-staging branch and any open PR from it will be
              destroyed. Are you sure?
            </Typography>
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setShowDestructiveConfirmationDialog(false)}>
              No
            </Button>
            <Button
              onClick={() => {
                setShowDestructiveConfirmationDialog(false);
                api
                  .createDeployPR(MAPLE_REPOSITORY, 'staging', true)
                  .then(handleResponse)
                  .then(data => {
                    if (data.type === 'redirect') {
                      window.open(data.url);
                      window.open(`${data.url}/files`);
                    }
                  })
                  .catch(handleErrorToToast);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const ReleaseControlPanelPage = () => {
  const catalogApi = useApi<CatalogApi>(catalogApiRef);

  const productionEntities = useAsync(
    async () =>
      catalogApi
        .queryEntities({
          filter: [
            {
              'metadata.annotations.sectorlabs/environment': 'production',
              'metadata.annotations.sectorlabs/service': 'strat',
            },
          ],
          orderFields: { field: 'metadata.name', order: 'asc' },
        })
        .then(response => response.items),
    [],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => (
      <ReleaseControlPanelContent productionEntities={pageData.value} />
    ),
    [],
  );

  return (
    <Page
      pageData={productionEntities}
      header={() => <Header title="Release control panel" />}
      content={renderPage}
    />
  );
};
