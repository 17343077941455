import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';

type Transition = {
  to: string;
  checks: string[];
  output: string;
  actions: [string];
};

type StateMachine = {
  currentState: string;
  states: string[];
  baseFilter: string | null;
  baseFilters: string[] | null;
  transitions: Record<string, { same: Transition[]; different: Transition[] }>;
};

type Props = {
  stateMachine: StateMachine;
};

export const StateMachineVisualizer = ({ stateMachine }: Props) => {
  const [originalStep, _setOriginalStep] = React.useState(
    stateMachine.states.findIndex(
      state => state === stateMachine.currentState,
    ) || 0,
  );
  const [selectedTransition, setSelectedTransition] =
    React.useState<Transition | null>(null);
  const [activeStep, setActiveStep] = React.useState(originalStep);

  const removeSentenceEndMark = (text: string): string =>
    text && text[text.length - 1] === '.'
      ? text.slice(0, text.length - 1)
      : text;

  const formatChecks = (checks: string[]) => {
    const formattedChecks = checks.map(removeSentenceEndMark);
    if (formattedChecks.length === 1) {
      return formattedChecks[0];
    }
    return `${formattedChecks[0]}, ${formattedChecks
      .slice(1)
      .join(', ')
      .toLowerCase()}`.replace(', not', ', NOT');
  };

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedTransition(
        stateMachine.transitions[stateMachine.states[activeStep]].same
          .concat(
            stateMachine.transitions[stateMachine.states[activeStep]].different,
          )
          .find(
            (transition: Transition) =>
              `${transition.to}-${transition.checks.join('-')}` ===
              event.target.value,
          ) || null,
      );
    },
    [activeStep, stateMachine.states, stateMachine.transitions],
  );

  const findStep = React.useCallback(
    (to: string | undefined) =>
      stateMachine.states.findIndex(state => state === to) || 0,
    [stateMachine.states],
  );

  const selectedNextStep = React.useMemo(
    () => findStep(selectedTransition?.to),
    [findStep, selectedTransition],
  );

  const handleNext = React.useCallback(() => {
    setActiveStep(selectedNextStep);
    setSelectedTransition(null);
  }, [setSelectedTransition, setActiveStep, selectedNextStep]);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setSelectedTransition(null);
  };

  return (
    <Box sx={{ width: '100%', paddingTop: 1 }}>
      <Typography variant="h6">Simulate state machine</Typography>
      <Button
        onClick={() => setActiveStep(originalStep)}
        sx={{ mr: 1, marginBottom: 4 }}
        variant="outlined"
        disabled={activeStep === originalStep}
      >
        Revert state machine to original state
      </Button>
      {stateMachine.baseFilters?.length ? (
        <>
          <Alert severity="info">
            The following conditions must be met:
            <br />
            {stateMachine.baseFilters.map(baseFilter => (
              <Typography variant="caption">
                {' '}
                - {baseFilter}
                <br />
              </Typography>
            ))}
          </Alert>
          <br />
        </>
      ) : null}
      <Stepper nonLinear activeStep={activeStep}>
        {stateMachine.states.map((state, index) => (
          <Step key={state}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {state[0].toUpperCase()}
              {state.slice(1)}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                selectedTransition
                  ? `${selectedTransition.to}-${selectedTransition.checks.join(
                      '-',
                    )}`
                  : ''
              }
              renderValue={value =>
                (value && value.includes('-')
                  ? removeSentenceEndMark(value.split('-')[1])
                  : value) || 'Select transition'
              }
              disabled={
                !(
                  stateMachine.transitions[stateMachine.states[activeStep]].same
                    .length +
                  stateMachine.transitions[stateMachine.states[activeStep]]
                    .different.length
                )
              }
              label="Transitions"
              displayEmpty
              onChange={handleChange}
              sx={{ mr: 1 }}
            >
              <MenuItem key="default" value="">
                Select a transition
              </MenuItem>
              {stateMachine.transitions[stateMachine.states[activeStep]]
                .different.length
                ? [
                    <ListSubheader key="same-state">
                      <Typography variant="caption">
                        To different state
                      </Typography>
                    </ListSubheader>,
                    ...stateMachine.transitions[
                      stateMachine.states[activeStep]
                    ].different
                      .sort((t1, t2) => {
                        if (findStep(t1.to) < findStep(t2.to)) return -1;
                        if (findStep(t1.to) > findStep(t2.to)) return 1;
                        return 0;
                      })
                      .map((transition: Transition) => (
                        <MenuItem
                          key={`${transition.to}-${transition.checks.join(
                            '-',
                          )}`}
                          value={`${transition.to}-${transition.checks.join(
                            '-',
                          )}`}
                        >
                          <Avatar
                            sx={{ width: 20, height: 20, bgcolor: '#9CC9FF' }}
                          >
                            <Typography variant="caption">
                              {findStep(transition.to) + 1}
                            </Typography>
                          </Avatar>
                          &nbsp;&nbsp;
                          <Typography>
                            {formatChecks(transition.checks)}
                          </Typography>
                        </MenuItem>
                      )),
                  ]
                : null}
              {stateMachine.transitions[stateMachine.states[activeStep]].same
                .length
                ? [
                    <ListSubheader key="same-state">
                      <Typography variant="caption">No state change</Typography>
                    </ListSubheader>,
                    ...stateMachine.transitions[
                      stateMachine.states[activeStep]
                    ].same.map((transition: Transition) => (
                      <MenuItem
                        key={`${transition.to}-${transition.checks.join('-')}`}
                        value={`${transition.to}-${transition.checks.join(
                          '-',
                        )}`}
                      >
                        <Avatar
                          sx={{ width: 20, height: 20, bgcolor: '#9CC9FF' }}
                        >
                          <Typography variant="caption">
                            {findStep(transition.to) + 1}
                          </Typography>
                        </Avatar>
                        &nbsp;&nbsp;
                        <Typography>
                          {formatChecks(transition.checks)}
                        </Typography>
                      </MenuItem>
                    )),
                  ]
                : null}
            </Select>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={handleNext}
              sx={{ mr: 1 }}
              variant="outlined"
              disabled={selectedTransition === null}
            >
              Next
            </Button>
          </Box>
          {selectedTransition ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Output
                  </ListSubheader>
                }
              >
                <Divider />
                <ListItem>
                  {removeSentenceEndMark(selectedTransition.output)}
                </ListItem>
              </List>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Next state
                  </ListSubheader>
                }
              >
                <Divider />
                <ListItem>
                  <Avatar sx={{ width: 20, height: 20, bgcolor: '#9CC9FF' }}>
                    <Typography variant="caption">
                      {selectedNextStep + 1}
                    </Typography>
                  </Avatar>
                  &nbsp;&nbsp;
                  {selectedTransition?.to[0].toUpperCase()}
                  {selectedTransition?.to.slice(1)}
                </ListItem>
              </List>
              {selectedTransition.actions.length ? (
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Actions to be performed
                    </ListSubheader>
                  }
                >
                  <Divider />
                  {selectedTransition.actions.map((action: string) => (
                    <ListItem key={action}>
                      {removeSentenceEndMark(action)}
                      <br />
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Box>
          ) : null}
        </>
      </div>
    </Box>
  );
};
