import { AnyShortcut } from '@internal/backstage-plugin-sl-common';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { Icons } from '@internal/plugin-sl-assets';

type Props = {
  shortcut: AnyShortcut;
  size?: 'small' | 'medium' | 'large';
};

export const ShortcutIcon = ({ size = 'medium', shortcut }: Props) => {
  const pxSizes = {
    small: 20,
    medium: 25,
    large: 30,
  };

  const pxSize = pxSizes[size];
  const app = useApp();
  const shortcutIcon = (app.getSystemIcon(shortcut.icon) ||
    app.getSystemIcon(Icons.QUESTION)) as IconComponent;

  return (
    <Avatar style={{ height: `${pxSize + 5}px`, width: `${pxSize + 5}px` }}>
      {React.createElement(shortcutIcon, { fontSize: size })}
    </Avatar>
  );
};
