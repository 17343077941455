import {
  IconValue,
  PageNames,
  PageValue,
  IconNames,
} from '@internal/backstage-plugin-sl-common';
import { ComponentPermissionName } from '@internal/plugin-sl-permissions';

type CommonSidebarProps = {
  text: string;
  icon: IconValue;
};

export type SidebarItemData = CommonSidebarProps & {
  pageName: PageValue;
  permissionName?: ComponentPermissionName;
};

export type SidebarGroupData = CommonSidebarProps & {
  permissionName: ComponentPermissionName;
  items: SidebarItemData[];
};

export type SidebarSection = {
  icon: IconValue;
  items: SidebarItemData[] | SidebarGroupData[];
};

const HelpSectionItems: SidebarItemData[] = [
  {
    text: 'Announcements',
    icon: IconNames.ANNOUNCEMENT,
    pageName: PageNames.ANNOUNCEMENTS,
  },
  {
    text: 'Stack Overflow',
    icon: IconNames.LIVE_HELP,
    pageName: PageNames.QETA,
  },
  {
    text: 'Catalog',
    icon: IconNames.CATEGORY,
    pageName: PageNames.CATALOG_INDEX,
  },
];

export const getSidebarSections: (
  isMobile: boolean,
) => SidebarSection[] = isMobile => [
  {
    icon: IconNames.SEARCH,
    items: [
      {
        icon: IconNames.INVENTORY,
        text: 'Cheatsheet',
        pageName: PageNames.CHEATSHEET,
      },
      ...(isMobile ? HelpSectionItems : []),
    ],
  },
  ...(!isMobile
    ? [
        {
          icon: IconNames.LIVE_HELP,
          items: HelpSectionItems,
        },
      ]
    : []),
  {
    icon: IconNames.SUPERVISOR,
    items: [
      {
        text: 'Reviews',
        icon: IconNames.RATE_REVIEW,
        permissionName: 'sl.reviews.access',
        items: [
          {
            text: 'My PRs',
            icon: IconNames.PERSON,
            pageName: PageNames.REVIEWS_MY_PR,
          },
          {
            text: 'My reviews',
            icon: IconNames.RATE_REVIEW,
            pageName: PageNames.REVIEWS_MY,
          },
          {
            text: 'Codeowners',
            icon: IconNames.SUPERVISOR,
            pageName: PageNames.REVIEWS_CODEOWNERS,
          },
          {
            text: 'State machines',
            icon: IconNames.SETTINGS,
            pageName: PageNames.REVIEWS_REPOSITORY_STATE_MACHINE,
          },
        ],
      },
      {
        text: 'On-call',
        icon: IconNames.CONTACT_EMERGENCY,
        permissionName: 'sl.oncall.access',
        items: [
          {
            text: 'Schedule',
            icon: IconNames.CALENDAR,
            pageName: PageNames.ON_CALL_SCHEDULE,
          },
          {
            text: 'Availability',
            icon: IconNames.EVENT_AVAILABLE,
            pageName: PageNames.ON_CALL_AVAILABILITY,
          },
          {
            text: 'Payment',
            icon: IconNames.SUMMARY,
            pageName: PageNames.ON_CALL_SUMMARY,
          },
        ],
      },
    ],
  },
  {
    icon: IconNames.RELEASE,
    items: [
      {
        icon: IconNames.RELEASE,
        text: 'Release',
        permissionName: 'sl.release.access',
        items: [
          {
            icon: IconNames.RELEASE,
            text: 'Version',
            pageName: PageNames.RELEASE_VERSION,
          },
          {
            icon: IconNames.BOOK,
            text: 'Notes',
            pageName: PageNames.RELEASE_NOTES,
          },
          {
            icon: IconNames.BUILD,
            text: 'Control Panel',
            pageName: PageNames.RELEASE_CONTROL_PANEL,
          },
        ],
      },
      {
        icon: IconNames.BUG_REPORT,
        text: 'QA',
        permissionName: 'sl.qa.access',
        items: [
          {
            icon: IconNames.BUILD,
            text: 'CI Jobs',
            pageName: PageNames.QA_JOB_PAGE,
          },
          {
            icon: IconNames.PERSON,
            text: 'Assignments',
            pageName: PageNames.QA_ASSIGNMENTS,
          },
        ],
      },
    ],
  },
  {
    icon: IconNames.SETTINGS,
    items: [
      {
        text: 'Settings',
        icon: IconNames.SETTINGS,
        pageName: PageNames.USER_SETTINGS,
      },
    ],
  },
];
