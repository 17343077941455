import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    'enable-background': 'new 0 0 135 27',
  },
});

export const ZameenIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="zameen_logo"
      x="0px"
      y="0px"
      viewBox="0 0 27 27"
      className={classes.svg}
      xmlSpace="preserve"
    >
      <g>
        <rect
          x="3.7"
          y="7.7"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          width="18.5"
          height="17.4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#00A551"
          d="M23.8,8.5V4.2h-6.1l-4.8-3.4L0,10v6.1l2-1.5v10.5l4.4,0   c-0.2-0.7-0.2-1.4,0.2-2.1l5-8.8l-4.7,1.2c-1.2,0.3-2.5-0.4-2.8-1.7c-0.3-1.2,0.4-2.5,1.7-2.8l9.9-2.4c0,0,0.1,0,0.1,0l0,0   c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2   c1.2,0.7,1.6,2.2,0.9,3.4l-4.8,8.4l5.2-1.3c1.2-0.3,2.5,0.4,2.8,1.7c0.3,1.2-0.4,2.5-1.7,2.8l-5.8,1.5l9.7,0l0-10.5l2,1.5V10   L23.8,8.5z"
        />
      </g>
    </SvgIcon>
  );
};
