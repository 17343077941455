import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: '28px',
    height: '28px',
    'enable-background': 'new -218 385 175 25',
  },
  st0: {
    fill: '#73C41D',
  },
});

export const BambooHrIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="-218 385 175 25"
      className={classes.svg}
      xmlSpace="preserve"
    >
      <g id="Symbols">
        <g id="logo_x2F_bamboohr_x2F_green">
          <g id="BambooHR-Logo">
            <path
              id="Fill-1"
              className={classes.st0}
              d="M-174.3,407.3c-4.1,0-6-3.3-6-6.4c0-3.2,2-6.4,6.1-6.4c3.2,0,5.9,2.6,5.9,6.5
				C-168.4,404.7-171.2,407.3-174.3,407.3L-174.3,407.3z M-168.6,395.1L-168.6,395.1c-1.2-1.3-2.9-2.9-5.9-2.9
				c-4.9,0-8.6,3.7-8.6,8.8c0,5.4,4,8.8,8.5,8.8c2.8,0,4.8-1.3,6-2.8h0.1v2.2h2.7v-16.4h-2.7V395.1z"
            />
            <path
              id="Fill-3"
              className={classes.st0}
              d="M-144.7,392.2c-2.9,0-4.9,1.7-5.7,3.4c-0.7-1.9-2.8-3.4-5.4-3.4c-2.1,0-3.8,1.1-4.9,2.6h-0.1
				v-2.1h-2.7v16.4h2.7V400c0-3.4,1.8-5.4,4.5-5.4c2.4,0,3.9,2.1,3.9,4.8v9.8h2.8V400c0-3.8,2.2-5.4,4.5-5.4c2.8,0,4,2.2,4,4.8v9.8
				h2.7v-9.6C-138.4,394.7-141,392.2-144.7,392.2"
            />
            <path
              id="Fill-5"
              className={classes.st0}
              d="M-108.2,407.3c-3.2,0-6-2.6-6-6.4c0-3.6,2.5-6.3,6-6.3c3.6,0,6,2.8,6,6.3
				C-102.2,404.5-104.7,407.3-108.2,407.3 M-108.2,392.2c-5,0-8.8,3.7-8.8,8.8c0,5,3.8,8.8,8.8,8.8c5.1,0,8.8-4,8.8-8.8
				C-99.4,395.9-103.1,392.2-108.2,392.2"
            />
            <path
              id="Fill-7"
              className={classes.st0}
              d="M-88.8,407.3c-3.2,0-6-2.6-6-6.4c0-3.6,2.5-6.3,6-6.3c3.6,0,6,2.8,6,6.3
				C-82.9,404.5-85.4,407.3-88.8,407.3 M-88.8,392.2c-5,0-8.8,3.7-8.8,8.8c0,5,3.8,8.8,8.8,8.8c5.1,0,8.8-4,8.8-8.8
				C-80,395.9-83.7,392.2-88.8,392.2"
            />
            <polygon
              id="Fill-9"
              className={classes.st0}
              points="-67.1,399.8 -75.9,399.8 -75.9,392.8 -77.9,392.8 -77.9,409.2 -75.9,409.2 -75.9,401.6
				-67.1,401.6 -67.1,409.2 -65.1,409.2 -65.1,392.8 -67.1,392.8 			"
            />
            <path
              id="Fill-11"
              className={classes.st0}
              d="M-60.1,400.5v-5.9h3c2.1,0,3.3,1.1,3.3,3c0,1.6-1.1,2.9-3.3,2.9H-60.1z M-55.6,402.1
				c2.1-0.4,3.8-2.2,3.8-4.5c0-2.3-1.5-4.8-5.6-4.8h-4.7v16.4h2v-6.8h2.2l5,6.8h2.5L-55.6,402.1z"
            />
            <polygon
              id="Fill-13"
              className={classes.st0}
              points="-49.8,392.8 -47.2,392.8 -47.2,393.2 -48.3,393.2 -48.3,396.4 -48.8,396.4
				-48.8,393.2 -49.8,393.2 			"
            />
            <polygon
              id="Fill-15"
              className={classes.st0}
              points="-44.9,395.3 -43.8,392.8 -43.2,392.8 -43,396.4 -43.5,396.4 -43.6,393.5 -44.7,395.9
				-45.1,395.9 -46.2,393.5 -46.3,396.4 -46.8,396.4 -46.6,392.8 -46,392.8 			"
            />
            <path
              id="Fill-17"
              className={classes.st0}
              d="M-194.3,407.5c-3.4,0-6.3-2.7-6.3-6.3c0-3.6,2.5-6.6,6.4-6.6c4,0,6.3,3.2,6.3,6.5
				C-187.9,404.7-190.4,407.5-194.3,407.5 M-194,392c-3.2,0-4.9,1.1-6.1,2.3l-0.3,0.3l0-10.6h-2.7v17.3c0,5.3,4.1,8.7,8.8,8.7
				c5.2,0,9.1-4,9.1-9.1C-185.1,396.1-189.3,392-194,392"
            />
            <path
              id="Fill-19"
              className={classes.st0}
              d="M-127.6,407.5c-3.4,0-6.3-2.7-6.3-6.3c0-3.6,2.5-6.6,6.4-6.6c4,0,6.3,3.2,6.3,6.5
				C-121.3,404.7-123.7,407.5-127.6,407.5 M-127.3,392c-3.2,0-4.9,1.1-6.1,2.3l-0.3,0.3l0-9h-2.7v15.7c0,5.3,4.1,8.7,8.8,8.7
				c5.2,0,9.1-4,9.1-9.1C-118.5,396.1-122.6,392-127.3,392"
            />
            <path
              id="Fill-21"
              className={classes.st0}
              d="M-204.4,395.5c0,0-1.2-0.2-2.2-0.4c-1.1-0.2-1.6-0.3-3.1-0.9c-1.4-0.6-2.8-2-4-3.6
				c-1.3-1.6-4.3-4.1-4.3-4.1s0.9,0.1,1.9,0.3c0.8,0.2,2.8,0.8,4.8,1.9c0.5,0.2,0.7,0.4,1.2,0.7c0.7,0.4,1.4,1.1,2.1,1.8
				c0.8,0.8,1.5,1.5,2.1,2.2c-0.7-1.2-2.4-5.6-5.7-9.2c-0.3-0.4,2.3,1.3,3,2c2,2.2,2.3,3.4,3,5.4L-204.4,395.5z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
