export const PageNames = Object.freeze({
  QA_ASSIGNMENTS: 'release-qa-assignments',
  RELEASE_VERSION: 'release-version',
  RELEASE_NOTES: 'release-notes',
  RELEASE_CONTROL_PANEL: 'release-control-panel',
  QA_JOB_PAGE: 'qa-job-page',
  SL_CLI_LOGIN: 'sl-cli-login',
  SL_CLI_SHORTCUTS_LOGIN: 'sl-cli-shortcuts-login',
  ON_CALL_SCHEDULE: 'on-call-schedule',
  ON_CALL_AVAILABILITY: 'on-call-availability',
  ON_CALL_SUMMARY: 'on-call-summary',
  PERMISSIONS_EXPLAIN: 'permissions-explain',
  REVIEWS_MY_PR: 'reviews-my-pr',
  REVIEWS_MY: 'reviews-my',
  REVIEWS_CODEOWNERS: 'reviews-codeowners',
  REVIEWS_REPOSITORY_STATE_MACHINE: 'reviews-repository-state-machine',
  ANNOUNCEMENTS: 'announcements',
  QETA: 'qeta',
  TECH_DOCS: 'tech-docs',
  CATALOG_INDEX: 'catalog',
  CHEATSHEET: 'cheatsheet',
  USER_SETTINGS: 'user-settings',
});
