import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Icons } from '@internal/plugin-sl-assets';
import {
  Issue,
  IssuesExtraInformation,
  IssuesExtraInformationChanges,
  IssueTypes,
} from './types';

import { IssueRow } from './issueRow';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  sectionTitle: string;
  issues: Issue[];
  portals: string[];
  issuesData: Record<string, IssuesExtraInformation>;
  setGenerateDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIssueData: (
    pivotalId: number,
    changes: IssuesExtraInformationChanges,
  ) => void;
  withMultiPortalSelect?: boolean;
};

export const ReleaseNotesSection = ({
  sectionTitle,
  issues,
  portals,
  issuesData,
  onHandleIssueData,
  setGenerateDisabled,
  withMultiPortalSelect = false,
}: Props) => {
  const app = useApp();

  return issues.length ? (
    <Paper sx={{ marginBottom: 3 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={React.createElement(
            app.getSystemIcon(Icons.EXPAND) as IconComponent,
          )}
        >
          <Typography variant="h2">
            {sectionTitle.replace('Maple', '🍁Maple')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', padding: '0 5px' }}>
                  Releases
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>User facing RN</TableCell>
                <TableCell>Labels</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const selectedIssues = issues.filter(issue =>
                        (
                          [
                            IssueTypes.BUG,
                            IssueTypes.FEATURE,
                          ] as (typeof IssueTypes)[keyof typeof IssueTypes][]
                        ).includes(issue.type),
                      );
                      const shouldActivate = !selectedIssues.every(
                        issue => issuesData[issue.pivotalId.toString()].checked,
                      );

                      selectedIssues.forEach(issue =>
                        onHandleIssueData(issue.pivotalId, {
                          checked: shouldActivate,
                        }),
                      );
                    }}
                  >
                    {React.createElement(
                      app.getSystemIcon(Icons.CHECK_CIRCLE) as IconComponent,
                    )}
                  </IconButton>
                </TableCell>
                {withMultiPortalSelect ? <TableCell>Portals</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {issues.map((issue: Issue) => (
                <IssueRow
                  issue={issue}
                  portals={portals}
                  issueData={issuesData[issue.pivotalId]}
                  onHandleIssueData={onHandleIssueData}
                  setGenerateDisabled={setGenerateDisabled}
                  withMultiPortalSelect={withMultiPortalSelect}
                />
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Paper>
  ) : null;
};
