import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { BaseApi } from '@internal/plugin-sl-assets';

export class SLCiApi extends BaseApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, 'sl-ci');
  }

  public async getJobContentPaths(jobId: string) {
    return this.get(`/job/${jobId}`);
  }

  public async getJobContentItem(jobId: string, item: string) {
    return this.get(`/job/${jobId}/${encodeURIComponent(item)}`);
  }
}

export const SLCiApiRef = createApiRef<SLCiApi>({
  id: 'plugin.sl-ci.api',
});
