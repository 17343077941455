import { ComponentDefinition } from './types';

export const ComponentDefinitions: ComponentDefinition[] = [
  // Permissions to access the software catalog
  {
    name: 'sl.catalog.search',
    description: 'Search the Backstage catalog',
    matchType: 'all',
    members: ['search.general.read'],
  },
  {
    name: 'sl.catalog.edit',
    description: 'Edit the Backstage catalog',
    matchType: 'all',
    members: [
      'search.general.create',
      'search.general.update',
      'search.general.delete',
    ],
  },

  {
    name: 'sl.cheatsheet.view',
    description: 'A collection of all the URLs that are of interest',
    matchType: 'all',
    members: [],
  },

  // SL-Bots ping
  {
    name: 'sl.bots.ping',
    description: 'Ping the status of any of the SL bots',
    matchType: 'all',
    members: ['proxy.sl-bots.ping.read'],
  },
  {
    name: 'sl.bots.users',
    description: 'Get information about users from a bot',
    matchType: 'all',
    members: ['proxy.sl-bots.users.read'],
  },

  // On-call sidebar
  {
    name: 'sl.oncall.access',
    description: 'See On-call in the sidebar',
    matchType: 'one',
    members: [
      'sl.oncall.availability',
      'sl.oncall.schedule',
      'sl.oncall.summary',
    ],
  },

  // The availability form page
  {
    name: 'sl.oncall.availability',
    description: 'View and update on-call availability',
    matchType: 'all',
    members: [
      'proxy.oncall.availability.read',
      'proxy.oncall.availability.update',
      'proxy.oncall.availability.stats.read',
      'proxy.oncall.expectations.read',
    ],
  },
  // The on-call schedule page
  {
    name: 'sl.oncall.schedule',
    description: 'View and update the on-call schedule',
    matchType: 'all',
    members: ['proxy.oncall.schedule.read', 'proxy.oncall.schedule.update'],
  },

  // The monthly summary page
  {
    name: 'sl.oncall.summary',
    description: 'View the montly summary of on-call (incl. payment)',
    matchType: 'all',
    members: ['proxy.oncall.summary.read'],
  },
  // Homepage and other public components
  {
    name: 'sl.oncall.public',
    description: 'View public on-call information',
    matchType: 'all',
    members: ['proxy.oncall.today.read'],
  },

  // Access to admin urls of portals
  {
    name: 'sl.oncall.shortcuts.admin',
    description: 'Open production admin panels',
    matchType: 'all',
    members: ['proxy.oncall.admin.read'],
  },
  {
    name: 'sl.oncall.shortcuts.admin.cable',
    description: 'Open cable admin panels',
    matchType: 'all',
    members: ['proxy.oncall.admin.cable.read'],
  },

  {
    name: 'sl.oncall.shortcuts.shifts',
    description: 'See own on-call shifts',
    matchType: 'all',
    members: ['proxy.oncall.shifts.read'],
  },
  {
    name: 'sl.oncall.shortcuts.notifications',
    description: 'Set default OpsGenie notifications',
    matchType: 'all',
    members: ['proxy.oncall.opsgenie.notifications.create'],
  },

  // Access to CI workflows
  {
    name: 'sl.qa.access',
    description: 'See QA in the sidebar',
    matchType: 'one',
    members: ['sl.qa.workflows.view'],
  },
  {
    name: 'sl.qa.workflows.view',
    description: 'View CI/GA artifacts',
    matchType: 'all',
    members: ['sl-ci.workflows.read'],
  },
  {
    name: 'sl.tools.github.commit-tracker',
    description: 'Access the commit tracker',
    matchType: 'all',
    members: ['proxy.sl-tools.github.commit-tracker.read'],
  },

  // SL Release bot
  // Access to see assignments on portals
  {
    name: 'sl.release.access',
    description: 'See Release in the sidebar',
    matchType: 'one',
    members: [
      'sl.release.qa.assignments.view',
      'sl.release.qa.assignments.change',
      'sl.release.version.view',
    ],
  },
  {
    name: 'sl.release.qa.assignments.view',
    description: 'View the QA assignments',
    matchType: 'all',
    members: ['proxy.release.qa.assignments.read'],
  },
  // Access to changing assignments on portals
  {
    name: 'sl.release.qa.assignments.change',
    description: 'Change QA assignments',
    matchType: 'all',
    members: ['proxy.release.qa.assignments.update'],
  },
  // Access to get commit versions (for comparing with production environments)
  {
    name: 'sl.release.version.view',
    description: 'Access to Release version page',
    matchType: 'all',
    members: ['proxy.release.version.create'],
  },
  {
    name: 'sl.release.notes.data.view',
    description: 'Access to Release Notes page',
    matchType: 'all',
    members: ['proxy.release.notes.data.read'],
  },
  {
    name: 'sl.release.view',
    description: 'View release details',
    matchType: 'all',
    members: ['proxy.release.releases.read'],
  },

  // SL CLI
  {
    name: 'sl.cli.access',
    description: 'See SL-CLI in shortcuts',
    matchType: 'one',
    members: ['sl.cli.login', 'sl.cli.shortcuts.login'],
  },
  {
    name: 'sl.cli.login',
    description: 'Grant Backstage aceess to SL CLI',
    matchType: 'all',
    members: [],
  },
  {
    name: 'sl.cli.shortcuts.login',
    description: 'To perform login and store the token in Apple Shortcuts',
    matchType: 'all',
    members: [],
  },
  {
    name: 'sl.cli.catalog',
    description:
      'To obtain access to the filtered catalog to be used in SL CLI',
    matchType: 'all',
    members: ['sl-cli.catalog.read'],
  },

  // Release endpoints only accessible thourgh SL CLI
  {
    name: 'sl.cli.release.slack.updates',
    description: 'Mark the deploy status of a portal in Slack',
    matchType: 'all',
    members: [
      'proxy.release.deployed.slack.create',
      'proxy.release.deploying.slack.create',
    ],
  },
  // Release PRs
  {
    name: 'sl.release.deploy',
    description: 'Prepare the PR for the next release of staging/production',
    matchType: 'all',
    members: [
      'proxy.release.deploy.next.create',
      'proxy.release.deploy.hotfix.create',
      'proxy.release.deploy.backport.create',
    ],
  },
  {
    name: 'sl.release.deploy.next.view',
    description: 'Commands to find and access deploy to staging/production PRs',
    matchType: 'all',
    members: ['proxy.release.deploy.next.read'],
  },
  {
    name: 'sl.argocd.resource-tree',
    description: 'Proxy to read resource tree endpoint of ArgoCD',
    matchType: 'all',
    members: ['proxy.argocd.application.resource-tree.read'],
  },

  // Reviews
  {
    name: 'sl.reviews.access',
    description: 'See Reviews in the sidebar',
    matchType: 'one',
    members: ['sl.reviews.state-machines', 'sl.reviews.pr'],
  },
  {
    name: 'sl.reviews.pr',
    description: 'See PR Review statuses',
    matchType: 'all',
    members: ['proxy.reviews.reviews.read'],
  },
  {
    name: 'sl.reviews.state-machines',
    description: 'See the reviews state machines',
    matchType: 'all',
    members: [
      'proxy.reviews.repositories.read',
      'proxy.reviews.state-machines.read',
    ],
  },

  // Access to announcements
  {
    name: 'sl.announcements.read-only',
    description: 'Read announcements',
    matchType: 'all',
    members: ['announcement.entity.read'],
  },
  {
    name: 'sl.announcements.modify',
    description: 'Change announcements',
    matchType: 'all',
    members: [
      'announcement.entity.create',
      'announcement.entity.update',
      'announcement.entity.delete',
    ],
  },

  // Ask
  {
    name: 'sl.qeta',
    description: 'Use "Stack Overflow"',
    matchType: 'all',
    members: ['qeta.create', 'qeta.update', 'qeta.read', 'qeta.delete'],
  },
  {
    name: 'qeta.create.question',
    description: 'Add a question on Stack Overflow (frontend)',
    matchType: 'all',
    members: [],
  },
  {
    name: 'qeta.create.answer',
    description: 'Add an answer on Stack Overflow (frontend)',
    matchType: 'all',
    members: [],
  },
  {
    name: 'sl.meta.permissions',
    description: 'See how permissions are granted',
    matchType: 'all',
    members: [],
  },
];
