import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { DAYS_OF_THE_WEEK, Icons } from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

export const TodayDate = () => {
  const app = useApp();
  const date = new Date();

  return (
    <Paper>
      <Container style={{ textAlign: 'center' }}>
        <ListItem>
          <ListItemIcon>
            {React.createElement(
              app.getSystemIcon(Icons.CALENDAR_TODAY) as IconComponent,
              { fontSize: 'large' },
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h3">
              {DAYS_OF_THE_WEEK[(date.getDay() + 6) % 7]},{' '}
              {date.toLocaleDateString()}
            </Typography>
          </ListItemText>
        </ListItem>
      </Container>
    </Paper>
  );
};
