type Environment = {
  url: string;
  protocol: string;
};

export const EnvironmentNames: Record<string, string> = Object.freeze({
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
});

export const Environments: Record<string, Environment> = Object.freeze({
  [EnvironmentNames.LOCAL]: { url: 'localhost:7000', protocol: 'http' },
  [EnvironmentNames.DEVELOPMENT]: {
    url: 'backstage-development.sector.sh',
    protocol: 'https',
  },
  [EnvironmentNames.PRODUCTION]: {
    url: 'backstage.sector.sh',
    protocol: 'https',
  },
});

export const isEnvironment = (
  environment: (typeof EnvironmentNames)[string],
) => {
  if (window.location.href.includes(Environments[environment].url)) {
    return true;
  }
  return false;
};

export const determineEnvironment = (): (typeof EnvironmentNames)[string] => {
  for (const environmentKey in Environments) {
    if (isEnvironment(environmentKey)) {
      return environmentKey;
    }
  }
  return EnvironmentNames.LOCAL;
};

export const switchEnvironment = (environment: keyof typeof Environments) => {
  const currentEnvironment = determineEnvironment();
  // Local should not redirect
  if (
    currentEnvironment === environment ||
    currentEnvironment === EnvironmentNames.LOCAL
  ) {
    return;
  }
  window.location.href = window.location.href
    .replace(
      Environments[currentEnvironment].url,
      Environments[environment].url,
    )
    .replace(
      Environments[currentEnvironment].protocol,
      Environments[environment].protocol,
    );
};
