import { PermissionAction } from '../defaults/types';

export const AllComponentPermissionNames = [
  'sl.catalog.search',
  'sl.catalog.edit',
  'sl.cheatsheet.view',
  'sl.bots.ping',
  'sl.bots.users',
  'sl.oncall.access',
  'sl.oncall.availability',
  'sl.oncall.schedule',
  'sl.oncall.summary',
  'sl.oncall.shortcuts.admin',
  'sl.oncall.shortcuts.admin.cable',
  'sl.oncall.shortcuts.shifts',
  'sl.oncall.shortcuts.notifications',
  'sl.oncall.public',
  'sl.qa.access',
  'sl.qa.workflows.view',
  'sl.release.access',
  'sl.release.qa.assignments.view',
  'sl.release.qa.assignments.change',
  'sl.release.notes.data.view',
  'sl.release.deploy',
  'sl.release.deploy.next.view',
  'sl.release.view',
  'sl.tools.github.commit-tracker',
  'sl.release.version.view',
  'sl.reviews.access',
  'sl.reviews.state-machines',
  'sl.reviews.pr',
  'sl.cli.access',
  'sl.cli.login',
  'sl.cli.release.slack.updates',
  'sl.cli.shortcuts.login',
  'sl.cli.catalog',
  'sl.argocd.resource-tree',
  'sl.announcements.read-only',
  'sl.announcements.modify',
  'sl.qeta',
  'qeta.create.question',
  'qeta.create.answer',
  'sl.meta.permissions',
] as const;

export const AllPermissionGroupNames = [
  'sl.hr',
  'sl.backstage.admin',
  'sl.devs',
  'sl.ra.manager',
  'sl.qa.manager',
  'all',
  'sl.release.wizard.maple',
] as const;

export type ComponentPermissionName =
  (typeof AllComponentPermissionNames)[number];

export type PermissionGroupName = (typeof AllPermissionGroupNames)[number];

export type UrlDefinition = {
  baseName: string;
  actions: PermissionAction[];
  urlMatch: string;
};

export type PermissionGranting = {
  permissions: ComponentPermissionName[];
  permissionGroups: PermissionGroupName[];
};

export type GithubGroupGranting = PermissionGranting & {
  groupName: string;
};

export type UserGranting = PermissionGranting & {
  email: string;
};

export type PermissionGroupDefinition = {
  name: PermissionGroupName;
  permissions: ComponentPermissionName[];
  description: string;
};

type BaseComponentDefinition = {
  name: ComponentPermissionName;
  description: string;
};

export type MatchOneComponentDefinition = BaseComponentDefinition & {
  matchType: 'one';
  members: ComponentPermissionName[];
};

export type MatchAllComponentDefinition = BaseComponentDefinition & {
  matchType: 'all';
  members: string[];
};

export type ComponentDefinition =
  | MatchOneComponentDefinition
  | MatchAllComponentDefinition;

export type SanitizedComponentDefinition = ComponentDefinition;

export const isComponentPermissionName = (
  name: string,
): name is ComponentPermissionName => {
  const permissionNames: readonly string[] = AllComponentPermissionNames;
  return permissionNames.includes(name);
};

export const isPermissionGroupName = (
  name: string,
): name is PermissionGroupName => {
  const permissionGroupNames: readonly string[] = AllPermissionGroupNames;
  return permissionGroupNames.includes(name);
};
