import { ComponentPermissionName } from '@internal/plugin-sl-permissions';
import { CommandValue, IconValue, PageValue } from '../enums';

export const AllShortcutTypes = [
  'root',
  'rootPage',
  'group',
  'page',
  'command',
  'url',
  'rootUrl',
] as const;

export const ShortcutTypes = Object.freeze({
  ROOT: 'root',
  ROOT_PAGE: 'rootPage',
  GROUP: 'group',
  PAGE: 'page',
  COMMAND: 'command',
  URL: 'url',
  ROOT_URL: 'rootUrl',
} as const);

export type ShortcutValue = (typeof AllShortcutTypes)[number];

export type BaseShortcut = {
  tags: string;
  title: string;
  description?: string;
  icon: IconValue;
  permissionName?: ComponentPermissionName;
};

export type PageShortcut = BaseShortcut & {
  type: 'page';
  pageName: PageValue;
};

export type UrlShortcut = BaseShortcut & {
  type: 'url';
  url: string;
  description: string;
};

export type RootShortcut = BaseShortcut & {
  type: 'root';
  disallowRecursiveSearch?: boolean;
  results: Record<string, AnyNonRootShortcut>;
};

export type RootUrlShortcut = BaseShortcut & {
  type: 'rootUrl';
  url: string;
  description: string;
};

export type RootPageShortcut = BaseShortcut & {
  type: 'rootPage';
  pageName: PageValue;
};

export type GroupShortcut = BaseShortcut & {
  type: 'group';
  results: Record<string, AnyNonRootShortcut>;
};

export type CommandShortcutInput = {
  key: string;
  hint: string;
  optional: boolean;
  type?: 'bool' | 'int';
};

export type CommandShortcut = BaseShortcut & {
  type: 'command';
  component: [CommandValue, string[]];
  inputs?: CommandShortcutInput[];
  description: string;
};

export type SidebarGroupShortcut = RootPageShortcut | RootShortcut;

export type AnyPageShortcut = PageShortcut | RootPageShortcut;

export type AnyRootShortcut = RootShortcut | RootPageShortcut | RootUrlShortcut;

export type AnyNonRootShortcut =
  | PageShortcut
  | GroupShortcut
  | UrlShortcut
  | CommandShortcut;

export type AnyGroupShortcut = RootShortcut | GroupShortcut;

export type AnyNonGroupShortcut =
  | AnyPageShortcut
  | UrlShortcut
  | RootUrlShortcut
  | CommandShortcut;

export type AnyExternalShortcut = UrlShortcut | CommandShortcut;

export type AnyShortcut = AnyRootShortcut | AnyNonRootShortcut;

export const isShortcutAnyGroup = (
  entry: AnyShortcut,
): entry is AnyGroupShortcut => {
  return ([ShortcutTypes.ROOT, ShortcutTypes.GROUP] as string[]).includes(
    entry.type,
  );
};

export const isShortcutAnyPage = (
  entry: AnyShortcut,
): entry is AnyPageShortcut => {
  return ([ShortcutTypes.PAGE, ShortcutTypes.ROOT_PAGE] as string[]).includes(
    entry.type,
  );
};

export const isShortcutInSidebar = (
  entry: AnyShortcut,
): entry is SidebarGroupShortcut => {
  return ([ShortcutTypes.ROOT, ShortcutTypes.ROOT_PAGE] as string[]).includes(
    entry.type,
  );
};
