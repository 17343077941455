import { UrlShortcut } from '@internal/backstage-plugin-sl-common';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { ShortcutIcon } from './shortcutIcon';

type Props = {
  shortcut: UrlShortcut;
};

export const UrlButton = ({ shortcut }: Props) => (
  <Tooltip
    enterDelay={500}
    title={
      <>
        <p>{shortcut.description}</p>
        <p>
          {shortcut.url.length > 60
            ? `${shortcut.url.slice(0, 60)}...`
            : shortcut.url}
        </p>
      </>
    }
  >
    <Button
      variant="text"
      color="primary"
      onClick={() => window.open(shortcut.url)}
      startIcon={<ShortcutIcon shortcut={shortcut} size="small" />}
    >
      {shortcut.title}
    </Button>
  </Tooltip>
);
