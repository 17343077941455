import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { BaseApi } from '@internal/plugin-sl-assets';

export class SLShortcutsApi extends BaseApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, 'sl-shortcuts');
  }

  public async getShortcuts() {
    return this.get(`/shortcuts`);
  }
}

export const SLShortcutsApiRef = createApiRef<SLShortcutsApi>({
  id: 'plugin.sl-shortcuts.api',
});
