import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AvatarContact, Employee, Icons } from '@internal/plugin-sl-assets';
import { PR } from '../types';
import { PRStatusIcon, ReviewStatusValue, StatusIcon } from '../status';
import { CodeownerReviewChip } from './codeownerReviewChip';
import { sortReviews } from './utils';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  prs: PR[];
  showAllCodeowners?: boolean;
  individualCodeowners?: boolean;
};

export const PRList = ({
  prs,
  showAllCodeowners = false,
  individualCodeowners = false,
}: Props) => {
  const app = useApp();

  if (!prs.length)
    return (
      <Typography variant="h6" sx={{ margin: 5 }}>
        No reviews to show
      </Typography>
    );

  const getPrTitle = (pr: PR): string =>
    `${pr.repository.replace('SectorLabs/', '')}#${pr.number}: ${
      pr.title || `Placeholder title for #${pr.number}`
    }`;

  return (
    <List>
      {prs.map((pr: PR, prIndex: number) => (
        <ListItem divider key={`pr${prIndex}`} disableGutters>
          <ListItemAvatar
            sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}
          >
            <AvatarContact
              employee={pr.author || ({ name: '??' } as Employee)}
              avatarIconSize={30}
            />
            <Avatar sx={{ height: 25, width: 25 }}>
              {React.createElement(
                app.getSystemIcon(PRStatusIcon[pr.state]) ||
                  (app.getSystemIcon(Icons.QUESTION) as IconComponent),
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{ maxWidth: '60%' }}
            primary={
              <Tooltip
                title={getPrTitle(pr)}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    `https://github.com/${pr.repository}/pull/${pr.number}`,
                  )
                }
              >
                <Typography>{getPrTitle(pr)}</Typography>
              </Tooltip>
            }
            secondary={
              <>
                {pr.branch !== 'master'
                  ? React.createElement(
                      app.getSystemIcon(Icons.HOT) as IconComponent,
                    )
                  : null}
              </>
            }
          />
          <ListItemSecondaryAction
            sx={{
              background: 'rgba(88, 88, 88, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '16px',
              columnGap: 1,
            }}
          >
            {pr.reviews.normal.length
              ? sortReviews(pr.reviews.normal).map(
                  ([status, reviewers], index) => (
                    <Chip
                      key={index}
                      size="medium"
                      sx={{ marginBottom: 0, marginRight: 0 }}
                      avatar={
                        <>
                          {reviewers.map((reviewer, reviewerIndex) => (
                            <AvatarContact
                              key={reviewerIndex}
                              avatarIconSize={28}
                              employee={reviewer}
                            />
                          ))}
                        </>
                      }
                      label={
                        <IconButton size="small" disabled>
                          {React.createElement(
                            app.getSystemIcon(
                              StatusIcon[status as ReviewStatusValue],
                            ) as IconComponent,
                          )}
                        </IconButton>
                      }
                    />
                  ),
                )
              : null}
            {pr.reviews.codeowners.length
              ? pr.reviews.codeowners
                  .sort((r1, r2) => {
                    if (r1.reviews.length > r2.reviews.length) {
                      return -1;
                    } else if (r1.reviews.length < r2.reviews.length) {
                      return 1;
                    }
                    return r1.slug < r2.slug ? 1 : -1;
                  })
                  .filter(
                    codeownerReview =>
                      showAllCodeowners || codeownerReview.isMember,
                  )
                  .map(codeownerReview => (
                    <CodeownerReviewChip
                      codeownerReview={codeownerReview}
                      showIndividualCodeownerReviews={individualCodeowners}
                    />
                  ))
              : null}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};
