import { useApi } from '@backstage/core-plugin-api';
import { SLToolsApi, SLToolsApiRef } from '../api';
import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';
import { handleResponse } from '@internal/plugin-sl-assets';

type Props = ShortcutComponentProps;

export const CommitTracker = ({ ...props }: Props) => {
  const api = useApi<SLToolsApi>(SLToolsApiRef);

  return (
    <BaseCommandShortcut
      {...props}
      request={input => {
        const type = input.identifier.match(/^[0-9]+$/) ? 'pr' : 'commit';
        return api
          .commitTracker(input.identifier, type, input.searchHfBranches)
          .then(handleResponse);
      }}
    />
  );
};
