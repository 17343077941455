import {
  BasicPermission,
  createPermission,
} from '@backstage/plugin-permission-common';
import { sanitizeUrlDefinition } from '../sanitization';
import { PermissionByUrl, PermissionMapping } from './types';
import { PermissionAction } from '../defaults/types';
import { UrlDefinition } from '../definitions/types';
import { SanitizedDefinition } from '../sanitization/types';

/**
 * Sanitizes a list of URL definitions and creates the Backstage Basic permissions
 *
 * @param urlDefinitions
 */
export const buildUrlPermissions = (urlDefinitions: UrlDefinition[]) => {
  const sanitizedUrlDefinitions: SanitizedDefinition[] = urlDefinitions.map(
    sanitizeUrlDefinition,
  );

  const permissionMapping: PermissionMapping =
    sanitizedUrlDefinitions.reduce<PermissionMapping>(
      (
        currentMapping: PermissionMapping,
        sanitizedDefinition: SanitizedDefinition,
      ) => ({
        ...currentMapping,
        ...Object.fromEntries<BasicPermission>(
          sanitizedDefinition.actions.map(
            (permissionAction: PermissionAction) => [
              `${sanitizedDefinition.baseName}.${permissionAction}`,
              createPermission({
                name: `${sanitizedDefinition.baseName}.${permissionAction}`,
                attributes: { action: permissionAction },
              }),
            ],
          ),
        ),
      }),
      {},
    );

  const permissionByUrl: PermissionByUrl =
    sanitizedUrlDefinitions.reduce<PermissionByUrl>(
      (
        currentUrlMapping: PermissionByUrl,
        sanitizedDefintion: SanitizedDefinition,
      ) => ({
        ...currentUrlMapping,
        [sanitizedDefintion.plugin]: {
          ...(currentUrlMapping[sanitizedDefintion.plugin] || {}),
          [sanitizedDefintion.urlMatch]: Object.fromEntries<BasicPermission>(
            sanitizedDefintion.actions.map(
              (permissionAction: PermissionAction) => [
                permissionAction,
                permissionMapping[
                  `${sanitizedDefintion.baseName}.${permissionAction}`
                ],
              ],
            ),
          ),
        },
      }),
      {},
    );

  return { mapping: permissionMapping, url: permissionByUrl };
};
