import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Header, Link, Progress } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';

import {
  Keys,
  handleResponse,
  useUrlUpdater,
  Employee,
  AvatarContact,
  Icons,
} from '@internal/plugin-sl-assets';
import { SLPokedexApi, SLPokedexApiRef } from '@internal/plugin-sl-bots';
import {
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import { SLCiApi, SLCiApiRef } from '../../api';
import { TreeItem, TreeView } from '@material-ui/lab';
import { BUCKET_BASE_URL } from './constants';
import { ResourceViewDialog } from './resourceViewDialog';
import { DiffViewDialog } from './diffViewDialog';

const CIRCLE_CI_URL_STATIC_PART = 'https://circleci.com/gh/SectorLabs';
const GITHUB_ACTIONS_URL_STATIC_PART = 'https://github.com/SectorLabs/';

type BucketContent = { [key: string]: BucketContent | boolean };

type JobContent = {
  errorShots: BucketContent;
  screenshots: BucketContent;
  'test-results': BucketContent;
};

type JobMetadata = {
  repoName: string;
  commitHash: string;
  jobName: string;
  branchName: string;
  authorName: string;
  date: string;
  // GA specific fields
  runId: string;
};

const ContentTypes: Record<string, string> = Object.freeze({
  UNKNOWN: 'unknown',
  PNG: 'png',
});

export const JobPage = () => {
  const app = useApp();
  const api = useApi<SLCiApi>(SLCiApiRef);
  const pokedexApi = useApi<SLPokedexApi>(SLPokedexApiRef);
  const queryParams = new URLSearchParams(window.location.search);
  const jobIdValue = queryParams.get('id');

  const [jobId, setJobId] = React.useState<string | null>(jobIdValue);
  const jobInputRef: React.Ref<HTMLInputElement> = React.createRef();
  const [currentJobIdInput, setCurrentJobIdInput] = React.useState<string>(
    jobId || '',
  );
  const [jobMetadata, setJobMetadata] = React.useState<JobMetadata | null>(
    null,
  );
  const [author, setAuthor] = React.useState<Employee | null>(null);
  const [highlightedResource, setHighlightedResource] = React.useState<
    string | null
  >(queryParams.get('resource') || null);
  const [diffPaths, setDiffPaths] = React.useState<string[]>([]);
  const [resourcePaths, setResourcePaths] = React.useState<string[]>([]);
  const [diffViewEnabled, setDiffViewEnabled] = React.useState<boolean>(
    jobIdValue !== null &&
      (queryParams.get('diffView') === '1' || queryParams.get('diff') !== null),
  );
  const [currentDiffIndexFocus, setCurrentDiffIndexFocus] =
    React.useState<number>(parseInt(queryParams.get('diff') || '0', 10) || 0);
  const [currentDiffStateIndex, setCurrentDiffStateIndex] =
    React.useState<number>(
      parseInt(
        queryParams.get('diffStateIndex') ||
          queryParams.get('diffState') ||
          '0',
        10,
      ) || 0,
    );

  useUrlUpdater({
    id: jobId ? jobId : null,
    diff: diffViewEnabled ? currentDiffIndexFocus : null,
    diffState: diffViewEnabled ? currentDiffStateIndex : null,
    resource: highlightedResource,
    // Legacy parameters - these values are cleared
    jobId: null,
    diffView: null,
    diffIndex: null,
    diffStateIndex: null,
  });

  const { value, loading, error } = useAsync(
    () =>
      jobId
        ? api
            .getJobContentPaths(jobId)
            .then(handleResponse)
            .then(
              ({
                data,
                metadata,
              }: {
                data: JobContent;
                metadata: JobMetadata;
              }) => {
                setJobMetadata(metadata);

                setDiffPaths(Object.keys(data?.screenshots?.diff || []));
                if (!data) {
                  setResourcePaths([]);
                  throw Error('Job data not found');
                }
                const getResourcesFromBucketContent = (
                  directory: BucketContent,
                  prefix: string = '',
                ): string[] =>
                  Object.entries(directory).reduce<string[]>(
                    (resources, [bucketItemKey, bucketItemValue]) => {
                      if (typeof bucketItemValue === 'boolean') {
                        return [
                          ...resources,
                          prefix.length
                            ? `${prefix}/${bucketItemKey}`
                            : bucketItemKey,
                        ];
                      }
                      return [
                        ...resources,
                        ...getResourcesFromBucketContent(
                          bucketItemValue,
                          prefix.length
                            ? `${prefix}/${bucketItemKey}`
                            : bucketItemKey,
                        ),
                      ];
                    },
                    [],
                  );

                setResourcePaths(getResourcesFromBucketContent(data));

                return data;
              },
            )
        : Promise.resolve({}),
    [jobId],
  );

  React.useEffect(() => {
    if (jobMetadata?.authorName) {
      pokedexApi
        .users({ githubUsername: jobMetadata.authorName })
        .then(handleResponse)
        .then(employeeData => {
          if (employeeData.length && employeeData[0].type === 'employee') {
            setAuthor(employeeData[0]);
          }
        })
        .catch(() => {});
    } else {
      setAuthor(null);
    }
  }, [pokedexApi, setAuthor, jobMetadata?.authorName]);

  const pageKeyPressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    switch (event.key) {
      case Keys.ESCAPE:
        setHighlightedResource(null);
        setCurrentDiffStateIndex(0);
        setCurrentDiffIndexFocus(0);
        setDiffViewEnabled(false);
        event.preventDefault();
        event.stopPropagation();
        break;
      case Keys.D:
      case Keys.d:
        if (!diffPaths.length) break;

        setDiffViewEnabled(prevState => !prevState);
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
        break;
    }
  };

  const searchKeyHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case Keys.ENTER:
          setJobId(currentJobIdInput);
          setCurrentDiffIndexFocus(0);
          setCurrentDiffStateIndex(0);
          setHighlightedResource(null);
          break;
        default:
          break;
      }
    },
    [currentJobIdInput, setJobId],
  );

  const renderWorkflowSwitcher = () => (
    <Container>
      <Grid container>
        <Grid item xs={11}>
          <input
            style={{
              padding: '3px',
              borderRadius: '10px',
              width: '100%',
              height: '40px',
              fontSize: '20px',
              marginBottom: '10px',
            }}
            id="currentJob"
            ref={jobInputRef}
            name="currentJob"
            type="text"
            onChange={e => setCurrentJobIdInput(e.target.value)}
            value={currentJobIdInput}
            placeholder="Job ID"
            onKeyDown={searchKeyHandler}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            style={{ margin: '5px' }}
            title={
              <Typography variant="body1">
                View Diffs and artifacts. To navigate only using the keyboard:{' '}
                <br />- <i>Press</i> D to open diff view
                <br />- Once the diff view is open, use the <i>LEFT</i> and{' '}
                <i>RIGHT</i> arrows to <b>navigate</b> through the diffs and the{' '}
                <i>UP</i> and <i>DOWN</i> arrows to switch between <b>diff</b>,{' '}
                <b>actual</b> and <b>expected</b> views.
                <br />
              </Typography>
            }
          >
            <IconButton size="small">
              {React.createElement(
                app.getSystemIcon(Icons.QUESTION) as IconComponent,
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );

  const renderDirectoryAsTreeView = (
    directory: BucketContent,
    currentPath: string = '',
  ) => {
    const subDirectories = Object.entries(directory).filter(
      ([_, item]) => typeof item !== 'boolean',
    ) as [string, BucketContent][];
    const files = Object.entries(directory).filter(
      ([_, item]) => typeof item === 'boolean',
    ) as [string, boolean][];
    return (
      <>
        {subDirectories.map(([label, item]) => (
          <TreeItem
            nodeId={currentPath ? `${currentPath}/${label}` : label}
            key={label}
            label={label}
          >
            {renderDirectoryAsTreeView(
              item,
              currentPath ? `${currentPath}/${label}` : label,
            )}
          </TreeItem>
        ))}
        {files.map(([label, _]) => (
          <TreeItem
            nodeId={currentPath ? `${currentPath}/${label}` : label}
            key={label}
            label={label}
            onClick={() => {
              const labelSplit = label.split('.');
              if (
                label.includes('.') &&
                labelSplit &&
                Object.values(ContentTypes).includes(
                  labelSplit[labelSplit.length - 1],
                )
              ) {
                setHighlightedResource(
                  currentPath ? `${currentPath}/${label}` : label,
                );
                return;
              }
              window.open(
                `${BUCKET_BASE_URL}/${jobId}/${encodeURIComponent(
                  currentPath ? `${currentPath}/${label}` : label,
                )}`,
              );
            }}
          />
        ))}
      </>
    );
  };

  const renderHeaderTitle = React.useCallback(() => {
    if (!jobMetadata) {
      return `Job ${jobId ? `#${jobId} ` : ''}artifacts`;
    }
    return jobMetadata.jobName;
  }, [jobId, jobMetadata]);

  const renderJobLink = React.useCallback(() => {
    if (!jobId || !jobMetadata) {
      return '';
    }

    const url =
      (jobId as string).length < 10
        ? `${CIRCLE_CI_URL_STATIC_PART}/${jobMetadata.repoName}/${jobId}`
        : `${GITHUB_ACTIONS_URL_STATIC_PART}/${jobMetadata.repoName}/actions/runs/${jobMetadata.runId}/job/${jobId}`;

    if (!url) {
      return '';
    }

    return (
      <u>
        <Link to={url} style={{ color: 'white' }}>
          Link to job
        </Link>
      </u>
    );
  }, [jobId, jobMetadata]);

  const renderMetadata = React.useCallback(() => {
    if (!jobMetadata) return null;

    return (
      <Container>
        <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          {author ? (
            <AvatarContact avatarIconSize={30} employee={author} />
          ) : (
            <Avatar>{jobMetadata.authorName}</Avatar>
          )}
          <IconButton size="small">
            {React.createElement(
              app.getSystemIcon(Icons.GITHUB) as IconComponent,
            )}
          </IconButton>
          <div>
            <Link to={`https://github.com/SectorLabs/${jobMetadata.repoName}`}>
              {jobMetadata.repoName}
            </Link>
          </div>{' '}
          <div>/</div>
          <div>
            <Link
              to={`https://github.com/SectorLabs/${jobMetadata.repoName}/tree/${jobMetadata.branchName}`}
            >
              {jobMetadata.branchName}
            </Link>
          </div>
          {jobMetadata.commitHash ? (
            <div>
              {' '}
              <Link
                to={`https://github.com/SectorLabs/${jobMetadata.repoName}/commit/${jobMetadata.commitHash}`}
              >
                (see commit)
              </Link>
            </div>
          ) : null}
          <div>&nbsp;at {jobMetadata.date}</div>
        </div>
      </Container>
    );
  }, [app, jobMetadata, author]);

  return (
    <div onKeyDown={pageKeyPressHandler} role="presentation">
      <Header title={renderHeaderTitle()} subtitle={renderJobLink()} />
      <Container style={{ marginTop: '20px' }}>
        <Paper>
          {error && <Alert severity="error">{error?.message}</Alert>}
          {renderWorkflowSwitcher()}
          {loading && <Progress />}
          {renderMetadata()}
          {!loading && jobId && value && (
            <Container>
              <Typography variant="h6">
                Found {diffPaths.length} diffs
              </Typography>
              {jobId && diffPaths.length ? (
                <Button
                  style={{ margin: '5px' }}
                  variant="contained"
                  startIcon={React.createElement(
                    app.getSystemIcon(Icons.IMAGE) as IconComponent,
                  )}
                  color="primary"
                  onClick={() => setDiffViewEnabled(true)}
                >
                  Open Diff view
                </Button>
              ) : null}
              <TreeView
                defaultCollapseIcon={React.createElement(
                  app.getSystemIcon(Icons.EXPAND) as IconComponent,
                )}
                defaultExpandIcon={React.createElement(
                  app.getSystemIcon(Icons.CHEVRON_RIGHT) as IconComponent,
                )}
              >
                {renderDirectoryAsTreeView(value)}
              </TreeView>
            </Container>
          )}
        </Paper>
      </Container>
      <ResourceViewDialog
        jobId={jobId as string}
        resourcePaths={resourcePaths}
        highlightedResource={highlightedResource}
        setHighlightedResource={setHighlightedResource}
      />
      <DiffViewDialog
        jobId={jobId as string}
        diffViewEnabled={diffViewEnabled}
        setDiffViewEnabled={setDiffViewEnabled}
        currentDiffIndexFocus={currentDiffIndexFocus}
        currentDiffStateIndex={currentDiffStateIndex}
        setCurrentDiffIndexFocus={setCurrentDiffIndexFocus}
        setCurrentDiffStateIndex={setCurrentDiffStateIndex}
        diffPaths={diffPaths}
      />
    </div>
  );
};
