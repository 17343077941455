import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

type BooleanFilter = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export type Filters = {
  individualCodeownersFilter: BooleanFilter;
  showAllCodeownersFilter: BooleanFilter;
};

export const useFilters = (): Filters => {
  const individualCodeownersFilter = React.useState<boolean>(false);
  const showAllCodeownersFilter = React.useState<boolean>(false);

  return { individualCodeownersFilter, showAllCodeownersFilter };
};

export const ReviewFilters = ({
  individualCodeownersFilter,
  showAllCodeownersFilter,
}: Filters) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={individualCodeownersFilter[0]}
            onChange={() => {
              individualCodeownersFilter[1](prevValue => !prevValue);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Show individual codeowner reviews"
      />
      <FormControlLabel
        control={
          <Switch
            checked={showAllCodeownersFilter[0]}
            onChange={() => {
              showAllCodeownersFilter[1](prevValue => !prevValue);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Show all codeowners"
      />
    </FormGroup>
  );
};
