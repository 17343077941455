import React from 'react';
import {
  SlCliAppleShortcutsLoginPage,
  SlCliLoginPage,
} from '@internal/plugin-sl-cli';
import {
  SLOnCallAvailabilityPage,
  SLOnCallSchedulePage,
  SLOnCallSummaryPage,
} from '@internal/plugin-sl-oncall';
import {
  QaAssignmentsPage,
  ReleaseNotesPage,
  VersionPage,
  ReleaseControlPanelPage,
} from '@internal/plugin-sl-release';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { DefaultTechDocsHome } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { CatalogIndexPage } from '@backstage/plugin-catalog';
import { PageNames } from '@internal/plugin-sl-assets';
import { JobPage } from '@internal/plugin-sl-qa';
import { PageValue } from '@internal/backstage-plugin-sl-common';
import {
  MyReviewsPage,
  MyPRReviewsPage,
  RepositoryStateMachine,
  CodeownersReviewsPage,
} from '@internal/plugin-sl-reviews';
import { ComponentPermissionName } from '@internal/plugin-sl-permissions';
import { ComponentPermissionsExplanationPage } from '@internal/plugin-sl-permissions-frontend';
import { CheatsheetPage } from './components/cheatsheet/cheatsheetPage';

export type Page = {
  url: string;
  permissionName?: ComponentPermissionName;
  renderPage: React.FunctionComponent;
};

export const Pages: Record<PageValue, Page> = Object.freeze({
  [PageNames.REVIEWS_MY_PR]: {
    url: '/reviews/pr',
    permissionName: 'sl.reviews.pr',
    renderPage: () => <MyPRReviewsPage />,
  },
  [PageNames.REVIEWS_MY]: {
    url: '/reviews/reviews',
    permissionName: 'sl.reviews.pr',
    renderPage: () => <MyReviewsPage />,
  },
  [PageNames.REVIEWS_REPOSITORY_STATE_MACHINE]: {
    url: '/reviews/state-machines',
    permissionName: 'sl.reviews.state-machines',
    renderPage: () => <RepositoryStateMachine />,
  },
  [PageNames.REVIEWS_CODEOWNERS]: {
    url: '/reviews/codeowners',
    permissionName: 'sl.reviews.pr',
    renderPage: () => <CodeownersReviewsPage />,
  },
  [PageNames.RELEASE_CONTROL_PANEL]: {
    url: '/release/control-panel',
    permissionName: 'sl.release.deploy',
    pageName: PageNames.RELEASE_CONTROL_PANEL,
    renderPage: () => <ReleaseControlPanelPage />,
  },
  [PageNames.RELEASE_VERSION]: {
    url: '/release/version',
    pageName: PageNames.RELEASE_VERSION,
    renderPage: () => <VersionPage />,
  },
  [PageNames.RELEASE_NOTES]: {
    url: '/release/notes',
    pageName: PageNames.RELEASE_NOTES,
    renderPage: () => <ReleaseNotesPage />,
  },
  [PageNames.QA_ASSIGNMENTS]: {
    url: '/qa/assignments',
    permissionName: 'sl.release.qa.assignments.view',
    renderPage: () => <QaAssignmentsPage />,
  },
  [PageNames.QA_JOB_PAGE]: {
    url: '/qa/ci-jobs',
    permissionName: 'sl.qa.workflows.view',
    renderPage: () => <JobPage />,
  },
  [PageNames.SL_CLI_LOGIN]: {
    url: '/sl-cli/login',
    permissionName: 'sl.cli.login',
    renderPage: () => <SlCliLoginPage />,
  },
  [PageNames.SL_CLI_SHORTCUTS_LOGIN]: {
    url: '/sl-cli/shortcuts',
    permissionName: 'sl.cli.shortcuts.login',
    renderPage: () => <SlCliAppleShortcutsLoginPage />,
  },
  [PageNames.ON_CALL_SCHEDULE]: {
    url: '/on-call/schedule',
    permissionName: 'sl.oncall.schedule',
    renderPage: () => <SLOnCallSchedulePage />,
  },
  [PageNames.ON_CALL_AVAILABILITY]: {
    url: '/on-call/availability',
    permissionName: 'sl.oncall.availability',
    renderPage: () => <SLOnCallAvailabilityPage />,
  },
  [PageNames.ON_CALL_SUMMARY]: {
    url: '/on-call/summary',
    permissionName: 'sl.oncall.summary',
    renderPage: () => <SLOnCallSummaryPage />,
  },
  [PageNames.ANNOUNCEMENTS]: {
    url: '/announcements',
    renderPage: () => <AnnouncementsPage />,
  },
  [PageNames.QETA]: {
    url: '/qeta',
    renderPage: () => <QetaPage title='"Stack Overflow"' />,
  },
  [PageNames.TECH_DOCS]: {
    url: '/docs',
    renderPage: () => <DefaultTechDocsHome />,
  },
  [PageNames.CATALOG_INDEX]: {
    url: '/catalog',
    renderPage: () => <CatalogIndexPage initialKind="domain" />,
  },
  [PageNames.CHEATSHEET]: {
    url: '/cheatsheet',
    permissionName: 'sl.cheatsheet.view',
    renderPage: () => <CheatsheetPage />,
  },
  [PageNames.USER_SETTINGS]: {
    url: '/settings',
    renderPage: () => <UserSettingsPage />,
  },
  [PageNames.PERMISSIONS_EXPLAIN]: {
    url: '/permissions',
    renderPage: () => <ComponentPermissionsExplanationPage />,
    permissionName: 'sl.meta.permissions',
  },
});
