import { useApi } from '@backstage/core-plugin-api';
import { SLOnCallApi, SLOnCallApiRef } from '../api';
import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';
import { handleResponse } from '@internal/plugin-sl-assets';

type Props = ShortcutComponentProps;

export const ShiftCompensation = ({ ...props }: Props) => {
  const api = useApi<SLOnCallApi>(SLOnCallApiRef);

  return (
    <BaseCommandShortcut
      {...props}
      request={() => api.shiftCompensation().then(handleResponse)}
    />
  );
};
