import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';

type Props = ShortcutComponentProps & {
  url: string;
};

export const TubescreamerDevAdSearch = ({ url, ...props }: Props) => (
  <BaseCommandShortcut
    {...props}
    request={input =>
      Promise.resolve({
        type: 'redirect',
        url: `${url}/admin/ts/object/1/${input.id}`,
      })
    }
  />
);

export const tubescreamerDevAdSearchFactory =
  (url: string) => (props: ShortcutComponentProps) =>
    <TubescreamerDevAdSearch {...props} url={url} />;
