import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useScreenSize } from '@internal/plugin-sl-assets';

export const ResourceView = ({ resourceUrl }: { resourceUrl: string }) => {
  const { width, height } = useScreenSize();

  return (
    <TransformWrapper pinch={{ step: 1 }} centerOnInit>
      <TransformComponent>
        <img
          src={resourceUrl}
          alt={resourceUrl}
          style={{
            width: `${width - 150}px`,
            height: `${height - 150}px`,
            objectFit: 'contain',
          }}
        />
      </TransformComponent>
    </TransformWrapper>
  );
};
