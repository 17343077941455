import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
  },
  st18: {
    display: 'inline',
    fill: '#0ff',
    'fill-opacity': 0.2,
  },
  enableBackground: {
    'enable-background': 'new',
  },
});

export const GoogleFirebaseIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      viewBox="0 0 48 48"
    >
      <g transform="translate(-11.264 -26.728) scale(.27273)">
        <defs>
          <path
            id="SVGID_1_"
            d="M176.8 137.4c-.4-2.2-2.4-3.6-4.6-3.3-.8.1-1.6.5-2.2 1.1L149.2 156l-16.3-31.1c-1-2-3.4-2.7-5.4-1.7-.7.4-1.3 1-1.7 1.7l-8.9 17-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6-1.1.6-1.9 1.7-2.1 2.9L65.3 240l57.9 32.5c3.6 2 8.1 2 11.7 0l58.4-32.5z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" />
        </clipPath>
        <g clipPath="url(#SVGID_2_)" style={{ clipPath: 'url(#SVGID_2_)' }}>
          <path
            d="M65.3 240 87 101.4c.3-2.2 2.4-3.7 4.6-3.3 1.2.2 2.3 1 2.9 2.1l22.4 41.8 8.9-17c1-2 3.4-2.7 5.4-1.7.7.4 1.3 1 1.7 1.7l60.4 115z"
            style={{ fill: '#ffa000' }}
          />
          <linearGradient
            id="SVGID_3_"
            x1="90.242"
            x2="82.242"
            y1="137.44"
            y2="141.62"
            gradientTransform="matrix(1 0 0 -1 0 329.99)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#A52714" />
            <stop offset=".4" stopColor="#A52714" stopOpacity=".5" />
            <stop offset=".8" stopColor="#A52714" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_3_)"
            d="m139.3 99-106-9v192l32-42z"
            style={{ opacity: 0.12, fill: 'url(#SVGID_3_)' }}
            className={classes.enableBackground}
          />
          <path
            d="m140.2 186-23.3-44.1L65.3 240z"
            style={{ fill: '#f57c00' }}
          />
          <linearGradient
            id="SVGID_4_"
            x1="124.24"
            x2="120.66"
            y1="149.08"
            y2="152.66"
            gradientTransform="matrix(1 0 0 -1 0 329.99)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#A52714" stopOpacity=".8" />
            <stop offset=".5" stopColor="#A52714" stopOpacity=".21" />
            <stop offset="1" stopColor="#A52714" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_4_)"
            d="M33.3 90h192v192h-192z"
            style={{ opacity: 0.2, fill: 'url(#SVGID_4_)' }}
            className={classes.enableBackground}
          />
          <path
            d="m193.3 240-16.6-102.7c-.4-2.2-2.4-3.6-4.6-3.3-.8.1-1.6.5-2.2 1.1L65.3 240l57.9 32.5c3.6 2 8.1 2 11.7 0z"
            style={{ fill: '#ffca28' }}
          />
          <path
            d="M176.8 137.4c-.4-2.2-2.4-3.6-4.6-3.3-.8.1-1.6.5-2.2 1.1L149.2 156l-16.3-31.1c-1-2-3.4-2.7-5.4-1.7-.7.4-1.3 1-1.7 1.7l-8.9 17-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6-1.1.6-1.9 1.7-2.1 2.9L65.3 240h-.1l.1.1.6.3 83.3-83.4 20.8-20.8c1.6-1.6 4.1-1.6 5.7 0 .6.6 1 1.3 1.1 2.2l16.4 101.7.2-.1zM65.5 239.8 87 102.4c.3-2.2 2.4-3.7 4.6-3.3 1.2.2 2.3 1 2.9 2.1l22.4 41.8 8.9-17c1-2 3.4-2.7 5.4-1.7.7.4 1.3 1 1.7 1.7l16 30.4z"
            style={{ fill: '#fff', fillOpacity: 0.2 }}
          />
          <path
            d="M134.9 271.5c-3.6 2-8.1 2-11.7 0l-57.8-32.4-.1.9 57.9 32.5c3.6 2 8.1 2 11.7 0l58.4-32.5-.1-.9z"
            style={{ opacity: 0.2, fill: '#a52714' }}
            className={classes.enableBackground}
          />
          <linearGradient
            id="SVGID_5_"
            x1="-597.05"
            x2="-546.98"
            y1="1378.5"
            y2="1428.5"
            gradientTransform="matrix(2.67 0 0 2.67 1656 -3551.5)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" stopOpacity=".1" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_5_)"
            d="M176.8 137.4c-.4-2.2-2.4-3.6-4.6-3.3-.8.1-1.6.5-2.2 1.1L149.2 156l-16.3-31.1c-1-2-3.4-2.7-5.4-1.7-.7.4-1.3 1-1.7 1.7l-8.9 17-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6-1.1.6-1.9 1.7-2.1 2.9L65.3 240l57.9 32.5c3.6 2 8.1 2 11.7 0l58.4-32.5z"
            style={{ fill: 'url(#SVGID_5_)' }}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
