import React from 'react';
import { SLReleaseApi, SLReleaseApiRef } from '../api';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';
import { useApi } from '@backstage/core-plugin-api';
import { handleResponse } from '@internal/plugin-sl-assets';

type Props = ShortcutComponentProps & {
  branch: 'staging' | 'production';
};

export const DeployTo = ({ branch, ...props }: Props) => {
  const api = useApi<SLReleaseApi>(SLReleaseApiRef);

  return (
    <BaseCommandShortcut
      {...props}
      request={_ => api.getDeployPR('maple', branch).then(handleResponse)}
    />
  );
};

export const deployToFactory =
  (branch: 'staging' | 'production') => (props: ShortcutComponentProps) =>
    <DeployTo {...props} branch={branch} />;
