import { useApi } from '@backstage/core-plugin-api';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { isMobile } from 'react-device-detect';
import { Header } from '@backstage/core-components';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  handleResponse,
  Page,
  PageData,
  useUrlUpdater,
} from '@internal/plugin-sl-assets';
import { VersionListDesktop } from './desktop';
import { VersionListMobile } from './mobile';
import { Env, VersionAvailableForServices, Service } from './constants';
import { SLReleaseApiRef } from '../../api';

export const VersionPage = () => {
  const releaseApi = useApi(SLReleaseApiRef);
  const queryParams = new URLSearchParams(window.location.search);
  const defaultService = queryParams.get('service') || 'strat';
  const defaultEnv = queryParams.get('env') || 'production';
  const [serviceEnv, setServiceEnv] = React.useState<{
    service: Service;
    env: Env;
  }>({
    service: defaultService as Service,
    env: defaultEnv as Env,
  });

  useUrlUpdater({
    service: serviceEnv.service,
    env: serviceEnv.env,
  });

  const portalVersions = useAsync(
    async () =>
      releaseApi
        .getReleaseVersion(serviceEnv.service, serviceEnv.env, [
          'metadata.title',
          'metadata.annotations.sectorlabs/icon',
          'metadata.annotations.backstge.io/view-url',
        ])
        .then(handleResponse),
    [serviceEnv.service, serviceEnv.env],
  ) as PageData;

  return (
    <Page
      header={() => (
        <>
          <Header
            title="Web Release version"
            subtitle={`${
              serviceEnv.service.charAt(0).toUpperCase() +
              serviceEnv.service.slice(1)
            } ${serviceEnv.env}`}
          />
          <Container>
            <Tabs
              value={`${serviceEnv.service}-${serviceEnv.env}`}
              variant="scrollable"
              scrollButtons="auto"
            >
              {(
                Object.entries(VersionAvailableForServices) as [
                  Service,
                  Env[],
                ][]
              ).reduce<React.ReactNode[]>(
                (components: React.ReactNode[], mapping: [Service, Env[]]) => {
                  const [serviceName, envNames] = mapping;
                  return [
                    ...components,
                    envNames.map((env: Env) => (
                      <Tab
                        key={`${serviceName}-${env}`}
                        value={`${serviceName}-${env}`}
                        label={`${serviceName} ${env}`}
                        onClick={() =>
                          setServiceEnv({ service: serviceName, env })
                        }
                      />
                    )),
                  ];
                },
                [],
              )}
            </Tabs>
          </Container>
        </>
      )}
      content={pageData =>
        !isMobile ? (
          <VersionListDesktop pageData={pageData} />
        ) : (
          <VersionListMobile pageData={pageData} />
        )
      }
      pageData={portalVersions}
    />
  );
};
