import { Employee } from '@internal/plugin-sl-assets';

import { ReviewStatusValue, StatusesSortedByPriority } from '../status';
import { NormalReview } from '../types';

export const sortReviews = (reviews: NormalReview[]) =>
  Object.entries(
    reviews.reduce(
      (
        reviewsByStatus: Record<string, Employee[]>,
        normalReview,
      ): Record<string, Employee[]> => ({
        ...reviewsByStatus,
        [normalReview.status]: [
          ...(reviewsByStatus[normalReview.status] || []),
          normalReview.reviewer,
        ],
      }),
      {},
    ),
  ).sort(([s1, _1], [s2, _2]) => {
    if (s1 === s2) return 0;
    return StatusesSortedByPriority.indexOf(s1 as ReviewStatusValue) <
      StatusesSortedByPriority.indexOf(s2 as ReviewStatusValue)
      ? -1
      : 1;
  });
