import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';

type Props = {
  repositoryName: string;
};

export const GithubPR = (props: ShortcutComponentProps & Props) => (
  <BaseCommandShortcut
    {...props}
    request={input =>
      Promise.resolve({
        type: 'redirect',
        url: `https://www.github.com/SectorLabs/${
          props.repositoryName
        }/pull/${input.pr.replace('#', '')}`,
      })
    }
  />
);

export const githubPrFactory =
  (repositoryName: string) => (props: ShortcutComponentProps) =>
    <GithubPR {...props} repositoryName={repositoryName} />;
