import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  DIFF_NEIGHBOURS_LAZY_LOAD_THRESHOLD,
  BUCKET_BASE_URL,
  DiffStatesOrder,
} from './constants';
import { useScreenSize } from '@internal/plugin-sl-assets';

type DiffViewProps = {
  jobId: string;
  diffPaths: string[];
  currentDiffIndexFocus: number;
  currentDiffStateIndex: number;
};

export const DiffView = ({
  jobId,
  diffPaths,
  currentDiffIndexFocus,
  currentDiffStateIndex,
}: DiffViewProps) => {
  const { height, width } = useScreenSize();

  return (
    <TransformWrapper pinch={{ step: 1 }} centerOnInit>
      <TransformComponent>
        {diffPaths.map((diffPath, diffIndex) =>
          DiffStatesOrder.map((diffState, diffStateIndex) =>
            Math.abs(diffIndex - currentDiffIndexFocus) <
              DIFF_NEIGHBOURS_LAZY_LOAD_THRESHOLD ||
            Math.abs(diffIndex - currentDiffIndexFocus) >
              diffPaths.length - DIFF_NEIGHBOURS_LAZY_LOAD_THRESHOLD ? (
              <img
                src={`${BUCKET_BASE_URL}/${jobId}/${encodeURIComponent(
                  `screenshots/${diffState}/${diffPath}`,
                )}`}
                style={{
                  width: `${width - 50}px`,
                  height: `${height - 100}px`,
                  objectFit: 'contain',
                  ...(diffIndex !== currentDiffIndexFocus ||
                  diffStateIndex !== currentDiffStateIndex
                    ? { display: 'none' }
                    : {}),
                }}
                key={`${BUCKET_BASE_URL}/screenshots/${diffState}/${diffPath}`}
                alt={`${diffState}/${diffPath}`}
              />
            ) : null,
          ),
        )}
      </TransformComponent>
    </TransformWrapper>
  );
};
