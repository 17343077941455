import React from 'react';
import { isMobile } from 'react-device-detect';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  keyPressHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const SearchField = ({
  inputRef,
  value,
  placeholder,
  onChange,
  onFocus,
  keyPressHandler,
}: Props) => {
  return (
    <input
      style={{
        padding: '3px 15px',
        borderRadius: '20px',
        width: isMobile ? 'calc(100% - 10px)' : '100%',
        height: isMobile ? '60px' : '40px',
        fontSize: isMobile ? '30px' : '20px',
        margin: isMobile ? '10px 5px' : 0,
        backgroundColor: 'transparent',
        color: '#fff',
        border: 'solid 1px #aaa',
      }}
      ref={inputRef}
      name="currentTerm"
      type="text"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      onFocus={onFocus}
      onKeyDown={keyPressHandler || (_ => {})}
    />
  );
};
