import { BasicPermission } from '@backstage/plugin-permission-common';
import {
  buildComponentPermissions,
  buildGrantedPermissions,
  buildUrlPermissions,
} from './build';
import { UrlPermissionDefinitions } from './definitions';
import { RequestMethod } from './defaults/types';
import { RequestMethodMapping } from './defaults';

export {
  isComponentPermissionName,
  AllComponentPermissionNames,
  AllPermissionGroupNames,
} from './definitions/types';
export type {
  ComponentPermissionName,
  GithubGroupGranting,
  PermissionGroupDefinition,
  PermissionGroupName,
  UserGranting,
  ComponentDefinition,
  UrlDefinition,
} from './definitions/types';
export {
  GithubGroupsGrantedPermissions,
  PermissionGroupDefinitions,
  UserEmailGrantedPermissions,
  ComponentDefinitions,
  UrlPermissionDefinitions,
} from './definitions';
export { sanitizeUserDomain } from './sanitization';
export type {
  PermissionGrants,
  ComponentPermissionCollection,
  ComponentPermission,
} from './build/types';
export { RequestMethodMapping };
export type { RequestMethod };

export class SLPermissionsDiscoveryError extends Error {}

export const SLPermissions = (() => {
  const urlPermissions = buildUrlPermissions(UrlPermissionDefinitions);
  const componentPermissions = buildComponentPermissions(
    urlPermissions.mapping,
  );
  const grantedPermissions = buildGrantedPermissions(componentPermissions);

  return Object.freeze({
    discovery: {
      url: (
        plugin: string,
        url: string,
        method: RequestMethod,
      ): BasicPermission[] => {
        if (!(method in RequestMethodMapping)) {
          return [];
        }

        const pluginPermissionsByUrl = urlPermissions.url[plugin];
        const permissionAction = RequestMethodMapping[method];

        if (!pluginPermissionsByUrl) {
          // The developer specified a plugin for the middleware, but there are no permissions built for it
          throw new SLPermissionsDiscoveryError(
            `No permission defined for ${plugin}!`,
          );
        }

        // @ts-ignore typescript complains about possible undefined values, but we filter for them at the end
        const requiredPermissions: Set<BasicPermission> = new Set(
          Object.entries(pluginPermissionsByUrl)
            .filter(([urlRegex, _permissionActionMapping]) =>
              url.match(new RegExp(urlRegex, 'g')),
            )
            .map(
              ([_, permissionActionMapping]) =>
                permissionActionMapping[permissionAction],
            )
            .filter(mappedPermissionAction => !!mappedPermissionAction),
        );

        if (!requiredPermissions.size) {
          // No permissions matched this URL, probably a mistake on the developer side
          throw new SLPermissionsDiscoveryError(
            `No rule matched for ${method} ${url} in plugin ${plugin}`,
          );
        }

        return Array.from(requiredPermissions);
      },
      basic: urlPermissions.mapping,
      component: componentPermissions,
      granted: grantedPermissions,
    },
    component: Object.fromEntries(
      Object.values(componentPermissions).map(permission => [
        permission.name,
        permission.basicPermission,
      ]),
    ),
  });
})();
