export const Icons = Object.freeze({
  ADD: 'add',
  ADMIN_PANEL: 'adminPanel',
  API: 'api',
  ALARM_CLOCK: 'alarmClock',
  ANNOUNCEMENT: 'announcement',
  ARROW_BACK: 'arrowBack',
  ARROW_RIGHT: 'arrowRight',
  ARROW_DROPDOWN: 'arrowDropdown',
  ASSISTANT: 'assistant',
  BEACH_ACCESS: 'beachAccess',
  BOOK: 'book',
  BOT: 'bot',
  BUG_REPORT: 'bugReport',
  BUILD: 'build',
  CALENDAR: 'calendar',
  CALENDAR_TODAY: 'calendarToday',
  CATEGORY: 'category',
  CALL: 'call',
  CHECK_CIRCLE: 'checkCircle',
  CHEVRON_RIGHT: 'chevronRight',
  CODE: 'code',
  CONTACT_EMERGENCY: 'contactEmergency',
  COPY: 'copy',
  CLOUD: 'cloud',
  CLOSE: 'close',
  DELETE: 'delete',
  EDIT: 'edit',
  ELASTIC: 'elastic',
  ERROR: 'error',
  EVENT_AVAILABLE: 'eventAvailable',
  EVENT_BUSY: 'eventBusy',
  EXPAND: 'expand',
  FILTER: 'filter',
  GROUP: 'group',
  HEALING: 'healing',
  HOME: 'home',
  HOT: 'hot',
  HOURGLASS_TOP: 'hourglassTop',
  IMAGE: 'image',
  INVENTORY: 'inventory',
  KEYBOARD: 'keyboard',
  LANGUAGE: 'language',
  LAUNCH: 'launch',
  LIBRARY_BOOKS: 'libraryBooks',
  LIVE_HELP: 'liveHelp',
  LOCK: 'lock',
  MESSAGE: 'message',
  MONETIZATION: 'monetization',
  NOT_INTERESTED: 'notInterested',
  NOTIFICATION_ACTIVE: 'notificationActive',
  NOTIFICATION_IMPORTANT: 'notificationImportant',
  NOTIFICATION_PAUSED: 'notificationPaused',
  NUMBER1: 'number1',
  NUMBER2: 'number2',
  NUMBER3: 'number3',
  NUMBER4: 'number4',
  NUMBER5: 'number5',
  NUMBER6: 'number6',
  NUMBER7: 'number7',
  NUMBER8: 'number8',
  NUMBER9: 'number9',
  QUESTION: 'question',
  PDF: 'pdf',
  PENDING: 'pending',
  PERSON: 'person',
  POWER: 'power',
  RATE_REVIEW: 'rateReview',
  RELEASE: 'release',
  RING_VOLUME: 'ringVolume',
  RUN: 'run',
  SAVE: 'save',
  SEARCH: 'search',
  SETTINGS: 'settings',
  SUMMARY: 'summary',
  SUPERVISOR: 'supervisor',
  SQUARE_FOOT: 'squareFootIcon',
  SYNC: 'sync',
  SYNC_DISABLED: 'syncDisabled',
  SYNC_PROBLEM: 'syncProblem',
  TERMINAL: 'terminal',
  THUMBS_DOWN: 'thumbsDown',
  THUMBS_UP: 'thumbsUp',
  TUNE: 'tune',
  VERIFIED_USER: 'verifiedUser',
  VISIBILITY: 'visibility',
  WARNING: 'warning',

  // Material UI mutated
  NOT_INTERESTED_DISABLED: 'notInterestedDisabled',
  NOT_INTERESTED_ERROR: 'notInterestedError',
  RING_VOLUME_PRIMARY: 'ringVolumePrimary',
  THUMBS_DOWN_ERROR: 'thumbsDownError',
  THUMBS_UP_DISABLED: 'thumbsUpDisabled',
  THUMBS_UP_PRIMARY: 'thumbsUpPrimary',

  // External services icons
  ANDROID: 'android',
  APPLE: 'apple',
  APPLE_SHORTCUTS: 'apple_shortcuts',
  ARGOCD: 'argocd',
  AWS: 'aws',
  BAMBOOHR: 'bamboohr',
  BAYUT: 'bayut',
  BPROPERTY: 'bproperty',
  CIRCLECI: 'circleci',
  CLOUDFLARE: 'cloudflare',
  CORALOGIX: 'coralogix',
  DUBIZZLE: 'dubizzle',
  FIREBASE: 'firebase',
  GITHUB: 'github',
  HEROKU: 'heroku',
  LAMUDI: 'lamudi',
  LOGO: 'logo',
  LOGO_FULL: 'logoFull',
  OLX: 'olx',
  OPSGENIE: 'opsgenie',
  SENTRY: 'sentry',
  SL: 'sectorlabs',
  SLACK: 'slack',
  ZAMEEN: 'zameen',

  // Custom SVGs
  PR_DRAFT: 'pr_draft',
  PR_READY: 'pr_ready',
  PR_CLOSED: 'pr_closed',
  PR_MERGED: 'pr_merged',
});

export type IconValue = (typeof Icons)[keyof typeof Icons];
