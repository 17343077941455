import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { BaseApi } from '@internal/plugin-sl-assets';

export class ArgoApi extends BaseApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, 'proxy', '/argocd/api');
  }

  public async getApplicationTree(applicationName: string) {
    return this.get(`/applications/${applicationName}/resource-tree`);
  }
}

export const ArgoApiRef = createApiRef<ArgoApi>({
  id: 'plugin.sl-release.argo-api',
});
