import React from 'react';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';

type Props = ShortcutComponentProps & {
  url: string;
};

export const OlxAdSearch = ({ url, ...props }: Props) => (
  <BaseCommandShortcut
    {...props}
    request={input =>
      Promise.resolve({
        type: 'redirect',
        url: `${url}/en/ad/-ID${input.id}.html`,
      })
    }
  />
);

export const olxAdSearchFactory =
  (url: string) => (props: ShortcutComponentProps) =>
    <OlxAdSearch {...props} url={url} />;
