import React from 'react';
import {
  AnyNonRootShortcut,
  AnyRootShortcut,
  AnyShortcut,
  isShortcutAnyGroup,
} from '@internal/backstage-plugin-sl-common';
import { useApi } from '@backstage/core-plugin-api';
import { permissionApiRef } from '@backstage/plugin-permission-react';
import { SLPermissions } from '@internal/plugin-sl-permissions';
import { AuthorizeResult } from '@backstage/plugin-permission-common';

const usePermissionedShortcuts = (
  shortcuts: Record<string, AnyRootShortcut>,
  loadAllByDefault: boolean = false,
) => {
  const permissionApi = useApi(permissionApiRef);
  const [permissionStatus, setPermissionStatus] = React.useState<
    Record<string, boolean>
  >({});
  const [permissionsChecked, setPermissionsChecked] = React.useState<
    Record<string, boolean>
  >({});

  const filterShortcuts = React.useCallback(() => {
    const permissionsToAddForChecking = new Set<string>();

    const filterShortcut = (
      results: Record<string, AnyShortcut>,
    ): Record<string, AnyShortcut> =>
      Object.entries(results).reduce<Record<string, AnyShortcut>>(
        (
          accumulator: Record<string, AnyShortcut>,
          [shortcutKey, shortcutValue],
        ): Record<string, AnyShortcut> => {
          if (shortcutValue.permissionName) {
            if (!(shortcutValue.permissionName in permissionStatus)) {
              permissionsToAddForChecking.add(shortcutValue.permissionName);
              if (!loadAllByDefault) {
                return accumulator;
              }
              return {
                ...accumulator,
                [shortcutKey]: shortcutValue,
              };
            }
            if (permissionStatus[shortcutValue.permissionName] === false)
              return accumulator;
          }

          if (!isShortcutAnyGroup(shortcutValue)) {
            return {
              ...accumulator,
              [shortcutKey]: shortcutValue,
            };
          }

          return {
            ...accumulator,
            [shortcutKey]: {
              ...shortcutValue,
              results: filterShortcut(shortcutValue.results) as Record<
                string,
                AnyNonRootShortcut
              >,
            },
          };
        },
        {},
      );

    const filteredShortcuts = filterShortcut(
      shortcuts as Record<string, AnyShortcut>,
    );
    const permissionNamesToCheck = Array.from(
      permissionsToAddForChecking.values(),
    );
    Promise.all(
      permissionNamesToCheck.map(permissionName =>
        permissionApi.authorize({
          permission: SLPermissions.component[permissionName],
        }),
      ),
    )
      .then(results => {
        setPermissionStatus(prevPermissionStatus => ({
          ...prevPermissionStatus,
          ...Object.fromEntries(
            results.map(({ result }, index) => [
              permissionNamesToCheck[index],
              result === AuthorizeResult.ALLOW,
            ]),
          ),
        }));
        setPermissionsChecked(prevPermissionsChecked => ({
          ...prevPermissionsChecked,
          ...Object.fromEntries(
            results.map(({ result: _result }, index) => [
              permissionNamesToCheck[index],
              true,
            ]),
          ),
        }));
      })
      .catch(_ => {});
    return filteredShortcuts as Record<string, AnyRootShortcut>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionApi, Object.keys(permissionStatus).join('-'), shortcuts]);

  const filteredShortcuts = React.useMemo<Record<string, AnyRootShortcut>>(
    filterShortcuts,
    [filterShortcuts],
  );

  const checkComplete = React.useMemo(
    () =>
      Object.values(permissionStatus).length ===
      Object.values(permissionsChecked).length,
    [permissionStatus, permissionsChecked],
  );

  return {
    shortcuts: filteredShortcuts,
    permissionStatus,
    checkComplete,
  };
};

export default usePermissionedShortcuts;
