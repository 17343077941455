import useAsync from 'react-use/lib/useAsync';
import React from 'react';
import { Header } from '@backstage/core-components';
import { IconComponent, useApi, useApp } from '@backstage/core-plugin-api';
import { SLReviewsApi, SLReviewsApiRef } from '../api';

import {
  handleResponse,
  Icons,
  Page,
  PageData,
} from '@internal/plugin-sl-assets';
import { PRList } from '../components';
import { PR } from '../types';
import { PRStatuses, ReviewStatuses, ReviewStatusValue } from '../status';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ReviewFilters, useFilters } from '../components/filters';

type Props = {
  prs: PR[];
};

const CodeownersReviewsContent = ({ prs }: Props) => {
  const app = useApp();
  const filters = useFilters();
  const prsRequireMe = prs.filter(pr =>
    pr.reviews.codeowners
      .filter(codeownerGroup => codeownerGroup.isMember)
      .some(
        codeownerGroup =>
          codeownerGroup.status === ReviewStatuses.REVIEW_REQUESTED,
      ),
  );
  const prsHandled = prs.filter(pr =>
    pr.reviews.codeowners
      .filter(codeownerGroup => codeownerGroup.isMember)
      .every(codeownerGroup =>
        (
          [
            ReviewStatuses.SEEN,
            ReviewStatuses.AWAITING_CHANGES,
            ReviewStatuses.APPROVED,
            ReviewStatuses.REVIEWED,
            ReviewStatuses.REQUESTED_CHANGES,
          ] as ReviewStatusValue[]
        ).includes(codeownerGroup.status),
      ),
  );
  const prsOpen = prs.filter(pr => pr.state === PRStatuses.READY);

  return (
    <Grid container spacing={1} xs={12}>
      <Grid item xs={2} sx={{ paddingLeft: 1 }}>
        <Paper>
          <Container>
            <Typography variant="h3" sx={{ margin: 5 }}>
              Filters
            </Typography>
            <hr />
            <ReviewFilters {...filters} />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={10} container direction="column" spacing={1}>
        <Grid item>
          <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Typography variant="h3" sx={{ margin: 5 }}>
              Review required
            </Typography>
            <hr />
            <PRList
              prs={prsRequireMe}
              showAllCodeowners={filters.showAllCodeownersFilter[0]}
              individualCodeowners={filters.individualCodeownersFilter[0]}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Typography variant="h3" sx={{ margin: 5 }}>
              Handled
            </Typography>
            <hr />
            <PRList
              prs={prsHandled}
              showAllCodeowners={filters.showAllCodeownersFilter[0]}
              individualCodeowners={filters.individualCodeownersFilter[0]}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={React.createElement(
                  app.getSystemIcon(Icons.EXPAND) as IconComponent,
                )}
              >
                <Typography>Ready PRs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PRList
                  prs={prsOpen}
                  showAllCodeowners={filters.showAllCodeownersFilter[0]}
                  individualCodeowners={filters.individualCodeownersFilter[0]}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item>
          <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={React.createElement(
                  app.getSystemIcon(Icons.EXPAND) as IconComponent,
                )}
              >
                <Typography>All open PRs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PRList
                  prs={prs}
                  showAllCodeowners={filters.showAllCodeownersFilter[0]}
                  individualCodeowners={filters.individualCodeownersFilter[0]}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CodeownersReviewsPage = () => {
  const api = useApi<SLReviewsApi>(SLReviewsApiRef);

  const reviews = useAsync(
    async () => api.codeownerReviews().then(handleResponse),
    [],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => <CodeownersReviewsContent prs={pageData.value} />,
    [],
  );

  return (
    <Page
      pageData={reviews}
      header={() => <Header title="Codeowner reviews" />}
      content={renderPage}
    />
  );
};
