import React from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {
  getBadgeColor,
  getResultTitle,
  isOnHfBranch,
  PortalVersionStatus,
  Props,
  StatusToIcon,
} from './constants';
import {
  GithubUrlSchema,
  BotPatchedCatalogEntity,
  Icons,
} from '@internal/plugin-sl-assets';
import { useArgoProgress } from './useArgoProgress';
import { ArgoProgressBar } from './argo';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

const PortalVersionListItemMobile = ({
  result,
  argoProgressBars,
  loading,
  error,
}: {
  loading: boolean;
  error: Error | undefined;
  result: BotPatchedCatalogEntity;
  argoProgressBars?: Array<ArgoProgressBar>;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const app = useApp();

  return (
    <>
      <Drawer
        key={`${result.metadata.name}-drawer`}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Badge
                overlap="rectangular"
                sx={{
                  '& .v5-MuiBadge-badge': {
                    padding: '0',
                    height: 'initial',
                    backgroundColor: getBadgeColor(result),
                  },
                }}
                badgeContent={React.createElement(
                  app.getSystemIcon(
                    StatusToIcon[
                      !isOnHfBranch(result)
                        ? (result.botsResults
                            ?.status as (typeof PortalVersionStatus)[keyof typeof PortalVersionStatus]) ||
                          PortalVersionStatus.ERRORED
                        : PortalVersionStatus.HF
                    ],
                  ) || (app.getSystemIcon(Icons.QUESTION) as IconComponent),
                )}
              >
                <Avatar>
                  {React.createElement(
                    app.getSystemIcon(
                      result.metadata.annotations['sectorlabs/icon'],
                    ) || (app.getSystemIcon(Icons.QUESTION) as IconComponent),
                  )}
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6">{getResultTitle(result)}</Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          {result.botsResults ? (
            <Link
              href={
                isOnHfBranch(result)
                  ? GithubUrlSchema.branchCompare(
                      result.metadata.annotations['github.com/project-slug'],
                      'production',
                      result.botsResults.branch as string,
                    )
                  : GithubUrlSchema.commits(
                      result.metadata.annotations['github.com/project-slug'],
                      result.botsResults.branch as string,
                    )
              }
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    {React.createElement(
                      app.getSystemIcon(Icons.GITHUB) as IconComponent,
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Branch"
                  secondary={result.botsResults.branch}
                />
              </ListItem>
            </Link>
          ) : null}
          {result.botsResults?.commit ? (
            <Link
              href={GithubUrlSchema.commits(
                result.metadata.annotations['github.com/project-slug'],
                result.botsResults?.commit as string,
              )}
            >
              <ListItem key="version">
                <ListItemAvatar>
                  <Avatar>
                    {React.createElement(
                      app.getSystemIcon(Icons.GITHUB) as IconComponent,
                    )}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary="Commit"
                  secondary={(result.botsResults?.commit as string)?.slice(
                    0,
                    7,
                  )}
                />
              </ListItem>
            </Link>
          ) : null}
          {result.botsErrors ? (
            <>
              <Divider key="errorDivider" />
              {Object.entries(result.botsErrors).map(
                ([errorKey, errorValue]) => (
                  <ListItem key={errorKey}>
                    <ListItemText
                      primary={
                        <Typography color="error">{errorKey}</Typography>
                      }
                      secondary={errorValue}
                    />
                  </ListItem>
                ),
              )}
            </>
          ) : null}
        </List>
      </Drawer>
      <ListItem
        key={`${result.metadata.name}-item`}
        onClick={() => setOpen(true)}
      >
        <ListItemAvatar>
          <Badge
            overlap="rectangular"
            sx={{
              '& .v5-MuiBadge-badge': {
                padding: '0',
                height: 'initial',
                backgroundColor: getBadgeColor(result),
              },
            }}
            badgeContent={React.createElement(
              app.getSystemIcon(
                StatusToIcon[
                  !isOnHfBranch(result)
                    ? (result.botsResults
                        ?.status as (typeof PortalVersionStatus)[keyof typeof PortalVersionStatus]) ||
                      PortalVersionStatus.ERRORED
                    : PortalVersionStatus.HF
                ],
              ) || (app.getSystemIcon(Icons.QUESTION) as IconComponent),
            )}
          >
            <Avatar
              alt={result.metadata.name}
              style={{ height: 28, width: 28 }}
            >
              {React.createElement(
                app.getSystemIcon(
                  result.metadata.annotations['sectorlabs/icon'],
                ) || (app.getSystemIcon(Icons.LANGUAGE) as IconComponent),
              )}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText>{getResultTitle(result)}</ListItemText>
        <ListItemSecondaryAction>
          {argoProgressBars &&
          result.metadata.annotations['argocd/app-name'] ? (
            <IconButton>
              <Box sx={{ position: 'relative' }}>
                <Avatar>
                  {React.createElement(
                    app.getSystemIcon(Icons.ARGOCD) as IconComponent,
                  )}
                </Avatar>
                {loading ? (
                  <CircularProgress
                    variant="indeterminate"
                    size={47}
                    sx={{
                      top: -3,
                      left: -4,
                      zIndex: 1,
                      position: 'absolute',
                    }}
                  />
                ) : null}
                {!loading && error ? (
                  <CircularProgress
                    variant="determinate"
                    size={47}
                    sx={{
                      color: 'orange',
                      top: -3,
                      left: -4,
                      zIndex: 1,
                      position: 'absolute',
                    }}
                  />
                ) : null}
                {!loading && argoProgressBars
                  ? argoProgressBars?.map(
                      ({ progress, color }: ArgoProgressBar, index: number) => (
                        <CircularProgress
                          key={index}
                          variant="determinate"
                          size={47}
                          value={progress}
                          sx={{
                            color,
                            top: -3,
                            left: -4,
                            zIndex: index + 1,
                            position: 'absolute',
                          }}
                        />
                      ),
                    )
                  : null}
              </Box>
            </IconButton>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export const VersionListMobile = ({ pageData }: Props) => {
  const app = useApp();
  const [upToDateProductionDrawerVisible, setUpToDateProductionDrawerVisible] =
    React.useState<boolean>(false);
  const { loading, error, value: argoProgress } = useArgoProgress(pageData);

  const entitiesByStatus = Object.fromEntries(
    Object.values(PortalVersionStatus).map(status => [
      status,
      pageData.value.filter(
        (result: BotPatchedCatalogEntity) =>
          status ===
          (result.botsResults?.status || PortalVersionStatus.ERRORED),
      ),
    ]),
  );

  return (
    <Container>
      <Paper>
        {entitiesByStatus[PortalVersionStatus.UP_TO_DATE].length ? (
          <>
            <Drawer
              key={`${PortalVersionStatus.UP_TO_DATE}-drawer`}
              anchor="bottom"
              open={upToDateProductionDrawerVisible}
              onClose={() => setUpToDateProductionDrawerVisible(false)}
            >
              <List>
                {entitiesByStatus[PortalVersionStatus.UP_TO_DATE]
                  .filter(
                    (result: BotPatchedCatalogEntity) => !isOnHfBranch(result),
                  )
                  .map((result: BotPatchedCatalogEntity) => (
                    <ListItem key={result.metadata.name}>
                      <ListItemAvatar>
                        <Badge
                          overlap="rectangular"
                          sx={{
                            '& .v5-MuiBadge-badge': {
                              padding: '0',
                              height: 'initial',
                              backgroundColor: getBadgeColor(result),
                            },
                          }}
                          badgeContent={React.createElement(
                            app.getSystemIcon(
                              StatusToIcon[PortalVersionStatus.UP_TO_DATE],
                            ) as IconComponent,
                          )}
                        >
                          <Avatar style={{ width: '25px', height: '25px' }}>
                            {React.createElement(
                              app.getSystemIcon(
                                result.metadata.annotations['sectorlabs/icon'],
                              ) ||
                                (app.getSystemIcon(
                                  Icons.LANGUAGE,
                                ) as IconComponent),
                            )}
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText>{getResultTitle(result)}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            </Drawer>
            <List
              key={`${PortalVersionStatus.UP_TO_DATE}-list`}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Up to date
                </ListSubheader>
              }
            >
              <ListItem
                onClick={() => setUpToDateProductionDrawerVisible(true)}
              >
                <AvatarGroup max={7}>
                  {entitiesByStatus[PortalVersionStatus.UP_TO_DATE]
                    .filter(
                      (result: BotPatchedCatalogEntity) =>
                        !isOnHfBranch(result),
                    )
                    .map((result: BotPatchedCatalogEntity) => (
                      <Avatar
                        alt={result.metadata.name}
                        key={`${result.metadata.name}-avatar`}
                      >
                        {React.createElement(
                          app.getSystemIcon(
                            result.metadata.annotations['sectorlabs/icon'],
                          ) ||
                            (app.getSystemIcon(
                              Icons.LANGUAGE,
                            ) as IconComponent),
                        )}
                      </Avatar>
                    ))}
                </AvatarGroup>
              </ListItem>
            </List>
            {entitiesByStatus[PortalVersionStatus.UP_TO_DATE].filter(
              isOnHfBranch,
            ).length ? (
              <List
                key={`${PortalVersionStatus.UP_TO_DATE}-hf-list`}
                subheader={
                  <ListSubheader component="div">
                    Up to date with HF branch
                  </ListSubheader>
                }
              >
                {entitiesByStatus[PortalVersionStatus.UP_TO_DATE]
                  .filter(isOnHfBranch)
                  .map((result: BotPatchedCatalogEntity, index: number) => (
                    <PortalVersionListItemMobile
                      key={index}
                      result={result}
                      loading={loading}
                      error={error}
                    />
                  ))}
              </List>
            ) : null}
          </>
        ) : null}
        {entitiesByStatus[PortalVersionStatus.OUTDATED].length ? (
          <>
            <Divider key={`${PortalVersionStatus.OUTDATED}-divider`} />
            <List
              key={`${PortalVersionStatus.OUTDATED}-list`}
              subheader={
                <ListSubheader component="div">Outdated</ListSubheader>
              }
            >
              {entitiesByStatus[PortalVersionStatus.OUTDATED].map(
                (result: BotPatchedCatalogEntity, index: number) => (
                  <PortalVersionListItemMobile
                    key={index}
                    result={result}
                    loading={loading}
                    error={error}
                    argoProgressBars={
                      argoProgress?.[
                        result.metadata.annotations['argocd/app-name']
                      ] || []
                    }
                  />
                ),
              )}
            </List>
          </>
        ) : null}
        {entitiesByStatus[PortalVersionStatus.ERRORED].length ? (
          <>
            <Divider key={`${PortalVersionStatus.ERRORED}-divider`} />
            <List
              key={`${PortalVersionStatus.ERRORED}-list`}
              subheader={<ListSubheader component="div">Errors</ListSubheader>}
            >
              {entitiesByStatus[PortalVersionStatus.ERRORED].map(
                (result: BotPatchedCatalogEntity, index: number) => (
                  <PortalVersionListItemMobile
                    key={index}
                    result={result}
                    loading={loading}
                    error={error}
                  />
                ),
              )}
            </List>
          </>
        ) : null}
      </Paper>
    </Container>
  );
};
