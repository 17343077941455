export const CommandNames = Object.freeze({
  TOOLS_COMMIT_TRACKER: 'tools-commit-tracker',
  ON_CALL_ADMIN: 'on-call-admin',
  ON_CALL_CABLE_ADMIN: 'on-call-cable-admin',
  ON_CALL_SHIFT_COMPENSATION: 'on-call-shift-compensation',
  ON_CALL_DEFAULT_OPSGENIE_NOTIFICATIONS: 'on-call-opsgenie-notifications',
  RELEASE_DEPLOY: 'release-deploy',
  TUBESCREAMER_AD_SEARCH: 'tubescreamer-ad-search',
  TUBESCREAMER_AD_SEARCH_DEV: 'tubescreamer-ad-search-dev',
  GITHUB_PR_BY_ID: 'github-pr-by-id',
  OLX_AD_SEARCH: 'olx-ad-search',
});

export type CommandValue = (typeof CommandNames)[keyof typeof CommandNames];
