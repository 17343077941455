import { SLPermissionsSanitizationError } from './errors';
import { PermissionActions } from '../defaults';
import { SanitizedDefinition, SanitizedDefinitionBaseName } from './types';
import { PermissionAction } from '../defaults/types';
import { UrlDefinition } from '../definitions/types';

/**
 * Sanitize a definition's name and split it into parts
 *
 * @param definition the definition to sanitize
 */
const sanitizeDefinitionBaseName = (
  definition: UrlDefinition,
): SanitizedDefinitionBaseName => {
  if (!/^[a-z-]+(\.[a-z-]+)*$/.test(definition.baseName)) {
    throw new SLPermissionsSanitizationError(
      definition.baseName,
      'Invalid permission key',
      `contains invalid characters or does not follow the naming convention!`,
    );
  }

  PermissionActions.forEach(permissionAction => {
    if (definition.baseName.indexOf(permissionAction) !== -1) {
      throw new SLPermissionsSanitizationError(
        definition.baseName,
        'Invalid name',
        `contains ${permissionAction}`,
      );
    }
  });

  const [plugin, tag] = definition.baseName.split('.', 2);

  return { plugin, tag, baseName: definition.baseName };
};

/**
 * Sanitize a definition's url match rule
 *
 * @param definition the definition to sanitize
 */
const sanitizeDefinitionUrlMatch = (definition: UrlDefinition): string => {
  // The Backstage configuration which uses YAML, does not allow "." for permissions, so we must use "_"
  // as a placeholder
  if (!/^\^/.test(definition.urlMatch)) {
    throw new SLPermissionsSanitizationError(
      definition.baseName,
      'Invalid URL regex',
      `must start with '^', is actually "${definition.urlMatch}"`,
    );
  }

  return definition.urlMatch;
};

/**
 * Sanitize a definition's permission actions
 *
 * @param definition the definition to sanitize
 */
const sanitizeDefinitionActions = (
  definition: UrlDefinition,
): PermissionAction[] => {
  definition.actions.forEach(action => {
    if (PermissionActions.indexOf(action) === -1) {
      throw new SLPermissionsSanitizationError(
        definition.baseName,
        `Invalid permission action`,
        action,
      );
    }
  });
  return definition.actions;
};

/**
 * Sanitize a definition
 *
 * @param definition the definition to sanitize
 */
export const sanitizeUrlDefinition = (
  definition: UrlDefinition,
): SanitizedDefinition => {
  return {
    ...sanitizeDefinitionBaseName(definition),
    urlMatch: sanitizeDefinitionUrlMatch(definition),
    actions: sanitizeDefinitionActions(definition),
  };
};
