import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
  getBadgeColor,
  getDisplayColor,
  getResultTitle,
  isOnHfBranch,
  PortalVersionStatus,
  Props,
  StatusToIcon,
} from './constants';
import {
  GithubUrlSchema,
  BotPatchedCatalogEntity,
  Icons,
} from '@internal/plugin-sl-assets';
import { useArgoProgress } from './useArgoProgress';
import { ArgoProgressBar } from './argo';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

export const VersionListDesktop = ({ pageData }: Props) => {
  const app = useApp();
  const { loading, value: argoProgress, error } = useArgoProgress(pageData);

  const renderArgoButton = React.useCallback(
    (applicationName: string) => {
      return (
        <IconButton
          size="medium"
          onClick={() =>
            window.open(
              `https://argocd.sector.run/applications/${applicationName}`,
            )
          }
        >
          <Box sx={{ position: 'relative' }}>
            <Avatar>
              {React.createElement(
                app.getSystemIcon(Icons.ARGOCD) as IconComponent,
              )}
            </Avatar>
            {loading ? (
              <CircularProgress
                variant="indeterminate"
                size={47}
                sx={{
                  top: -3,
                  left: -4,
                  zIndex: 1,
                  position: 'absolute',
                }}
              />
            ) : null}
            {!loading && error ? (
              <CircularProgress
                variant="determinate"
                size={47}
                sx={{
                  color: 'orange',
                  top: -3,
                  left: -4,
                  zIndex: 1,
                  position: 'absolute',
                }}
              />
            ) : null}
            {!loading && argoProgress && argoProgress[applicationName]
              ? argoProgress[applicationName]?.map(
                  ({ progress, color }: ArgoProgressBar, index: number) => (
                    <CircularProgress
                      key={index}
                      variant="determinate"
                      size={47}
                      value={progress}
                      sx={{
                        color,
                        top: -3,
                        left: -4,
                        zIndex: index + 1,
                        position: 'absolute',
                      }}
                    />
                  ),
                )
              : null}
          </Box>
        </IconButton>
      );
    },
    [loading, error, app, argoProgress],
  );

  const renderReleaseTime = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);

    const month = date.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month.toString();

    const day = date.getDate();
    const dayString = day < 10 ? `0${day}` : day.toString();

    const hours = date.getHours();
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();

    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();

    const seconds = date.getSeconds();
    const secondsString = seconds < 10 ? `0${seconds}` : seconds.toString();

    return ` (${date.getFullYear()}/${monthString}/${dayString} ${hoursString}:${minutesString}:${secondsString})`;
  };

  return (
    <Container>
      <Paper>
        <List>
          {pageData.value.map((result: BotPatchedCatalogEntity) => {
            const textColor = getDisplayColor(result);
            const textColorProps = ['primary', 'secondary', 'error'].includes(
              textColor,
            )
              ? { color: textColor }
              : { sx: { color: textColor } };

            return [
              <ListItem
                key={result.metadata.name}
                sx={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemAvatar>
                  <Badge
                    sx={{
                      '& .v5-MuiBadge-badge': {
                        padding: '0',
                        height: 'initial',
                        backgroundColor: getBadgeColor(result),
                      },
                    }}
                    overlap="rectangular"
                    badgeContent={React.createElement(
                      app.getSystemIcon(
                        StatusToIcon[
                          !isOnHfBranch(result)
                            ? (result.botsResults
                                ?.status as (typeof PortalVersionStatus)[keyof typeof PortalVersionStatus]) ||
                              PortalVersionStatus.ERRORED
                            : PortalVersionStatus.HF
                        ],
                      ) || (app.getSystemIcon(Icons.LANGUAGE) as IconComponent),
                    )}
                  >
                    <Avatar style={{ height: '35px', width: '35px' }}>
                      {React.createElement(
                        app.getSystemIcon(
                          result.metadata.annotations['sectorlabs/icon'],
                        ) ||
                          (app.getSystemIcon(Icons.LANGUAGE) as IconComponent),
                      )}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Link
                        href={
                          result.metadata.annotations['backstage.io/view-url']
                        }
                        {...textColorProps}
                      >
                        {getResultTitle(result)}
                      </Link>
                      {result.botsResults ? (
                        <>
                          &nbsp;(
                          <Link
                            {...textColorProps}
                            href={
                              isOnHfBranch(result)
                                ? GithubUrlSchema.branchCompare(
                                    result.metadata.annotations[
                                      'github.com/project-slug'
                                    ],
                                    'production',
                                    result.botsResults.branch as string,
                                  )
                                : GithubUrlSchema.commits(
                                    result.metadata.annotations[
                                      'github.com/project-slug'
                                    ],
                                    result.botsResults.branch as string,
                                  )
                            }
                          >
                            {result.botsResults.branch}
                          </Link>
                          )
                        </>
                      ) : null}
                    </>
                  }
                  secondary={
                    <>
                      {result.botsResults?.version ? (
                        <Link
                          {...textColorProps}
                          key="commit-link"
                          href={
                            (result.botsResults?.version as string).startsWith(
                              'v',
                            )
                              ? GithubUrlSchema.tags(
                                  result.metadata.annotations[
                                    'github.com/project-slug'
                                  ],
                                  result.botsResults?.version as string,
                                )
                              : GithubUrlSchema.commits(
                                  result.metadata.annotations[
                                    'github.com/project-slug'
                                  ],
                                  result.botsResults?.version as string,
                                )
                          }
                        >
                          {result.botsResults?.version}
                        </Link>
                      ) : null}
                      {result.botsResults?.time
                        ? renderReleaseTime(result.botsResults?.time as number)
                        : null}
                      {result.botsErrors
                        ? Object.entries(result.botsErrors).map(
                            ([field, botError]) => (
                              <Typography key={field} color="error">
                                {field}: {botError}
                              </Typography>
                            ),
                          )
                        : null}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {result.metadata.annotations['sectorlabs/github-workflow'] ? (
                    <IconButton
                      size="small"
                      onClick={() =>
                        window.open(
                          `https://www.github.com/${result.metadata.annotations['github.com/project-slug']}/actions/workflows/${result.metadata.annotations['sectorlabs/github-workflow']}.yml`,
                        )
                      }
                    >
                      <Avatar>
                        {React.createElement(
                          app.getSystemIcon(Icons.GITHUB) as IconComponent,
                        )}
                      </Avatar>
                    </IconButton>
                  ) : null}
                  {!result.metadata.annotations['sectorlabs/github-workflow'] &&
                  result.botsResults?.branch ? (
                    <IconButton
                      size="small"
                      onClick={() =>
                        window.open(
                          `https://app.circleci.com/pipelines/github/${result.metadata.annotations['github.com/project-slug']}?branch=${result.botsResults?.branch}`,
                        )
                      }
                    >
                      <Avatar>
                        {React.createElement(
                          app.getSystemIcon(Icons.CIRCLECI) as IconComponent,
                        )}
                      </Avatar>
                    </IconButton>
                  ) : null}
                  {result.metadata.annotations['argocd/app-name']
                    ? renderArgoButton(
                        result.metadata.annotations['argocd/app-name'],
                      )
                    : null}
                </ListItemSecondaryAction>
              </ListItem>,
              <Divider key={`${result.metadata.name}-divider`} />,
            ];
          })}
        </List>
      </Paper>
    </Container>
  );
};
