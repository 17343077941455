import React from 'react';

type Props = {
  action: () => void;
  withPropagation?: boolean;
};

const useCtrlKInterceptor = ({ action, withPropagation = false }: Props) => {
  React.useEffect(() => {
    const ctrlKInterceptor = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === 'k' || event.key === 'K')) {
        action();
        if (!withPropagation) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    };

    document.addEventListener('keydown', ctrlKInterceptor);
    return () => document.removeEventListener('keydown', ctrlKInterceptor);
  }, [action, withPropagation]);

  return null;
};

export default useCtrlKInterceptor;
