import React from 'react';
import { SLOnCallApi, SLOnCallApiRef } from '../api';
import {
  BaseCommandShortcut,
  ShortcutComponentProps,
} from '@internal/plugin-sl-shortcuts';
import { useApi } from '@backstage/core-plugin-api';
import { handleResponse } from '@internal/plugin-sl-assets';

type Props = ShortcutComponentProps & {
  portal: string;
};

export const TubescreamerAdSearch = ({ portal, ...props }: Props) => {
  const api = useApi<SLOnCallApi>(SLOnCallApiRef);

  return (
    <BaseCommandShortcut
      {...props}
      request={input =>
        api
          .adminUrl(portal)
          .then(handleResponse)
          .then(data => ({
            ...data,
            url: `${data.url}/ts/object/1/${input.id}`,
          }))
      }
    />
  );
};

export const tubescreamerAdSearchFactory =
  (portal: string) => (props: ShortcutComponentProps) =>
    <TubescreamerAdSearch {...props} portal={portal} />;
