import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { DAYS_OF_THE_WEEK, Icons, IconValue } from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

export const EventTypes = Object.freeze({
  IMPORTANT: 'important',
  UNIQUE: 'unique',
  ORDINARY: 'ordinary',
});

export type EventProps = {
  startDate: Date;
  endDate?: Date;
  name: string;
  type?: (typeof EventTypes)[keyof typeof EventTypes];
  secondTicks?: number;
  details?: string;
  url?: string;
  withCountdown?: boolean;
  icon?: IconValue;
};

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const Event = ({
  startDate,
  endDate,
  name,
  details,
  url,
  type,
  icon,
  secondTicks = 0.5,
  withCountdown = false,
}: EventProps) => {
  const app = useApp();
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date());

  const showDaysLeftOnly = !startDate.getHours();
  // In days-only mode, we want to show "Today" if an event happens today
  const daysLeftOnlyDeltaMs = showDaysLeftOnly
    ? HOUR * 23 + MINUTE * 59 + SECOND * 59
    : 0;

  const startDeltaMiliseconds =
    // @ts-ignore Dates know how to be subtracted
    startDate - currentDate > 0 - daysLeftOnlyDeltaMs
      ? // @ts-ignore Dates know how to be subtracted
        startDate - currentDate + daysLeftOnlyDeltaMs
      : null;

  const endDeltaMiliseconds =
    // @ts-ignore Dates know how to be subtracted
    endDate && endDate - currentDate > 0 - daysLeftOnlyDeltaMs
      ? // @ts-ignore Dates know how to be subtracted
        endDate - currentDate + daysLeftOnlyDeltaMs
      : null;

  React.useEffect(() => {
    if (!showDaysLeftOnly) {
      const timeout = setTimeout(
        () => setCurrentDate(new Date()),
        secondTicks * 1000,
      );
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [secondTicks, currentDate, showDaysLeftOnly]);

  let color: 'primary' | 'secondary' | undefined = undefined;
  if (type === EventTypes.IMPORTANT) {
    color = 'secondary';
  } else if (type === EventTypes.UNIQUE) {
    color = 'primary';
  }

  const content = [];
  const shouldPickStartToDisplay =
    // @ts-ignore Dates know how to be subtracted
    startDate - currentDate > 0 - daysLeftOnlyDeltaMs &&
    ((showDaysLeftOnly && startDate > currentDate) ||
      !showDaysLeftOnly ||
      !endDate ||
      !endDeltaMiliseconds);
  const deltaMiliseconds =
    (shouldPickStartToDisplay ? startDeltaMiliseconds : null) ||
    endDeltaMiliseconds;

  if (!deltaMiliseconds) {
    return null;
  }

  let showInPrefix = true;

  const daysLeft = Math.floor(deltaMiliseconds / DAY);
  const hoursLeft = Math.floor((deltaMiliseconds % DAY) / HOUR);
  const minutesLeft = Math.floor((deltaMiliseconds % HOUR) / MINUTE);
  const secondsLeft = Math.floor((deltaMiliseconds % MINUTE) / SECOND);

  if (showDaysLeftOnly && !daysLeft) {
    content.push('Today');
    showInPrefix = false;
  }

  if (daysLeft) {
    if (showDaysLeftOnly && daysLeft === 1) {
      content.push('Tomorrow');
      showInPrefix = false;
    } else {
      content.push(`${daysLeft} day${daysLeft > 1 ? 's' : ''}`);
    }
  }
  if (!showDaysLeftOnly && hoursLeft) {
    content.push(`${hoursLeft} hour${hoursLeft > 1 ? 's' : ''}`);
  }
  if (!showDaysLeftOnly && minutesLeft) {
    content.push(`${minutesLeft} minute${minutesLeft > 1 ? 's' : ''}`);
  }
  if (!showDaysLeftOnly && !daysLeft && secondsLeft) {
    content.push(`${secondsLeft} second${secondsLeft > 1 ? 's' : ''}`);
  }

  return (
    <Tooltip
      title={
        <p>
          {showDaysLeftOnly ? (
            <>
              On{' '}
              <i>
                {DAYS_OF_THE_WEEK[(startDate.getDay() + 6) % 7]},{' '}
                {startDate.toLocaleDateString()}
              </i>
            </>
          ) : (
            <>
              From <i>{startDate.toString()}</i>
            </>
          )}
          {!showDaysLeftOnly && endDate ? (
            <i>{` to ${endDate.toString()}`}</i>
          ) : (
            ''
          )}
          {details ? (
            <>
              <br />
              <br />
              {details}
            </>
          ) : null}
        </p>
      }
      arrow
    >
      <ListItem>
        <ListItemIcon>
          <Avatar>
            {React.createElement(
              app.getSystemIcon(icon || Icons.CALENDAR) as IconComponent,
            )}
          </Avatar>
        </ListItemIcon>
        <ListItemText>
          <Typography color={color}>
            {url ? (
              <a href={url}>
                <u>
                  <b>{name}</b>
                </u>
              </a>
            ) : (
              <b>{name}</b>
            )}
          </Typography>
          <Typography color={color}>
            <>
              {DAYS_OF_THE_WEEK[(startDate.getDay() + 6) % 7]},{' '}
              {startDate.toLocaleDateString()}
              {!showDaysLeftOnly ? ` ${startDate.toLocaleTimeString()}` : null}
              {withCountdown ? (
                <>
                  <br />
                  {shouldPickStartToDisplay ? '' : 'ends '}
                  {showInPrefix ? 'in ' : ''}
                  {content.length > 1 ? (
                    <>
                      <i>{content.slice(0, content.length - 1).join(', ')}</i>{' '}
                      and <i>{content[content.length - 1]}</i>
                    </>
                  ) : (
                    <i>{content[0]}</i>
                  )}
                </>
              ) : null}
            </>
          </Typography>
        </ListItemText>
      </ListItem>
    </Tooltip>
  );
};
