import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
  },
  cls2: {
    fill: '#e9654b',
  },
  cls3: {
    fill: '#b6cfea',
  },
  cls6: {
    fill: '#ee794b',
  },
  cls12: {
    fill: '#010101',
  },
  cls13: {
    fill: '#fff',
  },
});

export const ArgoCDIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={classes.svg}
    >
      <defs id="defs2195">
        <clipPath id="clip-path">
          <path
            id="polyline2192"
            d="m241.019 115.687-7.173 163.792H126.245l-5.978-163.792"
            style={{ fill: 'none' }}
          />
        </clipPath>
      </defs>
      <g id="g2409" transform="translate(-37.223 -4.5)">
        <path
          id="path2199"
          d="M53.687 38.411s-.328.983-.82 1.475a1.487 1.487 0 0 1-1.146.491 18.993 18.993 0 0 1-2.457.492s1.146.163 2.457.327c.491 0 .491 0 .82.164.818 0 1.146-.491 1.146-.491z"
          className={classes.cls2}
          style={{ strokeWidth: '.137025' }}
        />
        <path
          id="path2201"
          d="M68.759 38.411s.327.983.819 1.475a1.487 1.487 0 0 0 1.146.491 18.993 18.993 0 0 0 2.458.492s-1.147.163-2.621.327c-.492 0-.492 0-.82.164-.982 0-1.146-.491-1.146-.491z"
          className={classes.cls2}
          style={{ strokeWidth: '.137025' }}
        />
        <circle
          id="circle2203"
          cx="61.387"
          cy="22.193"
          r="17.693"
          className={classes.cls3}
          style={{ strokeWidth: '.137025' }}
        />
        <circle
          id="circle2205"
          cx="61.387"
          cy="22.193"
          r="17.038"
          style={{ strokeWidth: '.137025', fill: '#e6f5f8' }}
        />
        <circle
          id="circle2207"
          cx="61.387"
          cy="22.52"
          r="13.925"
          style={{ strokeWidth: '.137025', fill: '#d0e8f0' }}
        />
        <g id="Body" transform="translate(36.716 3.883) scale(.13703)">
          <path
            id="path2209"
            d="M120.267 183.834s8.37 135.1 8.37 137.49c0 1.196 1.195 3.587-4.783 5.978-5.978 2.391-25.107 7.173-25.107 7.173h28.694c13.151 0 13.151-10.76 13.151-13.15 0-2.392 3.587-53.801 3.587-53.801s1.195 60.974 1.195 63.365c0 2.39-1.195 5.978-9.564 8.369l-23.912 4.782h27.498c16.738 0 16.738-10.76 16.738-10.76l3.587-53.8s1.196 53.8 1.196 59.778c0 4.782-3.587 8.369-16.738 10.76-8.37 2.39-19.13 4.782-19.13 4.782h31.085c15.543-1.195 17.934-11.956 17.934-11.956l26.302-133.903z"
            className={classes.cls6}
          />
          <path
            id="path2211"
            d="M239.824 183.834s-8.37 135.1-8.37 137.49c0 1.196-1.195 3.587 4.783 5.978 5.978 2.391 25.107 7.173 25.107 7.173H232.65c-13.15 0-13.15-10.76-13.15-13.15 0-2.392-3.588-53.801-3.588-53.801s-1.195 60.974-1.195 63.365c0 2.39 1.195 5.978 9.564 8.369l23.912 4.782h-27.498c-16.738 0-16.738-10.76-16.738-10.76l-3.587-53.8s-1.195 53.8-1.195 59.778c0 4.782 3.586 8.369 16.737 10.76 8.37 2.39 19.13 4.782 19.13 4.782h-31.085c-15.542-1.195-17.934-11.956-17.934-11.956l-26.302-133.903Z"
            className={classes.cls6}
          />
          <path
            id="path2213"
            d="M241.02 118.079c0 33.475-27.499 59.778-59.779 59.778s-59.778-27.498-59.778-59.779c0-32.28 27.498-59.778 59.778-59.778s59.778 26.303 59.778 59.778z"
            className={classes.cls6}
          />
          <g
            id="g2217"
            clipPath="url(#clip-path)"
            style={{ clipPath: 'url(#clip-path)' }}
          >
            <circle
              id="circle2215"
              cx="181.241"
              cy="150.359"
              r="106.405"
              className={classes.cls6}
            />
          </g>
          <g id="g2221" style={{ opacity: 0.22 }}>
            <path
              id="path2219"
              d="M237.433 183.834s7.173-63.364-2.392-87.276C217.108 54.714 175.263 58.3 175.263 58.3s23.912 9.565 25.107 45.432c1.196 25.107 0 62.169 0 62.169z"
              style={{ fill: '#e34e3b' }}
            />
          </g>
        </g>
        <g id="Face" transform="translate(36.716 3.883) scale(.13703)">
          <g id="g2226" style={{ opacity: '.5' }}>
            <circle
              id="circle2224"
              cx="156.134"
              cy="85.798"
              r="8.369"
              style={{ fill: '#fbdfc3' }}
            />
          </g>
          <path
            id="path2228"
            d="M201.566 207.746c0 20.324-9.565 33.475-21.52 33.475-11.956 0-21.52-15.542-21.52-35.866 0 0 9.564 19.129 22.715 19.129s20.325-16.738 20.325-16.738z"
            className={classes.cls12}
          />
          <path
            id="path2230"
            d="M201.566 207.746c0 13.15-9.565 20.324-21.52 20.324-11.956 0-20.325-9.564-20.325-21.52 0 0 9.565 11.956 22.716 11.956 13.15 0 19.129-10.76 19.129-10.76z"
            className={classes.cls13}
          />
          <circle
            id="circle2232"
            cx="221.89"
            cy="151.554"
            r="37.062"
            className={classes.cls2}
          />
          <circle
            id="circle2234"
            cx="138.201"
            cy="151.554"
            r="37.062"
            className={classes.cls2}
          />
          <circle
            id="circle2236"
            cx="221.89"
            cy="151.554"
            r="28.694"
            className={classes.cls13}
          />
          <circle
            id="circle2238"
            cx="135.81"
            cy="151.554"
            r="28.694"
            className={classes.cls13}
          />
          <circle
            id="circle2240"
            cx="135.81"
            cy="147.968"
            r="8.369"
            className={classes.cls12}
          />
          <circle
            id="circle2242"
            cx="221.89"
            cy="147.968"
            r="8.369"
            className={classes.cls12}
          />
        </g>
        <path
          id="path2245"
          d="M43.366 26.616a.774.774 0 0 1-.819-.82v-6.552a.774.774 0 0 1 .82-.82.774.774 0 0 1 .818.82v6.553a.774.774 0 0 1-.819.819z"
          className={classes.cls3}
          style={{ strokeWidth: 0.137025 }}
        />
        <path
          id="path2247"
          d="M79.08 26.616a.774.774 0 0 1-.82-.82v-6.552a.774.774 0 0 1 .82-.82.774.774 0 0 1 .818.82v6.553a.774.774 0 0 1-.819.819z"
          className={classes.cls3}
          style={{ strokeWidth: 0.137025 }}
        />
        <circle
          id="circle2249"
          cx="55.817"
          cy="9.087"
          r=".819"
          className={classes.cls13}
          style={{ strokeWidth: 0.137025 }}
        />
        <path
          id="path2251"
          d="M49.919 31.203a.602.602 0 0 1-.491-.164c-1.966-2.457-2.95-5.57-2.95-8.682a14.618 14.618 0 0 1 1.967-7.372 16.033 16.033 0 0 1 5.242-5.243.72.72 0 0 1 .819.164.72.72 0 0 1-.164.82 13.222 13.222 0 0 0-6.717 11.467 12.91 12.91 0 0 0 2.785 8.027c.164.164.164.655-.163.82-.164.163-.164.163-.328.163z"
          className={classes.cls13}
          style={{ strokeWidth: 0.137025 }}
        />
        <g
          id="g2255"
          style={{ opacity: 0.25 }}
          transform="translate(36.716 3.883) scale(.13703)"
        >
          <path
            id="path2253"
            d="M233.846 255.568v-9.564c-16.738 9.564-34.671 19.129-56.192 19.129-23.91 0-39.453-10.76-54.995-19.13l1.195 9.565s17.934 19.13 54.996 19.13c34.671-1.196 54.996-19.13 54.996-19.13z"
            className={classes.cls2}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
