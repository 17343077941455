import React, { ReactNode } from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SlackSocial } from './socials';
import { GithubSocial } from './socials/github';
import { BackstageSocial } from './socials/backstage';
import { isMobile } from 'react-device-detect';
import { Icons, IconValue } from '../icons';
import { Employee } from '../types';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Action = {
  icon: IconValue;
  title: string;
  onClick: () => void;
};

type Props = {
  employee: Employee;
  highlighted?: boolean;
  extraActions?: Action[];
  actionIconSize?: number;
  renderBadgeContent?: (() => ReactNode) | null;
  avatarIconSize?: number;
  showAcronym?: boolean;
};

export const AvatarContact = ({
  employee,
  renderBadgeContent,
  extraActions,
  actionIconSize,
  avatarIconSize,
  showAcronym,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const splitName = employee.name.split(' ');
  const acronym = `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const app = useApp();
  const deleteIcon = app.getSystemIcon(Icons.DELETE) as IconComponent;

  const avatarSize = actionIconSize ? actionIconSize + 10 : actionIconSize;

  const avatarIconSizeValue = avatarIconSize || (isMobile ? 60 : 50);

  const colorCode = `#${String.fromCharCode(
    'a'.charCodeAt(0) + Math.floor((acronym.charCodeAt(1) % 36) / 6),
  )}${String.fromCharCode(
    'a'.charCodeAt(0) + Math.floor(acronym.charCodeAt(1) % 6),
  )}${String.fromCharCode(
    'a'.charCodeAt(0) + Math.floor(acronym.charCodeAt(0) % 6),
  )}`;

  const renderAvatar = () => (
    <IconButton
      size="small"
      onClick={event => {
        setAnchorEl(event.currentTarget);
      }}
    >
      <Avatar
        sx={{
          display: 'relative',
          height: `${avatarIconSizeValue}px`,
          width: `${avatarIconSizeValue}px`,
          border: 'solid 1px #333',
          ...(showAcronym
            ? {
                backgroundColor: colorCode,
              }
            : {}),
          ...(employee.active === false ? { filter: 'grayscale(100%)' } : {}),
        }}
        alt={employee.name}
        src={!showAcronym ? employee.icon || '' : ''}
      >
        {acronym}
      </Avatar>
    </IconButton>
  );

  const renderCard = () => (
    <Card
      sx={{ position: 'relative', ...(!isMobile ? { maxWidth: '300px' } : {}) }}
    >
      <Avatar
        sx={{
          height: `250px`,
          width: !isMobile ? '300px' : `100%`,
          ...(employee.active === false ? { filter: 'grayscale(100%)' } : {}),
        }}
        alt={employee.name}
        src={employee.icon || ''}
        variant="rounded"
      >
        {acronym}
      </Avatar>
      {employee.active === false ? (
        <div
          style={{
            padding: '5px',
            marginTop: '-33px',
            zIndex: 10,
            display: 'flex',
            background: 'rgba(88, 88, 88, 0.8)',
          }}
        >
          {React.createElement(deleteIcon)}
          <Typography>Deactivated user</Typography>
        </div>
      ) : null}
      <CardContent>
        <Typography variant="h6">{employee.name}</Typography>
      </CardContent>
      <CardActions>
        {employee.active ? (
          <BackstageSocial employee={employee} height={actionIconSize} />
        ) : null}
        {employee.active ? (
          <GithubSocial employee={employee} height={actionIconSize} />
        ) : null}
        <SlackSocial employee={employee} height={actionIconSize} />
        {extraActions?.map(action => {
          const actionIcon =
            app.getSystemIcon(action.icon) ||
            (app.getSystemIcon(Icons.QUESTION) as IconComponent);

          return (
            <Tooltip
              key={action.title}
              title={action.title}
              onClick={action.onClick}
            >
              <Avatar sx={{ height: avatarSize, width: avatarSize }}>
                <IconButton size="small">
                  {React.createElement(actionIcon)}
                </IconButton>
              </Avatar>
            </Tooltip>
          );
        })}
      </CardActions>
    </Card>
  );

  return (
    <>
      {renderBadgeContent ? (
        <Badge overlap="circular" badgeContent={renderBadgeContent()}>
          {renderAvatar()}
        </Badge>
      ) : (
        renderAvatar()
      )}
      {!isMobile ? (
        <Popover
          id={id}
          onClose={() => setAnchorEl(null)}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {renderCard()}
        </Popover>
      ) : (
        <Drawer
          anchor="bottom"
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {renderCard()}
        </Drawer>
      )}
    </>
  );
};
