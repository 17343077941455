import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    'enable-background': 'new 60 -8 143 40;',
    padding: '1px',
  },
  st0: {
    fill: '#430098',
  },
});

export const HerokuIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="50 -3 55 30"
      className={classes.svg}
    >
      <g id="Page-1">
        <path
          id="logo"
          className={classes.st0}
          d="M92.3-8H63.6c-2,0-3.6,1.6-3.6,3.6v32.8c0,2,1.6,3.6,3.6,3.6h28.7     c2,0,3.6-1.6,3.6-3.6V-4.4C95.9-6.4,94.3-8,92.3-8z M93.9,28.4c0,0.9-0.7,1.6-1.6,1.6H63.6c-0.9,0-1.6-0.7-1.6-1.6V-4.4     C62-5.3,62.7-6,63.6-6h28.7c0.9,0,1.6,0.7,1.6,1.6V28.4z M69,26l4.5-4L69,18V26z M85.2,9.8C84.4,9,82.9,8,80.4,8     c-2.7,0-5.5,0.7-7.5,1.4V-2h-4v17.3l2.8-1.3c0,0,4.6-2.1,8.6-2.1c2,0,2.5,1.1,2.5,2.1V26h4V14C86.9,13.7,86.9,11.5,85.2,9.8z      M79.9,4.5h4c1.8-2.1,2.7-4.2,3-6.5h-4C82.5,0.3,81.5,2.4,79.9,4.5z "
        />
      </g>
    </SvgIcon>
  );
};
