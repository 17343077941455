import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';

export class SLReleaseApi extends SLBotsApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, Bots.RELEASE);
  }

  getQaAssignations() {
    return this.get('/api/qa/assignments?new=1');
  }

  patchQaAssignations(applicationId: string, emails: string[], type: string) {
    return this.patch(
      '/api/qa/assignments',
      JSON.stringify({ app: applicationId, emails: emails.join(','), type }),
      {
        'Content-Type': 'application/json',
      },
    );
  }

  getQaOptions() {
    return this.users({ role: 'qa', active: 1 });
  }

  getReleaseVersion(service: string, environment: string, fields: string[]) {
    return this.post(
      `/api/releaseVersion`,
      JSON.stringify({
        catalog: {
          filter: [
            {
              ['metadata.annotations.sectorlabs/service']: service,
              ['metadata.annotations.sectorlabs/environment']: environment,
            },
          ],
          fields,
        },
      }),
      { 'Content-Type': 'application/json' },
    );
  }

  getReleaseNotes(timestamp: number, repository: string = 'SectorLabs/maple') {
    return this.get(`/api/releaseNotes/data/${repository}/${timestamp}`);
  }

  getDeployPR(repository: string, branch: string) {
    return this.get(`/api/deploy/next/${repository}/${branch}`);
  }

  createDeployPR(repository: string, branch: string, force: boolean = false) {
    return this.post(
      `/api/deploy/next/${repository}/${branch}`,
      JSON.stringify({ force }),
    );
  }

  prepareHf(repository: string, entityName: string, force: boolean = false) {
    return this.post(
      `/api/deploy/prepareHotfix/${repository}/${entityName}`,
      JSON.stringify({ force }),
    );
  }

  backport(repository: string, branch: string, dry: boolean = false) {
    return this.post(
      `/api/deploy/backport/${repository}/${branch}`,
      JSON.stringify({ dry }),
    );
  }
}

export const SLReleaseApiRef = createApiRef<SLReleaseApi>({
  id: 'plugin.sl-release.api',
});

export const useReleaseApi = () => {
  return useApi<SLReleaseApi>(SLReleaseApiRef);
};
