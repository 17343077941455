import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Employee, Icons } from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  employee: Employee;
  height?: number;
};

export const GithubSocial = ({ employee, height }: Props) => {
  const app = useApp();
  const { githubId } = employee;
  const link = `https://www.github.com/${githubId}`;

  const avatarSize = height ? height + 10 : height;

  if (!githubId) return null;

  return (
    <Tooltip title="View GitHub profile">
      <Avatar style={{ height: avatarSize, width: avatarSize }}>
        <IconButton onClick={() => window.open(link)} size="small">
          {React.createElement(
            app.getSystemIcon(Icons.GITHUB) as IconComponent,
          )}
        </IconButton>
      </Avatar>
    </Tooltip>
  );
};
