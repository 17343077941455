import { UserGranting } from './types';

export const UserEmailGrantedPermissions: UserGranting[] = [
  // Users with catalog entry
  {
    email: 'tudor@sectorlabs.ro',
    permissionGroups: ['sl.backstage.admin'],
    permissions: [],
  },
  {
    email: 'bogdan@sectorlabs.ro',
    permissionGroups: [],
    permissions: [],
  },
  {
    email: 'adela@sectorlabs.ro',
    permissionGroups: ['sl.qa.manager'],
    permissions: [],
  },
  // Users without catalog entry
  {
    email: 'andrea@sectorlabs.ro',
    permissionGroups: ['all', 'sl.hr'],
    permissions: [],
  },
  {
    email: 'leda@sectorlabs.ro',
    permissionGroups: ['all', 'sl.hr'],
    permissions: [],
  },
  {
    email: 'amna.maqsood@dubizzlelabs.com',
    permissionGroups: ['all', 'sl.qa.manager'],
    permissions: [],
  },
  {
    email: 'hasnain.zaidi@dubizzlelabs.com',
    permissionGroups: ['all', 'sl.qa.manager'],
    permissions: [],
  },
  {
    email: 'hafsa.khan@dubizzlelabs.com',
    permissionGroups: ['all', 'sl.qa.manager'],
    permissions: [],
  },
  {
    email: 'ahsham.khan@dubizzlelabs.com',
    permissionGroups: ['all', 'sl.qa.manager'],
    permissions: [],
  },
  {
    email: 'oana.juganaru@sectorlabs.ro',
    permissionGroups: ['all'],
    permissions: [],
  },
];
