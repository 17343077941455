import React from 'react';
import {
  ComponentDefinition,
  GithubGroupGranting,
  PermissionGroupDefinition,
  PermissionGroupName,
  UserGranting,
  GithubGroupsGrantedPermissions,
  UserEmailGrantedPermissions,
  UrlPermissionDefinitions,
  RequestMethodMapping,
  UrlDefinition,
  RequestMethod,
} from '@internal/plugin-sl-permissions';
import { AvatarContact, Employee, Icons } from '@internal/plugin-sl-assets';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { getDefinitionGithubLink } from './links';
import { Bots } from '@internal/plugin-sl-bots';
import {
  AnyRootShortcut,
  AnyShortcut,
  isShortcutAnyGroup,
} from '@internal/backstage-plugin-sl-common';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  componentDefinition: ComponentDefinition;
  permissionGroupMapping: Record<
    PermissionGroupName,
    PermissionGroupDefinition
  >;
  shortcuts: AnyRootShortcut[];
  employees: Record<string, Employee>;
};

const cellCSS = {
  display: 'flex',
  flexAlign: 'center',
};

export const ComponentPermissionExplanation = ({
  componentDefinition,
  permissionGroupMapping,
  shortcuts,
  employees,
}: Props) => {
  const app = useApp();

  const explainUrlRegex = (urlDefinition: UrlDefinition): string => {
    const pluginName = urlDefinition.baseName.split('.')[0];
    const pluginString = `in ${pluginName} plugin`;

    if (urlDefinition.urlMatch === '^/') {
      return `everything ${pluginString}`;
    }

    if (
      pluginName === 'proxy' &&
      urlDefinition.urlMatch.match(
        new RegExp(`^\\^/sl-bots/(${Object.values(Bots).join('|')})/.*$`),
      )
    ) {
      const urlMatchSplit = urlDefinition.urlMatch.split('/');
      const botName = urlMatchSplit[2];
      return `/${urlMatchSplit.slice(3).join('/')} from ${botName} bot`;
    }

    if (
      pluginName === 'proxy' &&
      urlDefinition.urlMatch.match(new RegExp(`^\\^/sl-bots/\\[\\^/]\\+/.*$`))
    ) {
      return `/${urlDefinition.urlMatch
        .split('/')
        .slice(4)
        .join('/')} from any bot`;
    }
    return `${urlDefinition.urlMatch} ${pluginString}`;
  };

  const parseShortcuts = React.useCallback(
    (shortcut: AnyShortcut): AnyShortcut[] => {
      const validShortcuts =
        shortcut.permissionName === componentDefinition.name ? [shortcut] : [];
      if (isShortcutAnyGroup(shortcut)) {
        return [
          ...validShortcuts,
          ...Object.values(shortcut.results).reduce<AnyShortcut[]>(
            (result, shortcutResult) => [
              ...result,
              ...parseShortcuts(shortcutResult),
            ],
            [],
          ),
        ];
      }
      return validShortcuts;
    },
    [componentDefinition.name],
  );

  const usedShortcuts = React.useMemo(
    () =>
      shortcuts.reduce<AnyShortcut[]>(
        (result, shortcut) => [...result, ...parseShortcuts(shortcut)],
        [],
      ),
    [parseShortcuts, shortcuts],
  );

  const isGrantedDirectly = (
    granted: UserGranting | GithubGroupGranting,
  ): boolean => granted.permissions.includes(componentDefinition.name);
  const getGroupsThroughGranted = (
    granted: UserGranting | GithubGroupGranting,
  ): PermissionGroupName[] =>
    granted.permissionGroups.filter(
      permissionGroupName =>
        permissionGroupName !== 'all' &&
        permissionGroupMapping[permissionGroupName].permissions.includes(
          componentDefinition.name,
        ),
    );

  if (componentDefinition.matchType === 'one') {
    return (
      <Paper sx={{ marginBottom: 3 }}>
        <Container>
          <Typography variant="h3">{componentDefinition.name}</Typography>
          <Typography variant="caption">
            {componentDefinition.description} (
            <Link href={getDefinitionGithubLink('component')}>
              see definition here
            </Link>
            )
          </Typography>
          {usedShortcuts.length ? (
            <>
              <Typography key="grants">
                Grants access to frontend components:
              </Typography>
              <Container key="grants-elements">
                {usedShortcuts.map(shortcut => (
                  <Typography key={shortcut.description || shortcut.title}>
                    {shortcut.title} group
                  </Typography>
                ))}
              </Container>
            </>
          ) : null}
          <Typography>
            Indirectly granted to all users who have been granted at least one
            of the following:
          </Typography>
          <Container>
            {componentDefinition.members.map(memberName => (
              <Typography variant="body1" key={memberName}>
                <Link variant="body1" key={memberName} href={`#${memberName}`}>
                  {memberName}
                </Link>
                &nbsp;
              </Typography>
            ))}
          </Container>
        </Container>
      </Paper>
    );
  }

  const matchingUrlDefinitions = Object.values(
    componentDefinition.members
      .map(
        memberName =>
          [
            UrlPermissionDefinitions.find(
              urlDefinition =>
                urlDefinition.baseName === memberName.replace(/\.[a-z-]+$/, ''),
            ),
            memberName.replace(/([a-z-]+\.)+/, ''),
          ] as [UrlDefinition, string],
      )
      .reduce<Record<string, UrlDefinition & { methods: RequestMethod[] }>>(
        (result, value) => ({
          ...result,
          [value[0].baseName]: {
            ...value[0],
            methods: [
              ...(result[value[0].baseName]?.methods || []),
              ...((Object.entries(RequestMethodMapping)
                .filter(([_, action]) => action === value[1])
                .map(([urlMethod, _]) => urlMethod) as RequestMethod[]) || []),
            ],
          },
        }),
        {},
      ),
  );

  return (
    <Paper sx={{ marginBottom: 3 }}>
      <Container>
        <Typography variant="h3" id={componentDefinition.name}>
          {componentDefinition.name}
        </Typography>
        <Typography variant="caption">
          {componentDefinition.description} (
          <Link href={getDefinitionGithubLink('component')}>
            see definition here
          </Link>
          )
        </Typography>
        {usedShortcuts.length ? (
          <>
            <Typography key="grants">
              Grants access to frontend components:
            </Typography>
            <Container key="grants-elements">
              {usedShortcuts.map(shortcut => (
                <Typography key={shortcut.description || shortcut.title}>
                  {shortcut.description || shortcut.title}{' '}
                  {shortcut.type
                    .replace('rootUrl', 'page')
                    .replace('rootPage', 'page')[0]
                    .toUpperCase()}
                  {shortcut.type
                    .replace('rootUrl', 'page')
                    .replace('rootPage', 'page')
                    .slice(1)}
                </Typography>
              ))}
            </Container>
          </>
        ) : null}
        {matchingUrlDefinitions.length ? (
          <>
            <Typography>Grants access to backend endpoints:</Typography>
            <Container>
              {matchingUrlDefinitions.map(urlDefinition => (
                <Typography key={urlDefinition.baseName} variant="body2">
                  {urlDefinition.methods.join(', ')}{' '}
                  {explainUrlRegex(urlDefinition)}
                </Typography>
              ))}
            </Container>
          </>
        ) : null}
      </Container>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Granted to</TableCell>
            <TableCell>Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissionGroupMapping.all.permissions.includes(
            componentDefinition.name,
          ) ? (
            <TableRow key="all">
              <TableCell sx={cellCSS}>
                {React.createElement(
                  app.getSystemIcon(Icons.GROUP) as IconComponent,
                )}
                &nbsp;<Typography>Everyone</Typography>
              </TableCell>
              <TableCell>
                <Link href={getDefinitionGithubLink('permissionGroups')}>
                  Indirectly through permission group "all"
                  <br />({permissionGroupMapping.all.description})
                </Link>
              </TableCell>
            </TableRow>
          ) : null}
          {UserEmailGrantedPermissions.map(userGranted => {
            const directGranting = isGrantedDirectly(userGranted);
            const indirectGranting = getGroupsThroughGranted(userGranted);

            if (!directGranting && !indirectGranting.length) {
              return null;
            }

            return (
              <TableRow key={userGranted.email}>
                <TableCell sx={cellCSS}>
                  {employees[userGranted.email] ? (
                    [
                      <AvatarContact
                        avatarIconSize={20}
                        employee={employees[userGranted.email]}
                      />,
                      <div>
                        <Typography>
                          {employees[userGranted.email].name}
                        </Typography>
                      </div>,
                    ]
                  ) : (
                    <>
                      {React.createElement(
                        app.getSystemIcon(Icons.PERSON) as IconComponent,
                      )}
                      &nbsp;<Typography>{userGranted.email}</Typography>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {directGranting ? (
                    <>
                      {React.createElement(
                        app.getSystemIcon(Icons.WARNING) as IconComponent,
                      )}
                      &nbsp;
                      <Link
                        key="direct"
                        variant="body1"
                        href={getDefinitionGithubLink('users')}
                      >
                        Directly
                      </Link>
                    </>
                  ) : null}
                  {indirectGranting.map(permissionGroupName => (
                    <Link
                      key={permissionGroupName}
                      href={getDefinitionGithubLink('permissionGroups')}
                    >
                      Indirectly through permission group "{permissionGroupName}
                      "<br />(
                      {permissionGroupMapping[permissionGroupName].description})
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
          {GithubGroupsGrantedPermissions.map(githubGroupGranted => {
            const directGranting = isGrantedDirectly(githubGroupGranted);
            const indirectGranting =
              getGroupsThroughGranted(githubGroupGranted);

            if (!directGranting && !indirectGranting.length) {
              return null;
            }

            return (
              <TableRow key={githubGroupGranted.groupName}>
                <TableCell sx={cellCSS}>
                  {React.createElement(
                    app.getSystemIcon(Icons.GROUP) as IconComponent,
                  )}
                  &nbsp;
                  <Link
                    variant="body1"
                    href={`/catalog/default/group/${githubGroupGranted.groupName}`}
                  >
                    {githubGroupGranted.groupName}
                  </Link>
                </TableCell>
                <TableCell>
                  {directGranting ? (
                    <Link
                      key="direct"
                      variant="body1"
                      href={getDefinitionGithubLink('githubGroups')}
                    >
                      Directly
                    </Link>
                  ) : null}
                  {indirectGranting.map(permissionGroupName => (
                    <Link
                      key={permissionGroupName}
                      href={getDefinitionGithubLink('permissionGroups')}
                    >
                      Indirectly through permission group "{permissionGroupName}
                      "<br />(
                      {permissionGroupMapping[permissionGroupName].description})
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
