import useAsync from 'react-use/lib/useAsync';
import React from 'react';
import { Progress } from '@backstage/core-components';
import { CommandShortcutInput, ShortcutComponentProps } from '../types';
import { FunctionReturningPromise } from 'react-use/lib/misc/types';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  AnyResultType,
  Icons,
  IconValue,
  Keys,
  Result,
  SearchField,
} from '@internal/plugin-sl-assets';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type ResultAction = {
  action: (result: Result, resetFunction: () => void) => void;
  icon: IconValue;
  help: string;
};

type Props = ShortcutComponentProps & {
  request: FunctionReturningPromise;
  resultActions?: ResultAction[];
  hideResultsFound?: boolean;
};

export const BaseCommandShortcut = ({
  request,
  resultActions,
  hideResultsFound,
  onFinish,
  parentRef,
  inputs,
  setUserActivity,
}: Props) => {
  const app = useApp();
  const getInputDefaultValue = (input: CommandShortcutInput) => {
    switch (input.type) {
      case 'bool':
        return false;
      case 'int':
        return 0;
      default:
        return '';
    }
  };

  const defaultInputValues = Object.fromEntries(
    inputs.map(input => [input.key, getInputDefaultValue(input)]),
  );

  // For commands with only optional arguments, you should wait for user confirmation first
  const [lastTouchedAt, setLastTouchedAt] = React.useState<Date | null>(
    !inputs.length ? new Date() : null,
  );
  const [inputValue, setInputValue] = React.useState(defaultInputValues);
  const [currentInputValue, setCurrentInputValue] =
    React.useState(defaultInputValues);
  const [inputRefs, _setInputRefs] = React.useState<
    React.Ref<HTMLInputElement>[]
  >(inputs.map(_ => React.createRef()));
  const [selectedInputIndex, setSelectedInputIndex] = React.useState<number>(0);

  const { value, loading, error } = useAsync(() => {
    if (
      !lastTouchedAt ||
      inputs.some(input => !inputValue[input.key] && !input.optional)
    ) {
      return Promise.resolve({});
    }

    return request(inputValue);
  }, [inputValue, lastTouchedAt]);

  const data: AnyResultType = value as AnyResultType;

  React.useEffect(() => {
    if (inputs.length) {
      // Bring the search bar into focus when opening the dialog
      if (selectedInputIndex !== inputs.length) {
        // @ts-ignore TS2339: Property 'current' does not exist on type '((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement>'
        inputRefs[selectedInputIndex]?.current?.focus();
      } else if (selectedInputIndex === inputs.length) {
        // @ts-ignore TS2339
        parentRef?.current?.focus();
      }
    }
  }, [parentRef, inputs, selectedInputIndex, inputRefs]);

  React.useEffect(() => {
    if (!lastTouchedAt) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    switch (data?.type) {
      case 'redirect':
        // Hack to open links from lucky searches
        if (queryParams.get('lucky')) {
          window.location.href = data.url;
          break;
        }
        // https://stackoverflow.com/questions/76944918/should-not-already-be-working-on-window-open-in-simple-react-app
        setTimeout(() => {
          window.open(data.url);
          setLastTouchedAt(null);
        }, 0);
        if (parentRef === null || !inputs.length) onFinish?.();
        break;
      case 'redirects':
        // https://stackoverflow.com/questions/76944918/should-not-already-be-working-on-window-open-in-simple-react-app
        setTimeout(() => {
          data.urls.map(url => window.open(url));
          setLastTouchedAt(null);
        }, 0);
        if (parentRef === null || !inputs.length) onFinish?.();
        break;
      default:
        break;
    }
  }, [inputs.length, parentRef, lastTouchedAt, onFinish, data]);

  const keyPressHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      let newSelectedInputIndex = selectedInputIndex;
      setUserActivity?.();
      switch (event.key) {
        case Keys.ARROW_DOWN:
          newSelectedInputIndex = (selectedInputIndex + 1) % inputs.length;
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ARROW_UP:
          newSelectedInputIndex =
            (selectedInputIndex + inputs.length) % (inputs.length + 1);
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.TAB:
          newSelectedInputIndex = Math.max(
            (selectedInputIndex + 1) % (inputs.length + 1),
            inputs.length ? 1 : 0,
          );
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ENTER:
          setInputValue(currentInputValue);
          setLastTouchedAt(new Date());
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ESCAPE:
          event.preventDefault();
          event.stopPropagation();
          onFinish?.();
          break;
        default:
          break;
      }
      if (newSelectedInputIndex !== selectedInputIndex) {
        setSelectedInputIndex(newSelectedInputIndex);
      }
    },
    [
      setUserActivity,
      onFinish,
      currentInputValue,
      inputs.length,
      selectedInputIndex,
    ],
  );

  const renderOutput = () => {
    if (loading) {
      return <Progress />;
    } else if (error) {
      return (
        <Snackbar open={(error as Error | undefined) !== undefined}>
          <Alert severity="error">{error.message}</Alert>
        </Snackbar>
      );
    }
    if (data.type === 'results') {
      const userReadableValues = Object.entries(inputValue)
        .filter(([_key, valueOfKey], index: number) => {
          switch (inputs[index].type) {
            case 'bool':
              return valueOfKey;
            default:
              return (valueOfKey as string).length;
          }
        })
        .map(([key, valueOfKey]) => `${key} = "${valueOfKey}"`)
        .join(', ');
      return (
        <>
          {!hideResultsFound ? (
            <Typography>
              Found {data.results.length}{' '}
              {data.results.length !== 1 ? 'results' : 'result'}
              {userReadableValues.length ? ` for: ${userReadableValues}` : ''}
            </Typography>
          ) : null}
          <List dense>
            {data.results.map((result: Result, index: number) => (
              <ListItem
                divider
                style={result.url ? { cursor: 'pointer' } : {}}
                key={result.title || index}
                onClick={() => (result.url ? window.open(result.url) : null)}
              >
                <ListItemAvatar>
                  <Avatar>
                    {result.icon.startsWith('http') ? (
                      <img src={result.icon} alt="img" />
                    ) : (
                      React.createElement(
                        app.getSystemIcon(result.icon) ||
                          (app.getSystemIcon(Icons.QUESTION) as IconComponent),
                      )
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<b>{result.title}</b>}
                  secondary={result.subtitle}
                />
                {resultActions?.length || result.actions?.length ? (
                  <ListItemSecondaryAction>
                    <>
                      {(result.actions || []).map(
                        (action, actionIndex: number) => (
                          <Tooltip title={action.help}>
                            <IconButton
                              key={`result.action-${actionIndex}`}
                              onClick={() => window.open(action.url)}
                            >
                              {React.createElement(
                                app.getSystemIcon(action.icon) ||
                                  (app.getSystemIcon(
                                    Icons.QUESTION,
                                  ) as IconComponent),
                              )}
                            </IconButton>
                          </Tooltip>
                        ),
                      )}
                      {(resultActions || []).map(
                        (resultAction, resultActionIndex: number) => (
                          <Tooltip title={resultAction.help}>
                            <IconButton
                              key={`resultAction-${resultActionIndex}`}
                              onClick={() => {
                                resultAction.action(result, () => {
                                  setCurrentInputValue(defaultInputValues);
                                  setInputValue(defaultInputValues);
                                });
                              }}
                            >
                              {React.createElement(
                                app.getSystemIcon(resultAction.icon) ||
                                  (app.getSystemIcon(
                                    Icons.QUESTION,
                                  ) as IconComponent),
                              )}
                            </IconButton>
                          </Tooltip>
                        ),
                      )}
                    </>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            ))}
          </List>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {inputs.map((currentInput, index) => {
        if (currentInput.type === 'bool') {
          return (
            <>
              <FormControlLabel
                label={currentInput.hint}
                control={
                  <Checkbox
                    checked={currentInputValue[currentInput.key] as boolean}
                    onChange={() =>
                      setCurrentInputValue(prevInputValue => ({
                        ...prevInputValue,
                        [currentInput.key]: !prevInputValue[currentInput.key],
                      }))
                    }
                  />
                }
              />
              <br />
            </>
          );
        }

        return (
          <>
            {index ? <div style={{ height: '10px' }} /> : null}
            <SearchField
              key={currentInput.key}
              inputRef={inputRefs[index]}
              onFocus={() => setSelectedInputIndex(index)}
              onChange={e => {
                setCurrentInputValue(prevInputValue => ({
                  ...prevInputValue,
                  [currentInput.key]: e.target.value,
                }));
              }}
              value={currentInputValue[currentInput.key] as string}
              placeholder={`${currentInput.hint}${
                currentInput.optional ? ' (optional)' : ''
              }`}
              keyPressHandler={keyPressHandler}
            />
          </>
        );
      })}
      {inputs.length ? (
        <Button
          style={{
            borderRadius: '10px',
            marginTop: '15px',
            backgroundColor: 'rgba(128, 128, 128, 0.2)',
            backdropFilter: 'blur(30px)',
            color: 'white',
          }}
          variant="contained"
          onClick={() => {
            setInputValue(currentInputValue);
            setLastTouchedAt(new Date());
          }}
        >
          {React.createElement(
            app.getSystemIcon(Icons.TERMINAL) as IconComponent,
          )}
          Run (Enter)
        </Button>
      ) : null}
      {renderOutput()}
    </>
  );
};
