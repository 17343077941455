import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Icons, Keys } from '@internal/plugin-sl-assets';
import { DiffView } from './diffView';
import { DiffStatesOrder } from './constants';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  jobId: string;
  diffViewEnabled: boolean;
  setDiffViewEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  currentDiffIndexFocus: number;
  currentDiffStateIndex: number;
  setCurrentDiffIndexFocus: React.Dispatch<React.SetStateAction<number>>;
  setCurrentDiffStateIndex: React.Dispatch<React.SetStateAction<number>>;
  diffPaths: string[];
};

export const DiffViewDialog = ({
  jobId,
  currentDiffIndexFocus,
  setCurrentDiffIndexFocus,
  setCurrentDiffStateIndex,
  diffPaths,
  diffViewEnabled,
  setDiffViewEnabled,
  currentDiffStateIndex,
}: Props) => {
  const app = useApp();
  const diffViewKeyPressHandler = React.useCallback(
    (event: {
      key: any;
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => {
      switch (event.key) {
        case Keys.ARROW_LEFT:
          setCurrentDiffIndexFocus(
            currentDiffIndex =>
              (currentDiffIndex + diffPaths.length - 1) % diffPaths.length,
          );
          setCurrentDiffStateIndex(0);
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ARROW_RIGHT:
          setCurrentDiffIndexFocus(
            currentDiffIndex => (currentDiffIndex + 1) % diffPaths.length,
          );
          setCurrentDiffStateIndex(0);
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ARROW_UP:
          setCurrentDiffStateIndex(
            prevIndex => (prevIndex + 1) % DiffStatesOrder.length,
          );
          event.preventDefault();
          event.stopPropagation();
          break;
        case Keys.ARROW_DOWN:
          setCurrentDiffStateIndex(
            prevIndex =>
              (prevIndex + DiffStatesOrder.length - 1) % DiffStatesOrder.length,
          );
          event.preventDefault();
          event.stopPropagation();
          break;
        default:
          break;
      }
    },
    [diffPaths.length, setCurrentDiffStateIndex, setCurrentDiffIndexFocus],
  );

  return (
    <Dialog
      open={diffViewEnabled}
      onClose={() => {
        setDiffViewEnabled(false);
      }}
      fullScreen
      maxWidth="xl"
      onKeyDown={diffViewKeyPressHandler}
      disableEscapeKeyDown
    >
      <DialogTitle>
        Diff #{currentDiffIndexFocus + 1}{' '}
        <Typography variant="caption">
          ({diffPaths[currentDiffIndexFocus]})
        </Typography>
        <Tooltip
          style={{ position: 'absolute', right: 58, top: 8, color: 'white' }}
          title={
            <Typography variant="body1">
              View Diffs. To navigate only using the keyboard: <br />-{' '}
              <i>Press</i> <i>D</i> or <i>ESC</i> to close the diff view
              <br />- Use the <i>LEFT</i> and <i>RIGHT</i> arrows to{' '}
              <b>navigate</b> through the diffs and the <i>UP</i> and{' '}
              <i>DOWN</i> arrows to switch between <b>diff</b>, <b>actual</b>{' '}
              and <b>expected</b> views.
              <br />
            </Typography>
          }
        >
          <IconButton>
            {React.createElement(
              app.getSystemIcon(Icons.QUESTION) as IconComponent,
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
          onClick={() => {
            setDiffViewEnabled(false);
          }}
        >
          {React.createElement(app.getSystemIcon(Icons.CLOSE) as IconComponent)}
        </IconButton>
        <br />
        <Typography>
          {DiffStatesOrder[currentDiffStateIndex].toUpperCase()}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DiffView
          jobId={jobId as string}
          diffPaths={diffPaths}
          currentDiffIndexFocus={currentDiffIndexFocus}
          currentDiffStateIndex={currentDiffStateIndex}
        />
      </DialogContent>
    </Dialog>
  );
};
