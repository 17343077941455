import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    'enable-background': 'new 0 0 474.18 335.28',
  },
  st0: {
    fill: '#333333',
  },
  st1: {
    fill: '#59BFC7',
  },
});

export const SectorLabsIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 474.18 335.28"
      className={classes.svg}
      xmlSpace="preserve"
    >
      <g>
        <path
          className={classes.st0}
          d="M154.94,238.97c0.33,0,0.5,0.17,0.5,0.5v9.08c0,0.61,0.05,1.11,0.15,1.51c0.1,0.4,0.26,0.72,0.49,0.96
		c0.23,0.24,0.54,0.4,0.92,0.5c0.39,0.1,0.88,0.15,1.47,0.15h9.22c0.33,0,0.5,0.17,0.5,0.5v2.23c0,0.33-0.17,0.5-0.5,0.5h-9.36
		c-1.15,0-2.13-0.11-2.93-0.32c-0.8-0.21-1.46-0.56-1.97-1.04c-0.51-0.48-0.88-1.1-1.12-1.87c-0.24-0.77-0.35-1.7-0.35-2.79v-9.42
		c0-0.33,0.17-0.5,0.52-0.5H154.94z"
        />
        <path
          className={classes.st0}
          d="M205.13,249.03h7.83l-3.62-6.67c-0.06-0.11-0.15-0.16-0.25-0.16h-0.14c-0.11,0-0.19,0.05-0.25,0.16
		L205.13,249.03z M209.55,238.97c0.58,0,1.06,0.12,1.46,0.35c0.4,0.24,0.78,0.7,1.16,1.38l7.53,13.68c0.08,0.15,0.1,0.28,0.06,0.38
		c-0.04,0.1-0.15,0.15-0.33,0.15h-2.89c-0.26,0-0.43-0.1-0.52-0.3l-1.62-2.93h-10.7l-1.57,2.93c-0.11,0.2-0.28,0.3-0.52,0.3h-2.96
		c-0.2,0-0.32-0.05-0.35-0.15c-0.04-0.1-0.02-0.22,0.06-0.38l7.47-13.68c0.38-0.68,0.75-1.14,1.12-1.38
		c0.36-0.24,0.79-0.35,1.28-0.35H209.55z"
        />
        <path
          className={classes.st0}
          d="M254.33,248.07v3.39c0,0.2,0.1,0.3,0.3,0.3h9.33c0.61,0,1.04-0.11,1.3-0.33c0.26-0.22,0.39-0.57,0.39-1.06
		v-0.87c0-0.47-0.14-0.83-0.42-1.07c-0.28-0.24-0.8-0.37-1.56-0.37H254.33z M254.33,245.59h9.33c0.76,0,1.27-0.11,1.55-0.34
		c0.27-0.23,0.41-0.56,0.41-1v-0.77c0-0.47-0.13-0.82-0.4-1.04c-0.27-0.22-0.7-0.33-1.29-0.33h-9.31c-0.2,0-0.3,0.1-0.3,0.29V245.59
		z M264.64,238.97c1.56,0,2.67,0.31,3.33,0.93c0.66,0.62,0.99,1.56,0.99,2.82v1.09c0,1.27-0.49,2.24-1.46,2.89
		c1.05,0.61,1.57,1.59,1.57,2.94v1.32c0,0.61-0.07,1.15-0.2,1.64c-0.14,0.49-0.36,0.9-0.67,1.24c-0.31,0.34-0.73,0.6-1.24,0.79
		c-0.52,0.18-1.15,0.27-1.91,0.27h-13.52c-0.46,0-0.68-0.23-0.68-0.68v-14.57c0-0.46,0.23-0.68,0.68-0.68H264.64z"
        />
        <path
          className={classes.st0}
          d="M316.8,238.97c0.33,0,0.5,0.17,0.5,0.5v2.16c0,0.33-0.17,0.5-0.5,0.5h-11.4c-0.44,0-0.75,0.11-0.93,0.34
		c-0.18,0.23-0.27,0.54-0.27,0.93v0.77c0,0.85,0.39,1.28,1.18,1.28h8.54c1.32,0,2.31,0.34,2.98,1.01c0.67,0.68,1,1.64,1,2.9v1.82
		c0,1.11-0.33,2-0.98,2.69c-0.65,0.68-1.67,1.02-3.05,1.02h-12.61c-0.35,0-0.52-0.17-0.52-0.5v-2.16c0-0.33,0.17-0.5,0.52-0.5h11.95
		c0.44,0,0.75-0.11,0.93-0.34c0.18-0.23,0.27-0.54,0.27-0.93v-0.93c0-0.85-0.39-1.28-1.18-1.28h-8.54c-1.32,0-2.31-0.34-2.98-1.01
		c-0.67-0.68-1-1.64-1-2.9v-1.66c0-1.11,0.33-2,0.98-2.69c0.65-0.68,1.67-1.02,3.05-1.02H316.8z"
        />
        <path
          className={classes.st0}
          d="M140.92,196.16c0.66,0,0.99,0.33,0.99,0.99v4.3c0,0.66-0.33,0.99-0.99,0.99h-22.66
		c-0.87,0-1.49,0.23-1.85,0.68c-0.36,0.45-0.54,1.07-0.54,1.85v1.54c0,1.69,0.78,2.53,2.35,2.53h16.96c2.62,0,4.6,0.67,5.92,2.01
		c1.33,1.34,1.99,3.26,1.99,5.77v3.62c0,2.2-0.65,3.98-1.94,5.34c-1.3,1.36-3.32,2.03-6.06,2.03h-25.06c-0.69,0-1.04-0.33-1.04-0.99
		v-4.3c0-0.66,0.35-0.99,1.04-0.99h23.75c0.88,0,1.49-0.23,1.85-0.68c0.36-0.45,0.54-1.07,0.54-1.85v-1.85
		c0-1.69-0.79-2.53-2.35-2.53h-16.96c-2.62,0-4.6-0.67-5.92-2.01c-1.33-1.34-1.99-3.26-1.99-5.77v-3.3c0-2.2,0.65-3.98,1.94-5.34
		c1.3-1.36,3.32-2.04,6.06-2.04H140.92z"
        />
        <path
          className={classes.st0}
          d="M227.53,196.16c0.66,0,0.99,0.33,0.99,0.99v4.43c0,0.69-0.33,1.04-0.99,1.04h-20.13
		c-1.17,0-2.15,0.1-2.92,0.29c-0.77,0.2-1.38,0.53-1.83,0.99c-0.45,0.47-0.78,1.09-0.97,1.88c-0.2,0.78-0.3,1.78-0.3,2.98v6.42
		c0,1.21,0.1,2.21,0.3,3.01c0.2,0.8,0.52,1.43,0.97,1.9c0.45,0.47,1.07,0.8,1.83,1c0.77,0.19,1.74,0.29,2.92,0.29h20.13
		c0.66,0,0.99,0.33,0.99,0.99v4.43c0,0.66-0.33,0.99-0.99,0.99h-20.4c-2.29,0-4.24-0.21-5.83-0.63c-1.6-0.42-2.9-1.11-3.91-2.06
		c-1.01-0.95-1.75-2.19-2.22-3.71c-0.47-1.52-0.7-3.37-0.7-5.54v-7.78c0-2.17,0.23-4.01,0.7-5.52c0.47-1.51,1.21-2.74,2.22-3.69
		c1.01-0.95,2.31-1.64,3.91-2.08c1.6-0.44,3.54-0.65,5.83-0.65H227.53z"
        />
        <path
          className={classes.st0}
          d="M271.86,196.16c0.7,0,1.04,0.33,1.04,0.99v4.43c0,0.69-0.35,1.04-1.04,1.04h-14.2v24.2
		c0,0.66-0.33,0.99-0.99,0.99h-4.93c-0.66,0-0.99-0.33-0.99-0.99v-24.2h-14.2c-0.69,0-1.04-0.35-1.04-1.04v-4.43
		c0-0.66,0.35-0.99,1.04-0.99H271.86z"
        />
        <path
          className={classes.st0}
          d="M286.65,215.39c0,1.17,0.1,2.16,0.29,2.96c0.2,0.8,0.52,1.43,0.97,1.9c0.45,0.47,1.06,0.8,1.83,1
		c0.77,0.2,1.74,0.29,2.92,0.29h13.43c1.18,0,2.15-0.1,2.92-0.29c0.77-0.2,1.38-0.53,1.83-1c0.45-0.47,0.78-1.1,0.97-1.9
		c0.19-0.8,0.29-1.79,0.29-2.96v-6.79c0-1.17-0.1-2.16-0.29-2.96c-0.2-0.8-0.52-1.43-0.97-1.9c-0.45-0.47-1.06-0.8-1.83-1
		c-0.77-0.19-1.74-0.29-2.92-0.29h-13.43c-1.18,0-2.15,0.1-2.92,0.29c-0.77,0.2-1.38,0.53-1.83,1c-0.45,0.47-0.78,1.1-0.97,1.9
		c-0.2,0.8-0.29,1.79-0.29,2.96V215.39z M306.37,196.16c2.29,0,4.24,0.22,5.86,0.65c1.61,0.44,2.92,1.13,3.93,2.08
		c1.01,0.95,1.74,2.18,2.2,3.69c0.45,1.51,0.68,3.35,0.68,5.52v7.78c0,2.17-0.23,4.02-0.68,5.54c-0.45,1.52-1.18,2.76-2.2,3.71
		c-1.01,0.95-2.32,1.63-3.93,2.06c-1.61,0.42-3.57,0.63-5.86,0.63h-13.98c-2.29,0-4.24-0.21-5.83-0.63c-1.6-0.42-2.9-1.11-3.91-2.06
		c-1.01-0.95-1.75-2.19-2.22-3.71s-0.7-3.37-0.7-5.54v-7.78c0-2.17,0.23-4.01,0.7-5.52c0.47-1.51,1.2-2.74,2.22-3.69
		c1.01-0.95,2.31-1.64,3.91-2.08c1.6-0.44,3.54-0.65,5.83-0.65H306.37z"
        />
        <path
          className={classes.st0}
          d="M335.63,212.31h17.32c1.54,0,2.62-0.33,3.26-0.99c0.63-0.66,0.95-1.68,0.95-3.03v-1.9
		c0-1.36-0.32-2.37-0.95-3.03c-0.64-0.66-1.72-0.99-3.26-0.99h-16.74c-0.39,0-0.59,0.18-0.59,0.54V212.31z M354.35,196.16
		c3.47,0,5.95,0.78,7.46,2.35c1.51,1.57,2.26,3.89,2.26,6.97v2.58c0,2.59-0.53,4.64-1.58,6.15c-1.06,1.51-2.77,2.47-5.15,2.9
		l7.73,9.59c0.15,0.15,0.2,0.38,0.14,0.68c-0.06,0.3-0.3,0.45-0.72,0.45h-5.83c-0.39,0-0.66-0.05-0.82-0.13
		c-0.15-0.09-0.3-0.24-0.45-0.45l-7.15-9.41h-14.61v9c0,0.66-0.33,0.99-0.99,0.99h-4.89c-0.69,0-1.04-0.33-1.04-0.99v-29.31
		c0-0.9,0.45-1.36,1.36-1.36H354.35z"
        />
        <path
          className={classes.st0}
          d="M152.71,221.54c0.47,1.46,1.18,2.67,2.17,3.6c1.01,0.95,2.31,1.64,3.91,2.06c1.6,0.42,3.54,0.63,5.83,0.63
		h19.9c0.69,0,1.04-0.33,1.04-0.99v-4.3c0-0.66-0.35-0.99-1.04-0.99h-6.21h-13.55H152.71z"
        />
        <path
          className={classes.st0}
          d="M178.31,202.45h6.21c0.69,0,1.04-0.33,1.04-0.99v-4.3c0-0.66-0.35-0.99-1.04-0.99h-19.9
		c-2.29,0-4.24,0.22-5.83,0.65c-1.6,0.44-2.9,1.13-3.91,2.08c-0.98,0.92-1.69,2.11-2.16,3.55h12.04H178.31z"
        />
        <path
          className={classes.st1}
          d="M151.95,209.06v5.56h6.79h19.57h5.99c0.66,0,0.99-0.33,0.99-0.99v-3.57c0-0.66-0.33-0.99-0.99-0.99h-5.99
		h-19.57H151.95z"
        />
        <path
          className={classes.st0}
          d="M222.04,135.14c0.2-0.56,0.31-1.15,0.31-1.76V111.2c0-1.31,0.39-2.59,1.1-3.67c0.24-0.37,0.53-0.72,0.85-1.04
		l26.01-26.11l-51.48,0c-2.39,0-4.33,1.94-4.33,4.33l0.08,78.58l26.5-26.5C221.51,136.3,221.83,135.74,222.04,135.14"
        />
        <path
          className={classes.st0}
          d="M253.26,110.05c-0.2,0.56-0.31,1.15-0.31,1.76v22.18c0,1.32-0.39,2.59-1.1,3.67
		c-0.24,0.37-0.53,0.72-0.85,1.04l-26.75,26.85l51.09,0c2.39,0,4.33-1.94,4.33-4.33l-0.1-78.17l-25.35,25.35
		C253.8,108.89,253.48,109.45,253.26,110.05"
        />
        <path
          className={classes.st1}
          d="M225.92,132l51.62-51.62H257.4c-1.3,0-2.55,0.52-3.48,1.44l-26.07,26.07c-0.32,0.32-0.6,0.67-0.85,1.04
		c-0.71,1.08-1.1,2.35-1.1,3.67L225.92,132z"
        />
        <path
          className={classes.st1}
          d="M222.2,140.57l-24.83,24.83l20.24,0c1.3,0,2.55-0.52,3.48-1.44l26.5-26.5c1.25-1.25,1.95-2.94,1.95-4.71
		v-17.28c0-0.91,0-2.17,0-2.17l-27.05,26.96C222.39,140.37,222.3,140.47,222.2,140.57"
        />
      </g>
    </SvgIcon>
  );
};
