import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import {
  Issue,
  IssuesExtraInformation,
  IssuesExtraInformationChanges,
  IssueStateOrder,
  IssueTypeOrder,
  IssueTypes,
  ReleaseNotesData,
} from './types';
import { ReleaseNotesSection } from './section';

type Props = {
  value: ReleaseNotesData;
  startDate: Dayjs;
};

const portalPicker = (issue: Issue, applications: string[]): string[] => {
  if (issue.labels.includes('all countries')) {
    return ['all'];
  }
  return Array.from(
    new Set([
      ...(issue.labels.some(label =>
        applications
          .map(application => application.replace('_', '-'))
          .includes(label),
      )
        ? issue.labels.filter(label =>
            applications
              .map(application => application.replace('_', '-'))
              .includes(label),
          )
        : ['all']),
    ]),
  );
};

const issueSortingFunc = (issue1: Issue, issue2: Issue) => {
  const latestI1release = Object.keys(issue1.releases).sort((r1, r2) =>
    r1 < r2 ? 1 : -1,
  )[0];
  const latestI2release = Object.keys(issue2.releases).sort((r1, r2) =>
    r1 < r2 ? 1 : -1,
  )[0];

  if (latestI1release < latestI2release) {
    return 1;
  } else if (latestI1release > latestI2release) {
    return -1;
  }

  if (
    IssueTypeOrder.indexOf(issue1.type) < IssueTypeOrder.indexOf(issue2.type)
  ) {
    return 1;
  } else if (
    IssueTypeOrder.indexOf(issue1.type) > IssueTypeOrder.indexOf(issue2.type)
  ) {
    return -1;
  }

  if (
    IssueStateOrder.indexOf(issue1.state) <
    IssueStateOrder.indexOf(issue2.state)
  ) {
    return 1;
  } else if (
    IssueStateOrder.indexOf(issue1.state) >
    IssueStateOrder.indexOf(issue2.state)
  ) {
    return -1;
  }

  return 0;
};

export const ReleaseNotesContent = ({ value, startDate }: Props) => {
  const [generateDisabled, setGenerateDisabled] =
    React.useState<boolean>(false);
  const [issuesData, setIssuesData] = React.useState<
    Record<string, IssuesExtraInformation>
  >(
    value.issues.reduce(
      (alreadyAddressed, issue) => ({
        ...alreadyAddressed,
        [issue.pivotalId]: {
          portals: portalPicker(issue, value.applications),
          userFacingReleaseNotes: issue.userFacingReleaseNotes || '',
          checked: false,
        },
      }),
      {},
    ),
  );

  const onHandleIssueChanges = React.useCallback(
    (pivotalId: number, changes: IssuesExtraInformationChanges) =>
      setIssuesData(prevValue => ({
        ...prevValue,
        [pivotalId]: { ...prevValue[pivotalId], ...changes },
      })),
    [setIssuesData],
  );

  const generateCSVReleaseNotes = React.useCallback(() => {
    const csvText = value.issues
      .filter(issue => issuesData[issue.pivotalId.toString()].checked)
      .sort(issueSortingFunc)
      .map(
        issue =>
          `${dayjs(
            Object.entries(issue.releases)
              .sort((r1, r2) => (r1[0] < r2[0] ? -1 : 1))[0][1]
              .merged_at.slice(0, 10),
          ).format('DD/MM/YYYY')};${issuesData[
            issue.pivotalId.toString()
          ].portals.join(', ')};${issue.type};"${
            issuesData[issue.pivotalId].userFacingReleaseNotes
          }"`,
      )
      .join('\n');
    const csv = new Blob([csvText], {
      type: 'text/csv',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csv);
    link.download = `release-notes-${startDate.format(
      'DD-MM-YYYY',
    )}.csv`.toLowerCase();
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [startDate, value.issues, issuesData]);

  const [issuesByProject, setIssuesByProject] = React.useState<
    Record<string, Issue[]>
  >({});

  React.useEffect(() => {
    setIssuesByProject(
      Object.fromEntries(
        Object.entries(
          value.issues.reduce<Record<string, Issue[]>>(
            (currentCategorization, issue) => ({
              ...currentCategorization,
              [issue.projectName]: [
                ...(currentCategorization[issue.projectName] || []),
                {
                  ...issue,
                },
              ],
            }),
            {},
          ),
        ).map(([projectName, issues]) => {
          const sortedIssues = issues.sort(issueSortingFunc);
          return [projectName, sortedIssues];
        }),
      ),
    );
  }, [value.issues]);

  const selectedIssues = value.issues
    .filter(issue => issuesData[issue.pivotalId].checked)
    .sort(issueSortingFunc);
  const hasMissingReleaseNotes = !selectedIssues.every(
    issue => issuesData[issue.pivotalId.toString()].userFacingReleaseNotes,
  );
  const hasMissingPortal = !selectedIssues.every(
    issue => issuesData[issue.pivotalId.toString()].portals.length,
  );
  const isChoreSelected = selectedIssues.some(
    issue => issue.type === IssueTypes.CHORE,
  );

  return (
    <>
      {Object.entries(issuesByProject).map(([projectName, issues]) => (
        <ReleaseNotesSection
          key={projectName}
          portals={value.applications}
          sectionTitle={projectName}
          issues={issues}
          issuesData={issuesData}
          onHandleIssueData={onHandleIssueChanges}
          setGenerateDisabled={setGenerateDisabled}
        />
      ))}
      <ReleaseNotesSection
        sectionTitle="Highlighted"
        issues={selectedIssues}
        portals={value.applications}
        issuesData={issuesData}
        setGenerateDisabled={setGenerateDisabled}
        onHandleIssueData={onHandleIssueChanges}
        withMultiPortalSelect
      />
      <Paper
        sx={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          columnGap: 1,
        }}
      >
        <Button
          sx={{ padding: '12px' }}
          disabled={
            generateDisabled || !selectedIssues.length || hasMissingReleaseNotes
          }
          variant="outlined"
          onClick={generateCSVReleaseNotes}
        >
          Generate CSV release notes
        </Button>
        {generateDisabled ? <CircularProgress size={36} /> : null}
        {!selectedIssues.length ? (
          <Alert variant="outlined" severity="warning">
            <Typography variant="caption">No issues selected</Typography>
          </Alert>
        ) : null}
        {hasMissingReleaseNotes ? (
          <Alert variant="outlined" severity="warning">
            <Typography variant="caption">
              Some issues are missing RN
            </Typography>
          </Alert>
        ) : null}
        {hasMissingPortal ? (
          <Alert variant="outlined" severity="warning">
            <Typography variant="caption">
              Some issues do not have portals selected
            </Typography>
          </Alert>
        ) : null}
        {isChoreSelected ? (
          <Alert variant="outlined" severity="warning">
            <Typography variant="caption">Chore checked detected</Typography>
          </Alert>
        ) : null}
      </Paper>
    </>
  );
};
