import React from 'react';
import { ComponentPermissionExplanation } from './componentPermissionExplanation';
import {
  AllComponentPermissionNames,
  ComponentDefinition,
  ComponentDefinitions,
  ComponentPermissionName,
  PermissionGroupDefinition,
  PermissionGroupDefinitions,
  PermissionGroupName,
  UserEmailGrantedPermissions,
} from '@internal/plugin-sl-permissions';
import {
  SLShortcutsApi,
  SLShortcutsApiRef,
} from '@internal/plugin-sl-shortcuts';
import Container from '@mui/material/Container';
import { Header } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  Employee,
  handleResponse,
  Page,
  PageData,
} from '@internal/plugin-sl-assets';
import { AnyRootShortcut } from '@internal/backstage-plugin-sl-common';
import { SLPokedexApi, SLPokedexApiRef } from '@internal/plugin-sl-bots';

type Props = {
  shortcuts: AnyRootShortcut[];
  employees: Record<string, Employee>;
};

export const ComponentPermissionsExplanationContent = ({
  shortcuts,
  employees,
}: Props) => {
  const permissionGroupMapping: Record<
    PermissionGroupName,
    PermissionGroupDefinition
  > = PermissionGroupDefinitions.reduce(
    (
      mapping: Record<PermissionGroupName, PermissionGroupDefinition>,
      permissionGroup,
    ) => ({ ...mapping, [permissionGroup.name]: permissionGroup }),
    {} as Record<PermissionGroupName, PermissionGroupDefinition>,
  );
  const componentDefinitionMapping: Record<
    ComponentPermissionName,
    ComponentDefinition
  > = ComponentDefinitions.reduce(
    (
      mapping: Record<ComponentPermissionName, ComponentDefinition>,
      componentDefinition: ComponentDefinition,
    ) => ({ ...mapping, [componentDefinition.name]: componentDefinition }),
    {} as Record<ComponentPermissionName, ComponentDefinition>,
  );

  return (
    <>
      <Container sx={{ marginTop: 3 }}>
        {AllComponentPermissionNames.map(permissionName => (
          <ComponentPermissionExplanation
            key={permissionName}
            shortcuts={shortcuts}
            permissionGroupMapping={permissionGroupMapping}
            componentDefinition={componentDefinitionMapping[permissionName]}
            employees={employees}
          />
        ))}
      </Container>
    </>
  );
};

export const ComponentPermissionsExplanationPage = () => {
  const api = useApi<SLShortcutsApi>(SLShortcutsApiRef);
  const pokedexApi = useApi<SLPokedexApi>(SLPokedexApiRef);

  const data = useAsync(
    async () =>
      Promise.all([
        api.getShortcuts().then(handleResponse),
        pokedexApi
          .users({
            emails: UserEmailGrantedPermissions.map(
              userGranted => userGranted.email,
            ).join(','),
          })
          .then(handleResponse),
      ]),
    [api],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => (
      <ComponentPermissionsExplanationContent
        shortcuts={Object.values(pageData.value[0])}
        employees={Object.fromEntries(
          pageData.value[1].map((employee: Employee) => [
            employee.email,
            employee,
          ]),
        )}
      />
    ),
    [],
  );

  return (
    <Page
      pageData={data}
      header={() => (
        <Header
          title="Permissions"
          subtitle="Component permission grants and explanations"
        />
      )}
      content={renderPage}
    />
  );
};
