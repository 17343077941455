import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import React from 'react';
import { Header } from '@backstage/core-components';
import { SLReviewsApi, SLReviewsApiRef } from '../api';
import { handleResponse, Page, PageData } from '@internal/plugin-sl-assets';
import { PRList } from '../components';
import { PR } from '../types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

type Props = {
  prs: PR[];
};

const MyPRsContent = ({ prs }: Props) => (
  <Container sx={{ marginBottom: '15px' }}>
    <Paper>
      <Container>
        <PRList prs={prs} individualCodeowners />
      </Container>
    </Paper>
  </Container>
);

export const MyPRReviewsPage = () => {
  const api = useApi<SLReviewsApi>(SLReviewsApiRef);

  const assignationsOptions = useAsync(
    async () => api.myPrs().then(handleResponse),
    [],
  ) as PageData;

  const renderPage = React.useCallback(
    (pageData: PageData) => <MyPRsContent prs={pageData.value} />,
    [],
  );

  return (
    <Page
      pageData={assignationsOptions}
      header={() => <Header title="My PRs" />}
      content={renderPage}
    />
  );
};
