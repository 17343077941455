import { MappedRequestMethod } from './types';

export const RequestMethodMapping: MappedRequestMethod = {
  POST: 'create',
  GET: 'read',
  HEAD: 'read',
  PUT: 'update',
  PATCH: 'update',
  DELETE: 'delete',
};
