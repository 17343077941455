import { PermissionGroupDefinition } from './types';

export const PermissionGroupDefinitions: PermissionGroupDefinition[] = [
  {
    name: 'all',
    description: 'Permissions for everyone',
    permissions: [
      'sl.catalog.search',
      'sl.cheatsheet.view',
      'sl.announcements.read-only',
      'sl.oncall.public',
      'sl.bots.ping',
      'sl.bots.users',
      'sl.tools.github.commit-tracker',
      'sl.meta.permissions',
      'sl.release.qa.assignments.view',
      'sl.release.notes.data.view',
      'sl.release.view',
      'sl.release.version.view',
      'sl.argocd.resource-tree',
      'sl.qa.workflows.view',
      'sl.cli.catalog',
    ],
  },
  {
    name: 'sl.hr',
    description: 'Permissions for HR',
    permissions: ['sl.oncall.summary'],
  },
  {
    name: 'sl.devs',
    description: 'Developers from and in collaboration with SectorLabs',
    permissions: [
      'sl.oncall.shortcuts.admin',
      'qeta.create.question',
      'qeta.create.answer',
      'sl.qeta',
      'sl.cli.login',
      'sl.cli.shortcuts.login',
      'sl.reviews.pr',
      'sl.reviews.state-machines',
    ],
  },
  {
    name: 'sl.backstage.admin',
    description: 'Permissions for Backstage admins',
    permissions: ['sl.catalog.edit'],
  },
  {
    name: 'sl.release.wizard.maple',
    description: 'Permissions for Maple release wizards',
    permissions: [
      'sl.cli.release.slack.updates',
      'sl.release.deploy',
      'sl.release.qa.assignments.change',
    ],
  },
  {
    name: 'sl.qa.manager',
    description: 'Managers of QA resources',
    permissions: ['sl.release.qa.assignments.change'],
  },
];
