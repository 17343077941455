import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '28px',
    padding: '3px',
  },
  cls1: {
    fill: '#28b16d',
  },
});

export const BayutIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 15"
      className={classes.svg}
    >
      <path
        d="M11.26-.07a7.34 7.34 0 0 1 6.91 5.77 7.41 7.41 0 0 1-4.36 8.48 7.16 7.16 0 0 1-8-1.79c-.4-.45-.41-.45-.92-.15l-3.33 2c-.57.33-.81.27-1.14-.3l-.31-.55a.64.64 0 0 1 .27-1l3.43-2c.52-.31.51-.3.35-.86A7.4 7.4 0 0 1 8.87.33a6.52 6.52 0 0 1 1-.26c.47-.07.93-.07 1.39-.14zm5.66 7.43a5.89 5.89 0 0 0-5.75-5.95 5.88 5.88 0 0 0-5.88 5.75A5.88 5.88 0 0 0 11 13.27a5.87 5.87 0 0 0 5.92-5.91z"
        className={classes.cls1}
      />
      <path
        d="M14.91 8.06v1.88c0 .43-.18.61-.61.61h-1.44c-.34 0-.49-.14-.49-.48V8.56a1.29 1.29 0 0 0-1.09-1.25 1.26 1.26 0 0 0-1.39 1 3.3 3.3 0 0 0 0 .69v1.06c0 .35-.15.52-.51.52h-1.5a.54.54 0 0 1-.6-.58V6.26a.73.73 0 0 1 .4-.71q1.42-.8 2.81-1.65a1.05 1.05 0 0 1 1.21 0c.94.57 1.9 1.13 2.85 1.68a.62.62 0 0 1 .34.6v1.91z"
        className={classes.cls1}
      />
    </SvgIcon>
  );
};
