import {
  discoveryApiRef,
  IconComponent,
  identityApiRef,
  useApi,
  useApp,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';
import useAsync from 'react-use/lib/useAsync';
import { handleResponse, Icons, PageData } from '@internal/plugin-sl-assets';
import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

type Props = {
  bot: Bots;
  index: number;
};

export const BotPing = ({ bot, index }: Props) => {
  const app = useApp();
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const botsApi = new SLBotsApi(discoveryApi, identityApi, bot);

  const { value, loading, error } = useAsync(async () =>
    botsApi.ping().then(handleResponse),
  ) as PageData;

  const displayName = bot[0].toUpperCase() + bot.slice(1);

  return (
    <Paper
      sx={{
        textAlign: 'center',
        ...(index % 2 === 1 ? { marginLeft: 1 } : {}),
      }}
    >
      <Typography variant="body2">{displayName} bot status</Typography>
      <hr />
      {loading && <CircularProgress />}
      {error &&
        React.createElement(app.getSystemIcon(Icons.ERROR) as IconComponent, {
          fontSize: 'large',
        })}
      {!loading &&
        !error &&
        value.response === 'pong' &&
        React.createElement(
          app.getSystemIcon(Icons.CHECK_CIRCLE) as IconComponent,
          { fontSize: 'large' },
        )}
    </Paper>
  );
};
