import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';

export class SLPokedexApi extends SLBotsApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, Bots.POKEDEX);
  }
}

export const SLPokedexApiRef = createApiRef<SLPokedexApi>({
  id: 'plugin.sl-bots.pokedex-api',
});

export const usePokedexApi = () => {
  return useApi<SLPokedexApi>(SLPokedexApiRef);
};
