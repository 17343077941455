import React from 'react';
import {
  Issue,
  IssuesExtraInformation,
  IssuesExtraInformationChanges,
  IssueState,
  IssueType,
  ReleaseData,
} from './types';
import debounce from 'just-debounce';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Icons, NewTabLink } from '@internal/plugin-sl-assets';
import { MultiPortalSelect } from './multiPortalSelect';
import { IconComponent, useApp } from '@backstage/core-plugin-api';

type Props = {
  issue: Issue;
  portals: string[];
  issueData: IssuesExtraInformation;
  setGenerateDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIssueData: (
    pivotalId: number,
    changes: IssuesExtraInformationChanges,
  ) => void;
  withMultiPortalSelect?: boolean;
};

const releaseSortFunc = (
  r1: [string, ReleaseData],
  r2: [string, ReleaseData],
) => {
  const v1 = parseInt(r1[0].split('.')[1], 10);
  const v2 = parseInt(r2[0].split('.')[1], 10);

  if (v1 < v2) {
    return 1;
  } else if (v1 > v2) {
    return -1;
  }

  return 0;
};

export const IssueRow = ({
  issue,
  portals,
  issueData,
  onHandleIssueData,
  setGenerateDisabled,
  withMultiPortalSelect = false,
}: Props) => {
  const cellSx = { whiteSpace: 'nowrap', padding: '0 10px' };
  const app = useApp();
  const [text, setText] = React.useState<string>(
    issueData.userFacingReleaseNotes,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleIssueData = React.useCallback(
    debounce(userFacingText => {
      onHandleIssueData(issue.pivotalId, {
        userFacingReleaseNotes: userFacingText,
      });
      setGenerateDisabled(false);
    }, 1000),
    [onHandleIssueData],
  );

  React.useEffect(() => {
    setText(issueData.userFacingReleaseNotes);
  }, [setText, issueData.userFacingReleaseNotes]);

  return (
    <TableRow key={issue.pivotalId}>
      <TableCell sx={cellSx}>
        <NewTabLink
          href={`https://www.pivotaltracker.com/story/show/${issue.pivotalId}`}
        >
          {issue.pivotalId}
        </NewTabLink>
      </TableCell>
      <TableCell sx={cellSx}>
        {Object.entries(issue.releases)
          .sort(releaseSortFunc)
          .map(([releaseVersion, release]) => (
            <NewTabLink href={release.url}>
              {releaseVersion} ({release.merged_at.slice(8, 10)}/
              {release.merged_at.slice(5, 7)})<br />
            </NewTabLink>
          ))}
        {Object.keys(issue.old_releases).length ? (
          <Tooltip
            title={Object.entries(issue.old_releases)
              .sort(releaseSortFunc)
              .map(([releaseVersion, release]) => (
                <NewTabLink href={release.url}>
                  v{releaseVersion} ({release.merged_at.slice(8, 10)}/
                  {release.merged_at.slice(5, 7)})<br />
                </NewTabLink>
              ))}
          >
            <Typography variant="caption">🕘 Older</Typography>
          </Tooltip>
        ) : null}
      </TableCell>
      <TableCell sx={{ padding: '0 10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={issue.type}>
            <Typography>{IssueType[issue.type]}</Typography>
          </Tooltip>
          &nbsp;
          <Tooltip title={issue.state}>
            <Typography>{IssueState[issue.state]}</Typography>
          </Tooltip>
          &nbsp;
          <Typography color={issueData.checked ? 'secondary' : ''}>
            {issue.title}
          </Typography>
        </div>
      </TableCell>
      <TableCell sx={cellSx}>
        <IconButton
          onClick={() => {
            setText(issue.title);
            setGenerateDisabled(true);
            debouncedHandleIssueData(issue.title);
          }}
        >
          {React.createElement(
            app.getSystemIcon(Icons.ARROW_RIGHT) as IconComponent,
          )}
        </IconButton>
        <FormControl sx={{ m: 1, minWidth: 275 }}>
          <TextField
            multiline
            sx={{
              padding: '2px',
              '& .v5-MuiInputBase-multiline': { padding: '4px' },
            }}
            id="outlined-controlled"
            value={text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setText(event.target.value);
              setGenerateDisabled(true);
              debouncedHandleIssueData(event.target.value);
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0 10px' }}>
        <Typography
          color={issueData.checked ? 'secondary' : ''}
          variant="caption"
        >
          {issue.labels.join('; ')}
        </Typography>
      </TableCell>
      <TableCell sx={cellSx}>
        <Checkbox
          checked={issueData.checked}
          onChange={() =>
            onHandleIssueData(issue.pivotalId, { checked: !issueData.checked })
          }
        />
      </TableCell>
      {withMultiPortalSelect ? (
        <TableCell sx={{ padding: '0 10px' }}>
          <MultiPortalSelect
            issue={issue}
            portals={portals}
            issueData={issueData}
            onHandleIssueData={onHandleIssueData}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
};
