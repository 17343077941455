import { BotPatchedCatalogEntity } from '@internal/plugin-sl-assets';

export const ArgoStatuses = Object.freeze({
  HEALTHY: 'Healthy',
  PROGRESSING: 'Progressing',
  DEGRADED: 'Degraded',
});

export const ArgoColors = Object.freeze({
  SUCCESS: 'green',
  PROGRESS: 'yellow',
  ERROR: 'red',
});

export type ArgoProgressBar = {
  progress: number;
  color: (typeof ArgoColors)[keyof typeof ArgoColors];
};

export type ArgoNode = {
  kind: string;
  networkingInfo: {
    labels: {
      service: string;
      'job-name': string;
    };
  };
  images: Array<string>;
  health: { status: (typeof ArgoStatuses)[keyof typeof ArgoStatuses] };
};

export type ArgoRequestData = {
  nodes: Array<ArgoNode>;
};

export type ProcessedArgoPods = Record<
  string,
  Record<
    string,
    Record<(typeof ArgoStatuses)[keyof typeof ArgoStatuses], number>
  >
>;

const RELEASE_POD_PERCENTAGE = 20;
const WEB_POD_PERCENTAGE = 80;

export const processPods = (data: ArgoRequestData): ProcessedArgoPods =>
  data.nodes
    .filter(node => node.kind === 'Pod')
    .reduce((processedData: ProcessedArgoPods, pod) => {
      const labels = pod.networkingInfo.labels;
      const name = labels.service || labels['job-name'];
      if (name === 'worker') {
        return processedData;
      }
      const gitHash = pod.images[0].split(':')[1].split('-')[0];

      return {
        ...processedData,
        [gitHash]: {
          ...(processedData[gitHash] || {}),
          [name]: {
            ...((processedData[gitHash] || {})[name] || {}),
            [pod.health.status]:
              (processedData?.[gitHash]?.[name]?.[pod.health.status] || 0) + 1,
          },
        },
      };
    }, {});

export const getProgressBars = (
  pods: ProcessedArgoPods,
  entry: BotPatchedCatalogEntity,
): Array<ArgoProgressBar> => {
  const oldPods: Record<string, number> = {
    [ArgoStatuses.HEALTHY]: 0,
    [ArgoStatuses.PROGRESSING]: 0,
    [ArgoStatuses.DEGRADED]: 0,
  };
  const newPods: Record<string, number> = {
    [ArgoStatuses.HEALTHY]: 0,
    [ArgoStatuses.PROGRESSING]: 0,
    [ArgoStatuses.DEGRADED]: 0,
  };
  const releasePod: Record<string, number> = {
    [ArgoStatuses.HEALTHY]: 0,
    [ArgoStatuses.PROGRESSING]: 0,
    [ArgoStatuses.DEGRADED]: 0,
  };

  Object.entries(pods).map(([gitHash, podData]) => {
    const oldOrNew = (entry.botsResults?.commit as string).startsWith(gitHash)
      ? newPods
      : oldPods;

    Object.entries(podData).map(([podName, podStatuses]) => {
      Object.entries(podStatuses)
        .filter(([podStatus, _podCount]) =>
          [
            ArgoStatuses.HEALTHY,
            ArgoStatuses.DEGRADED,
            ArgoStatuses.PROGRESSING,
          ].includes(
            podStatus as (typeof ArgoStatuses)[keyof typeof ArgoStatuses],
          ),
        )
        .map(([podStatus, podCount]) => {
          if (podName.startsWith('web-')) {
            oldOrNew[podStatus] = (oldOrNew[podStatus] || 0) + podCount;
          }
          if (
            podName.startsWith('strat-release') &&
            (entry.botsResults?.commit as string).startsWith(gitHash)
          ) {
            releasePod[podStatus] = (releasePod[podStatus] || 0) + podCount;
          }
        });
    });
  });
  const totalNewPods = Object.values(newPods).reduce<number>(
    (total: number, value: number) => total + value,
    0,
  );
  const totalOldPods = Object.values(oldPods).reduce<number>(
    (total: number, value: number) => total + value,
    0,
  );
  const totalReleasePods = Object.values(releasePod).reduce<number>(
    (total: number, value: number) => total + value,
    0,
  );

  if (totalNewPods && !totalOldPods && !totalReleasePods) {
    return [{ progress: 100, color: ArgoColors.SUCCESS }];
  }

  const progress: Array<ArgoProgressBar> = [];

  let totalProgress: number = 0;

  if (totalReleasePods) {
    if (releasePod[ArgoStatuses.HEALTHY]) {
      totalProgress +=
        (releasePod[ArgoStatuses.HEALTHY] / totalReleasePods) *
        RELEASE_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.SUCCESS });
    }

    if (releasePod[ArgoStatuses.PROGRESSING]) {
      totalProgress +=
        (releasePod[ArgoStatuses.PROGRESSING] / totalReleasePods) *
        RELEASE_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.PROGRESS });
    }

    if (releasePod[ArgoStatuses.DEGRADED]) {
      totalProgress +=
        (releasePod[ArgoStatuses.DEGRADED] / totalReleasePods) *
        RELEASE_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.ERROR });
    }
  }

  totalProgress = RELEASE_POD_PERCENTAGE;

  if (newPods[ArgoStatuses.HEALTHY] + oldPods[ArgoStatuses.PROGRESSING]) {
    if (newPods[ArgoStatuses.HEALTHY] || oldPods[ArgoStatuses.PROGRESSING]) {
      totalProgress +=
        ((newPods[ArgoStatuses.HEALTHY] + oldPods[ArgoStatuses.PROGRESSING]) /
          (totalNewPods + totalOldPods)) *
        WEB_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.SUCCESS });
    }

    if (newPods[ArgoStatuses.PROGRESSING] || oldPods[ArgoStatuses.HEALTHY]) {
      totalProgress +=
        ((newPods[ArgoStatuses.PROGRESSING] + oldPods[ArgoStatuses.HEALTHY]) /
          (totalNewPods + totalOldPods)) *
        WEB_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.PROGRESS });
    }

    if (newPods[ArgoStatuses.DEGRADED] || oldPods[ArgoStatuses.DEGRADED]) {
      totalProgress +=
        ((newPods[ArgoStatuses.DEGRADED] + oldPods[ArgoStatuses.DEGRADED]) /
          (totalNewPods + totalOldPods)) *
        WEB_POD_PERCENTAGE;
      progress.push({ progress: totalProgress, color: ArgoColors.ERROR });
    }
  }

  progress.reverse();
  return progress;
};
