import { BasicPermission } from '@backstage/plugin-permission-common';
import { GithubGroupsGrantedPermissions } from '../definitions/githubGroups';
import { PermissionGroupDefinitions } from '../definitions/permissionGroups';
import { UserEmailGrantedPermissions } from '../definitions/users';
import { ComponentPermission, ComponentPermissionCollection } from './types';
import { PermissionGroupName } from '../definitions/types';
import { SLPermissionsBuilderError } from './errors';

const parseComponentPermission = (
  componentPermission: ComponentPermission,
): string[] => [
  componentPermission.name as string,
  ...(componentPermission.matchType === 'all'
    ? componentPermission.members.reduce<string[]>(
        (
          memberNames: string[],
          member: ComponentPermission | BasicPermission,
        ) => [
          ...memberNames,
          ...(member.type === 'component' && member.matchType === 'all'
            ? parseComponentPermission(member)
            : [member.name]),
        ],
        [],
      )
    : []),
];

const parsePermissionGroups = (
  componentPermissions: ComponentPermissionCollection,
  groupName: PermissionGroupName,
): string[] => {
  const permissionGroup = PermissionGroupDefinitions.find(
    group => group.name === groupName,
  );

  if (!permissionGroup) {
    throw new SLPermissionsBuilderError(
      `Could not find permission group with name: ${groupName} in the definition file!`,
    );
  }

  return permissionGroup.permissions.reduce<string[]>(
    (permissions, componentPermissionName) => [
      ...permissions,
      ...parseComponentPermission(
        componentPermissions[componentPermissionName],
      ),
    ],
    [],
  );
};

const reducePermissionGroups = (
  componentPermissions: ComponentPermissionCollection,
  permissionGroupNames: PermissionGroupName[],
) =>
  permissionGroupNames.reduce<string[]>(
    (parsedPermissions: string[], groupName: PermissionGroupName) => [
      ...parsedPermissions,
      ...parsePermissionGroups(componentPermissions, groupName),
    ],
    [],
  );

const buildGrantedUserPermissions = (
  componentPermissions: ComponentPermissionCollection,
) =>
  UserEmailGrantedPermissions.reduce(
    (rules, user) => ({
      ...rules,
      [user.email]: new Set([
        ...user.permissions.reduce<string[]>(
          (parsedComponentPermissions, componentPermissionName) => [
            ...parsedComponentPermissions,
            ...parseComponentPermission(
              componentPermissions[componentPermissionName],
            ),
          ],
          [],
        ),
        ...reducePermissionGroups(componentPermissions, user.permissionGroups),
      ]),
    }),
    {},
  );

const buildGrantedGithubGroupPermissions = (
  componentPermissions: ComponentPermissionCollection,
) =>
  GithubGroupsGrantedPermissions.reduce(
    (rules, group) => ({
      ...rules,
      [group.groupName]: new Set([
        ...group.permissions.reduce<string[]>(
          (parsedComponentPermissions, componentPermissionName) => [
            ...parsedComponentPermissions,
            ...parseComponentPermission(
              componentPermissions[componentPermissionName],
            ),
          ],
          [],
        ),
        ...reducePermissionGroups(componentPermissions, group.permissionGroups),
      ]),
    }),
    {},
  );

export const buildGrantedPermissions = (
  componentPermissions: ComponentPermissionCollection,
) => ({
  users: buildGrantedUserPermissions(componentPermissions),
  githubGroups: buildGrantedGithubGroupPermissions(componentPermissions),
});
