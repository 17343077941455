import React, { PropsWithChildren, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarSubmenu,
  SidebarSubmenuItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import { Icons } from '@internal/plugin-sl-assets';
import { SidebarShortcuts } from '@internal/plugin-sl-shortcuts';
import { isMobile } from 'react-device-detect';
import {
  EnvironmentNames,
  isEnvironment,
  switchEnvironment,
} from '@internal/plugin-sl-experiments';
import { AppContext, IconComponent, useApp } from '@backstage/core-plugin-api';
import { Commands } from '../commands';
import {
  SidebarSection,
  SidebarGroupData,
  SidebarItemData,
  getSidebarSections,
} from '../sidebar';
import { Pages } from '../../pages';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const app = useApp();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {React.createElement(
          app.getSystemIcon(
            isOpen ? Icons.LOGO_FULL : Icons.LOGO,
          ) as IconComponent,
        )}
      </Link>
    </div>
  );
};

const renderSidebarSubmenuItem = (app: AppContext, item: SidebarItemData) => (
  <SidebarSubmenuItem
    key={item.text}
    title={item.text}
    icon={
      app.getSystemIcon(item.icon) ||
      (app.getSystemIcon(Icons.QUESTION) as IconComponent)
    }
    to={Pages[item.pageName].url}
  />
);

const renderSidebarItem = (
  app: AppContext,
  entry: SidebarGroupData | SidebarItemData,
) =>
  !('pageName' in entry) ? (
    <SidebarItem
      key={entry.text}
      icon={
        app.getSystemIcon(entry.icon) ||
        (app.getSystemIcon(Icons.QUESTION) as IconComponent)
      }
      text={entry.text}
    >
      <SidebarSubmenu>
        {(entry as SidebarGroupData).items.map(item =>
          renderSidebarSubmenuItem(app, item),
        )}
      </SidebarSubmenu>
    </SidebarItem>
  ) : (
    <SidebarItem
      key={entry.text}
      icon={
        app.getSystemIcon(entry.icon) ||
        (app.getSystemIcon(Icons.QUESTION) as IconComponent)
      }
      text={entry.text}
      to={Pages[(entry as SidebarItemData).pageName].url}
    />
  );

const renderEnvironmentSwitcher = (codeIcon: IconComponent) => (
  <>
    {!isEnvironment(EnvironmentNames.PRODUCTION) && (
      <SidebarItem
        key="env-production"
        icon={codeIcon}
        text="Prod env"
        // @ts-ignore
        onClick={_ => {
          switchEnvironment(EnvironmentNames.PRODUCTION);
        }}
      />
    )}
    {!isEnvironment(EnvironmentNames.DEVELOPMENT) && (
      <SidebarItem
        key="env-development"
        icon={codeIcon}
        text="Dev env"
        // @ts-ignore
        onClick={_ => {
          switchEnvironment(EnvironmentNames.DEVELOPMENT);
        }}
      />
    )}
    {!isEnvironment(EnvironmentNames.LOCAL) && (
      <SidebarItem
        key="env-local"
        icon={codeIcon}
        text="Local env"
        // @ts-ignore
        onClick={_ => {
          switchEnvironment(EnvironmentNames.LOCAL);
        }}
      />
    )}
  </>
);

const renderLastItems = (app: AppContext) => (
  <>
    <SidebarItem
      key="permissions"
      icon={app.getSystemIcon(Icons.VERIFIED_USER) as IconComponent}
      text="Permissions"
      to="/permissions"
    />
    {renderEnvironmentSwitcher(app.getSystemIcon(Icons.CODE) as IconComponent)}
  </>
);

const renderSidebarGroup = (
  app: AppContext,
  group: SidebarSection,
  index: number,
  isLast: boolean,
) => (
  <React.Fragment key={index}>
    {isLast ? <SidebarSpace key={`${index}-space`} /> : null}
    <SidebarGroup
      key={index}
      icon={React.createElement(
        app.getSystemIcon(group.icon) ||
          (app.getSystemIcon(Icons.QUESTION) as IconComponent),
      )}
    >
      {isLast ? renderLastItems(app) : null}
      {group.items.map(item => renderSidebarItem(app, item))}
    </SidebarGroup>
    {!isLast ? <SidebarDivider key={`${index}-space`} /> : null}
  </React.Fragment>
);

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const app = useApp();
  const sidebarSections = useMemo(() => getSidebarSections(isMobile), []);

  return (
    <SidebarPage>
      <Sidebar key="sidebar">
        <SidebarLogo key="logo" />
        <SidebarGroup
          key="search"
          icon={React.createElement(
            app.getSystemIcon(Icons.SEARCH) as IconComponent,
          )}
        >
          <SidebarSearchModal />
          {!isMobile && <SidebarShortcuts commands={Commands} pages={Pages} />}
          {sidebarSections[0].items.map(item => renderSidebarItem(app, item))}
        </SidebarGroup>
        <SidebarDivider />
        {sidebarSections
          .slice(1)
          .map((group: SidebarSection, index: number) =>
            renderSidebarGroup(
              app,
              group,
              index + 1,
              index === sidebarSections.length - 2,
            ),
          )}
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
