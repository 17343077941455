import { SLPermissionsSanitizationError } from './errors';
import {
  ComponentDefinition,
  ComponentPermissionName,
  MatchAllComponentDefinition,
  MatchOneComponentDefinition,
  SanitizedComponentDefinition,
} from '../definitions/types';
import { ComponentDefinitions } from '../definitions';

const sanitizeComponentDefinitionName = (
  definition: ComponentDefinition,
): ComponentPermissionName => {
  if (!/^[a-z-]+(\.[a-z-]+)+$/.test(definition.name)) {
    throw new SLPermissionsSanitizationError(
      definition.name,
      'Invalid permission name',
      `contains invalid characters or does not follow the naming convention!`,
    );
  }

  return definition.name;
};

const sanitizeComponentDefinitionMatchType = (
  definition: ComponentDefinition,
): 'all' | 'one' => {
  if (['all', 'one'].indexOf(definition.matchType) === -1) {
    throw new SLPermissionsSanitizationError(
      definition.name,
      'Invalid match type',
      'is not "all" or "one"',
    );
  }

  return definition.matchType;
};

const sanitizeComponentDefinitionBaseName = (
  definition: ComponentDefinition,
  memberName: string,
  memberIndex: number,
): string => {
  if (!/^[a-z-]+(\.[a-z-]+)+$/.test(memberName)) {
    throw new SLPermissionsSanitizationError(
      definition.name,
      'Invalid member name',
      `item at index ${memberIndex} "${memberName}" does not follow the naming convention`,
    );
  }

  return memberName;
};

const sanitizeMatchAllComponentDefinitionMembers = (
  componentDefinition: MatchAllComponentDefinition,
): string[] =>
  componentDefinition.members.map((memberName: string, memberIndex) =>
    sanitizeComponentDefinitionBaseName(
      componentDefinition,
      memberName,
      memberIndex,
    ),
  );

const sanitizeMatchOneComponentDefinitionMembers = (
  definition: MatchOneComponentDefinition,
): ComponentPermissionName[] =>
  definition.members.map(
    (memberName: ComponentPermissionName, memberIndex: number) => {
      sanitizeComponentDefinitionBaseName(definition, memberName, memberIndex);

      const unsanitizedComponentDefinitionReference = ComponentDefinitions.find(
        currentComponentDefinition =>
          currentComponentDefinition.name === memberName,
      );
      if (!unsanitizedComponentDefinitionReference) {
        throw new SLPermissionsSanitizationError(
          definition.name,
          'Invalid member name',
          `item at index ${memberIndex} "${memberName}" is not defined as a component definition`,
        );
      }

      return memberName;
    },
  );

export const sanitizeComponentDefinition = (
  componentDefinition: ComponentDefinition,
): SanitizedComponentDefinition => {
  sanitizeComponentDefinitionMatchType(componentDefinition);

  if (componentDefinition.matchType === 'all')
    return {
      name: sanitizeComponentDefinitionName(componentDefinition),
      description: componentDefinition.description,
      matchType: 'all',
      members: sanitizeMatchAllComponentDefinitionMembers(componentDefinition),
    };

  return {
    name: sanitizeComponentDefinitionName(componentDefinition),
    description: componentDefinition.description,
    matchType: 'one',
    members: sanitizeMatchOneComponentDefinitionMembers(componentDefinition),
  };
};
