import React from 'react';
import Link from '@mui/material/Link';

type Props = {
  href: string;
  children: React.ReactNode;
};

export const NewTabLink = ({ href, children }: Props) => (
  <Link onClick={() => window.open(href)} sx={{ cursor: 'pointer' }}>
    {children}
  </Link>
);
