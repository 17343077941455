import { Event, EventProps } from './event';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

type Props = {
  events: EventProps[];
};

export const UpcomingEvents = ({ events }: Props) => {
  events.sort((event1, event2) => {
    if (event1.startDate < event2.startDate) return -1;
    if (event1.startDate > event2.startDate) return 1;
    return 0;
  });

  if (!events.length) {
    return null;
  }

  return (
    <Card>
      <Paper>
        <CardHeader title="Upcoming events" />
        <br />
        <Divider />
        <CardContent>
          {events.map(event => (
            <Event key={`${event.name}-${event.startDate}`} {...event} />
          ))}
        </CardContent>
      </Paper>
    </Card>
  );
};
