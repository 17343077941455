import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';

export class SLToolsApi extends SLBotsApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, Bots.TOOLS);
  }

  commitTracker(id: string, type: string, searchHfBranches: boolean = false) {
    return this.get(
      `/api/shortcuts/commitTracker?id=${id}&type=${type}&hf=${
        searchHfBranches ? 1 : 0
      }`,
      {
        'Content-Type': 'application/json',
      },
    );
  }
}

export const SLToolsApiRef = createApiRef<SLToolsApi>({
  id: 'plugin.sl-tools.api',
});

export const useToolsApi = () => {
  return useApi<SLToolsApi>(SLToolsApiRef);
};
