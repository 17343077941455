import React from 'react';
import { Route } from 'react-router-dom';
import { CatalogEntityPage, catalogPlugin } from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import QuestionIcon from '@mui/icons-material/Help';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SearchIcon from '@mui/icons-material/Search';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildIcon from '@mui/icons-material/Build';
import LanguageIcon from '@mui/icons-material/Language';
import AssistantIcon from '@mui/icons-material/Assistant';
import PersonIcon from '@mui/icons-material/Person';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import HealingIcon from '@mui/icons-material/Healing';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BookIcon from '@mui/icons-material/Book';
import CallIcon from '@mui/icons-material/Call';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PowerIcon from '@mui/icons-material/Power';
import CloudIcon from '@mui/icons-material/Cloud';
import GroupIcon from '@mui/icons-material/Group';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import AddIcon from '@mui/icons-material/Add';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CodeIcon from '@mui/icons-material/Code';
import ErrorIcon from '@mui/icons-material/Error';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import LockIcon from '@mui/icons-material/Lock';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ImageIcon from '@mui/icons-material/Image';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import HomeIcon from '@mui/icons-material/Home';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import GitHubIcon from '@mui/icons-material/GitHub';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Looks1Icon from '@mui/icons-material/LooksOne';
import Looks2Icon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import ApiIcon from '@mui/icons-material/Api';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TerminalIcon from '@mui/icons-material/Terminal';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RateReviewIcon from '@mui/icons-material/RateReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import MessageIcon from '@mui/icons-material/Message';
import WarningIcon from '@mui/icons-material/Warning';
import TuneIcon from '@mui/icons-material/Tune';
import SyncIcon from '@mui/icons-material/Sync';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { orgPlugin } from '@backstage/plugin-org';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { SearchPage } from '@backstage/plugin-search';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  discoveryApiRef,
  FeatureFlag,
  googleAuthApiRef,
  IdentityApi,
  PluginFeatureFlagConfig,
  useApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { setTokenCookie } from './cookieAuth';
import {
  RELATION_CHILD_OF,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
} from '@backstage/catalog-model';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import {
  createUnifiedTheme,
  SupportedThemes,
  UnifiedThemeProvider,
} from '@backstage/theme';
import { themes } from '@backstage/theme';
import {
  EnvironmentNames,
  EXPERIMENTS,
  Experiments,
} from '@internal/plugin-sl-experiments';
import { SLPermissions } from '@internal/plugin-sl-permissions';
import { slReviewsPlugin } from '@internal/plugin-sl-reviews';
import { Icons } from '@internal/plugin-sl-assets';
import {
  ArgoCDIcon,
  AwsIcon,
  BambooHrIcon,
  BayutIcon,
  BpropertyIcon,
  CircleCIIcon,
  CloudFlareIcon,
  CoralogixIcon,
  DubizzleIcon,
  ElasticIcon,
  GoogleFirebaseIcon,
  HerokuIcon,
  AppleShortcusIcon,
  LamudiIcon,
  LogoFullIcon,
  LogoIcon,
  OlxIcon,
  OpsgenieIcon,
  PrClosedIcon,
  PrDraftIcon,
  PrMergedIcon,
  PrReadyIcon,
  SectorLabsIcon,
  SentryIcon,
  SlackIcon,
  ZameenIcon,
} from './assets';
import { searchPage } from './components/search/searchPage';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { HomePage } from './components/home';
import { Pages, Page } from './pages';

const createCustomUnifiedThemeOverrides = (theme: SupportedThemes) => {
  const paperColor = theme.palette.background.paper;
  const updatedPaperColor = `${
    paperColor.length === 4
      ? `#${paperColor
          .slice(1)
          .split('')
          .map(colorCode => `${colorCode}${colorCode}`)
          .join('')}`
      : paperColor
  }44`;

  const auxiliaryPaperTransparentStyle = {
    backgroundColor: updatedPaperColor,
    backdropFilter: 'blur(30px)',
  };

  return {
    MuiDialog: {
      styleOverrides: {
        paper: auxiliaryPaperTransparentStyle,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          maxHeight: '90%',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: { ...auxiliaryPaperTransparentStyle },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: { backgroundColor: 'transparent' },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: { backgroundColor: 'transparent' },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: auxiliaryPaperTransparentStyle,
      },
    },
  };
};

// @ts-ignore palette comes from the v4 theme
const customLightThemeV4 = createUnifiedTheme({
  ...(themes.light.getTheme('v4') as SupportedThemes),
  components: createCustomUnifiedThemeOverrides(
    themes.light.getTheme('v4') as SupportedThemes,
  ),
});

// @ts-ignore palette comes from the v4 theme
const customDarkThemeV4 = createUnifiedTheme({
  ...(themes.dark.getTheme('v4') as SupportedThemes),
  components: createCustomUnifiedThemeOverrides(
    themes.dark.getTheme('v4') as SupportedThemes,
  ),
});

// @ts-ignore
const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'google-auth-provider',
            title: 'Google',
            message: 'Sign in with Google',
            apiRef: googleAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  featureFlags: [
    {
      name: 'show-argo-status',
      description: 'Show Argo status in release version',
      pluginId: 'sl-release',
    },
    {
      name: 'on-call-schedule-past-shifts',
      description: 'Show past shifts in the On-call schedule',
      pluginId: 'sl-oncall',
    },
    ...Object.keys(EXPERIMENTS).reduce<FeatureFlag[]>(
      (mappedExperiments: FeatureFlag[], currentExperimentKey: string) => [
        ...mappedExperiments,
        ...[
          {
            pluginId: 'sl-experiments',
            name: `experiment-${currentExperimentKey}-${EnvironmentNames.DEVELOPMENT}-redirect`,
            description: `Redirect experiment ${currentExperimentKey} (${EXPERIMENTS[currentExperimentKey].pathNamePrefix}) to ${EnvironmentNames.DEVELOPMENT} environment`,
          },
          {
            pluginId: 'sl-experiments',
            name: `experiment-${currentExperimentKey}-${EnvironmentNames.LOCAL}-redirect`,
            description: `Redirect experiment ${currentExperimentKey} (${EXPERIMENTS[currentExperimentKey].pathNamePrefix}) to ${EnvironmentNames.LOCAL} environment`,
          },
        ],
      ],
      [],
    ),
    ...Array.from(slReviewsPlugin.getFeatureFlags()).map(
      (featureFlag: PluginFeatureFlagConfig) => ({
        ...featureFlag,
        pluginId: 'sl-reviews',
      }),
    ),
  ],
  icons: {
    // Material UI icons
    [Icons.ADD]: () => <AddIcon />,
    [Icons.ADMIN_PANEL]: () => <AdminPanelSettingsIcon />,
    [Icons.API]: () => <ApiIcon />,
    [Icons.ALARM_CLOCK]: () => <AccessAlarmIcon />,
    [Icons.ANDROID]: () => <AndroidIcon />,
    [Icons.ANNOUNCEMENT]: () => <AnnouncementIcon />,
    [Icons.APPLE]: () => <AppleIcon />,
    [Icons.ARROW_DROPDOWN]: () => <ArrowDropDownIcon />,
    [Icons.ARROW_BACK]: () => <ArrowBackIcon />,
    [Icons.ARROW_RIGHT]: () => <ArrowRight />,
    [Icons.ASSISTANT]: () => <AssistantIcon />,
    [Icons.BEACH_ACCESS]: () => <BeachAccessIcon />,
    [Icons.BOOK]: () => <BookIcon />,
    [Icons.BOT]: () => <SmartToyIcon />,
    [Icons.BUILD]: () => <BuildIcon />,
    [Icons.BUG_REPORT]: () => <BugReportIcon />,
    [Icons.CALENDAR]: () => <EventIcon />,
    [Icons.CALENDAR_TODAY]: () => <CalendarTodayIcon />,
    [Icons.CALL]: () => <CallIcon />,
    [Icons.CATEGORY]: () => <CategoryIcon />,
    [Icons.CHECK_CIRCLE]: () => <CheckCircleIcon />,
    [Icons.CHEVRON_RIGHT]: () => <ChevronRightIcon />,
    [Icons.CLOUD]: () => <CloudIcon />,
    [Icons.CLOSE]: () => <CloseIcon />,
    [Icons.CODE]: () => <CodeIcon />,
    [Icons.CONTACT_EMERGENCY]: () => <ContactEmergencyIcon />,
    [Icons.COPY]: () => <CopyIcon />,
    [Icons.DELETE]: () => <DeleteIcon />,
    [Icons.EDIT]: () => <EditIcon />,
    [Icons.ERROR]: () => <ErrorIcon />,
    [Icons.EVENT_AVAILABLE]: () => <EventAvailableIcon />,
    [Icons.EVENT_BUSY]: () => <EventBusyIcon />,
    [Icons.EXPAND]: () => <ExpandMoreIcon />,
    [Icons.FILTER]: () => <FilterListIcon />,
    [Icons.GITHUB]: () => <GitHubIcon />,
    [Icons.GROUP]: () => <GroupIcon />,
    [Icons.HEALING]: () => <HealingIcon />,
    [Icons.HOME]: () => <HomeIcon />,
    [Icons.HOT]: () => <WhatshotIcon />,
    [Icons.HOURGLASS_TOP]: () => <HourglassTopIcon />,
    [Icons.IMAGE]: () => <ImageIcon />,
    [Icons.INVENTORY]: () => <InventoryIcon />,
    [Icons.KEYBOARD]: () => <KeyboardIcon />,
    [Icons.LANGUAGE]: () => <LanguageIcon />,
    [Icons.LAUNCH]: () => <LaunchIcon />,
    [Icons.LIBRARY_BOOKS]: () => <LibraryBooksIcon />,
    [Icons.LIVE_HELP]: () => <LiveHelpIcon />,
    [Icons.LOCK]: () => <LockIcon />,
    [Icons.MESSAGE]: () => <MessageIcon />,
    [Icons.MONETIZATION]: () => <MonetizationOnIcon />,
    [Icons.NOTIFICATION_ACTIVE]: () => <NotificationsActiveIcon />,
    [Icons.NOTIFICATION_IMPORTANT]: () => <NotificationImportantIcon />,
    [Icons.NOTIFICATION_PAUSED]: () => <NotificationsPausedIcon />,
    [Icons.NOT_INTERESTED]: () => <NotInterestedIcon />,
    [Icons.NUMBER1]: () => <Looks1Icon />,
    [Icons.NUMBER2]: () => <Looks2Icon />,
    [Icons.NUMBER3]: () => <Looks3Icon />,
    [Icons.NUMBER4]: () => <Looks4Icon />,
    [Icons.NUMBER5]: () => <Looks5Icon />,
    [Icons.NUMBER6]: () => <Looks6Icon />,
    [Icons.QUESTION]: () => <QuestionIcon />,
    [Icons.PDF]: () => <PictureAsPdfIcon />,
    [Icons.PENDING]: () => <PendingIcon />,
    [Icons.PERSON]: () => <PersonIcon />,
    [Icons.POWER]: () => <PowerIcon />,
    [Icons.RATE_REVIEW]: () => <RateReviewIcon />,
    [Icons.RELEASE]: () => <NewReleasesIcon />,
    [Icons.SEARCH]: () => <SearchIcon />,
    [Icons.RING_VOLUME]: () => <RingVolumeIcon />,
    [Icons.RUN]: () => <DirectionsRunIcon />,
    [Icons.SAVE]: () => <SaveIcon />,
    [Icons.SETTINGS]: () => <SettingsIcon />,
    [Icons.SUMMARY]: () => <SummarizeIcon />,
    [Icons.SUPERVISOR]: () => <SupervisorAccountIcon />,
    [Icons.SQUARE_FOOT]: () => <SquareFootIcon />,
    [Icons.SYNC]: () => <SyncIcon />,
    [Icons.SYNC_DISABLED]: () => <SyncDisabledIcon />,
    [Icons.SYNC_PROBLEM]: () => <SyncProblemIcon />,
    [Icons.TERMINAL]: () => <TerminalIcon />,
    [Icons.THUMBS_DOWN]: () => <ThumbDownIcon />,
    [Icons.THUMBS_UP]: () => <ThumbUpIcon />,
    [Icons.TUNE]: () => <TuneIcon />,
    [Icons.VERIFIED_USER]: () => <VerifiedUserIcon />,
    [Icons.VISIBILITY]: () => <VisibilityIcon />,
    [Icons.WARNING]: () => <WarningIcon />,

    // Mutated Material Icons
    [Icons.NOT_INTERESTED_DISABLED]: () => (
      <NotInterestedIcon color="disabled" />
    ),
    [Icons.NOT_INTERESTED_ERROR]: () => <NotInterestedIcon color="error" />,
    [Icons.RING_VOLUME_PRIMARY]: () => <RingVolumeIcon color="primary" />,
    [Icons.THUMBS_DOWN_ERROR]: () => <ThumbDownIcon color="error" />,
    [Icons.THUMBS_UP_DISABLED]: () => <ThumbUpIcon color="disabled" />,
    [Icons.THUMBS_UP_PRIMARY]: () => <ThumbUpIcon color="primary" />,

    // External icons
    [Icons.ARGOCD]: ArgoCDIcon,
    [Icons.AWS]: AwsIcon,
    [Icons.BAMBOOHR]: BambooHrIcon,
    [Icons.BPROPERTY]: BpropertyIcon,
    [Icons.BAYUT]: BayutIcon,
    [Icons.CIRCLECI]: CircleCIIcon,
    [Icons.CLOUDFLARE]: CloudFlareIcon,
    [Icons.CORALOGIX]: CoralogixIcon,
    [Icons.DUBIZZLE]: DubizzleIcon,
    [Icons.ELASTIC]: ElasticIcon,
    [Icons.FIREBASE]: GoogleFirebaseIcon,
    [Icons.HEROKU]: HerokuIcon,
    [Icons.APPLE_SHORTCUTS]: AppleShortcusIcon,
    [Icons.LAMUDI]: LamudiIcon,
    [Icons.LOGO]: LogoIcon,
    [Icons.LOGO_FULL]: LogoFullIcon,
    [Icons.OLX]: OlxIcon,
    [Icons.OPSGENIE]: OpsgenieIcon,
    [Icons.SENTRY]: SentryIcon,
    [Icons.SL]: SectorLabsIcon,
    [Icons.SLACK]: SlackIcon,
    [Icons.ZAMEEN]: ZameenIcon,

    // Custom SVGs
    [Icons.PR_DRAFT]: PrDraftIcon,
    [Icons.PR_READY]: PrReadyIcon,
    [Icons.PR_MERGED]: PrMergedIcon,
    [Icons.PR_CLOSED]: PrClosedIcon,
  },
  themes: [
    {
      id: 'sl-light-theme',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customLightThemeV4}>
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'sl-dark-theme',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customDarkThemeV4}>
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
});

const renderRoutesFromPages = (page: Page) => (
  <Route
    key={page.url}
    path={`${page.url}`}
    element={
      page.permissionName ? (
        <RequirePermission
          permission={SLPermissions.component[page.permissionName]}
        >
          {page.renderPage({ children: null })}
        </RequirePermission>
      ) : (
        page.renderPage({ children: null })
      )
    }
  />
);

const routes = (
  <FlatRoutes key="routes">
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 2,
            unidirectional: false,
            selectedRelations: [
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_CHILD_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    {Object.values(Pages).map(renderRoutesFromPages)}
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <Experiments />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
