import React, { ReactNode } from 'react';
import { Progress } from '@backstage/core-components';
import Alert from '@mui/material/Alert';

export type PartialPageData<T> = {
  value: T | undefined;
  loading: boolean;
  error: Error | undefined;
};

export type PageData = {
  value: any;
  loading: boolean;
  error: Error | undefined;
};

type Props = {
  pageData: PageData;
  header: () => ReactNode;
  content: (pageData: PageData) => ReactNode;
  additionalFilters?: (pageData: PageData) => ReactNode;
};

export const Page = ({
  pageData: { loading, value, error },
  header,
  content,
  additionalFilters,
}: Props) => {
  if (error) {
    return (
      <>
        {header()}
        <Alert severity="error">{error.message}</Alert>
      </>
    );
  }
  if (loading || !value) {
    return (
      <>
        {header()}
        <Progress />
      </>
    );
  }

  const errorContent = additionalFilters?.({ loading, value, error });

  if (errorContent) {
    return (
      <>
        {header()}
        {errorContent}
      </>
    );
  }

  return (
    <>
      {header()}
      <div style={{ height: '15px' }} />
      {content({ loading, value, error })}
    </>
  );
};
