import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { SLReviewsApi, SLReviewsApiRef } from './api';

export const slReviewsPlugin = createPlugin({
  id: 'sl-reviews',
  featureFlags: [
    // {
    //   name: 'show-codeowner-reviews-default',
    //   // @ts-ignore 2 reasons:
    //   // 1. plugin feature flags do not appear in settings by default, they have to be configured in the app
    //   // 2. description is not allowed here (why Backstage, just why?)
    //   description: 'Show codeowner reviews by default',
    // },
  ],
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: SLReviewsApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new SLReviewsApi(discoveryApi, identityApi),
    }),
  ],
});
